import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuditTrailService } from './auditTrail.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditorService } from '../creditor/creditor.service';

declare var $: any;

@Component({
    selector: 'auditTrail-search',
    templateUrl: 'auditTrail-search.template.html'
})

export class AuditTrailSearchComponent {
    filter: any = {

    };
    @ViewChild('auditTrailPaginator') auditTrailPaginator: MatPaginator;
    @ViewChild('auditTrailSort') auditTrailSort: MatSort;
    auditTrailColumns: string[] = ['ActionDate', 'ActionType', 'EntityName', 'ColumnName', 'Debtor', 'Creditor', 'OldValue', 'NewValue', 'User']; auditTrailDataSource: MatTableDataSource<any>;

    entityList: any = [];
    auditTrailList: any = [];
    debtorList: any = [];
    ShowCurrentUserOnly: boolean = false;

    constructor(private router: Router, private auditTrailService: AuditTrailService
        , private pageHelper: PageHelper, private spinner: NgxSpinnerService, private creditorService: CreditorService) {
    }

    ngOnInit() {
        $('#menuAuditTrail').addClass('active');

        this.getEntities();
        this.getDebtors();
        this.clearFilter();

        this.auditTrailSort.sortChange.subscribe(() => {
            this.filter.SortDirection = this.auditTrailSort.direction;
            this.filter.SortField = this.auditTrailSort.active;
            this.filter.CurrentPage = 1;
            this.filter.PageSize = this.auditTrailPaginator.pageSize;
            this.auditTrailPaginator.pageIndex = 0
            this.getAuditTrail();
        });
    }

    getEntities() {
        this.auditTrailService.getEntities().subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.entityList = response.data;
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }
    getDebtors() {
        this.creditorService.getDebtors(false)
            .subscribe(response => {
                if (response.Status == 'Success') {
                    this.debtorList = response.data;
                }
            },
                error => {
                    this.router.navigate(['/login']);
                });
    }
    getAuditTrail() {
        var request = {
            StartDate: this.convertDate(this.filter.StartDate),
            EndDate: this.convertDate(this.filter.EndDate),
            EntityId: this.filter.EntityId,
            SortField: this.filter.SortField,
            SortDirection: this.filter.SortDirection,
            PageSize: this.filter.PageSize, 
            CurrentPage: this.filter.CurrentPage, 
            DebtorId: this.filter.DebtorId,
            ShowCurrentUserOnly: this.filter.ShowCurrentUserOnly
        }
        this.spinner.show();
        this.auditTrailService.getAuditTrail(request).subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.auditTrailList = response.data;
                    if (!this.auditTrailDataSource || this.auditTrailDataSource.data.length == 0) {
                        this.auditTrailList.forEach(element => {
                            if (!element.OldValue)
                                element.OldValue = "";
                            if (!element.NewValue)
                                element.NewValue = "";
                            element.isOldValueExpanded = false;
                            element.isNewValueExpanded = false;
                        });
                        this.auditTrailDataSource = new MatTableDataSource(this.auditTrailList);
                        this.auditTrailDataSource.sort = this.auditTrailSort;
                        this.auditTrailDataSource.paginator = this.auditTrailPaginator;
                    }
                    else {
                        var records = [];
                        var totalRecords = (this.filter.CurrentPage - 1) * this.auditTrailPaginator.pageSize;
                        if (totalRecords > 0) {
                            for (var i = 0; i < totalRecords; i++)
                                records.push({});//Added blank values to list in order to get pagination properly
                        }
                        this.auditTrailList.forEach(element => {
                            if (!element.OldValue)
                                element.OldValue = "";
                            if (!element.NewValue)
                                element.NewValue = "";
                            element.isOldValueExpanded = false;
                            element.isNewValueExpanded = false;
                            records.push(element);
                        });
                        this.auditTrailDataSource.data = records;
                    }
                    setTimeout(() => {
                        this.auditTrailDataSource.paginator.pageIndex = this.filter.CurrentPage - 1;
                        if (this.auditTrailList.length > 0)
                            this.auditTrailDataSource.paginator.length = this.auditTrailList[0].TotalRecords;
                    }, 100);
                    this.resizePage();
                }
            },
            error => {
                this.spinner.hide();
                this.router.navigate(['/login']);
            });
    }

    clearFilter() {
        this.filter = {
            StartDate: new Date(),
            EndDate: new Date(),
            EntityId: "",
            SortField: "ActionDate",
            SortDirection: "Desc",
            PageSize: 10,            
            CurrentPage: 1,            
            DebtorId: "",   
            ShowCurrentUserOnly: false
        }
        this.getAuditTrail();
    }

    onPaginateChange(event) {
        if (this.filter.PageSize != event.pageSize) {
            this.filter.PageSize = event.pageSize;
            this.filter.CurrentPage = 1;
            this.auditTrailDataSource.paginator.pageIndex = this.filter.CurrentPage - 1;
        }
        else {
            this.filter.PageSize = event.pageSize;
            this.filter.CurrentPage = event.pageIndex + 1;
        }
        this.getAuditTrail();
    }

    resizePage() {
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }

    restrictInput(event: any) {
        event.preventDefault();
    }

    restrictDelete(event: any) {
        var key = event.keyCode || event.charCode;
        if (key == 8 || key == 46)
            return false;
    }

    filterChange() {
        this.filter.CurrentPage = 1;
        this.auditTrailDataSource.paginator.pageIndex = this.filter.CurrentPage - 1;
        this.filter.PageSize = 10;
        this.getAuditTrail();
    }

    convertDate(str) {
        if (!str)
          return null;
    
        var date = new Date(str),
          month = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [month, day, date.getFullYear()].join("/");
      }
}
