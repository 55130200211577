import {Pipe} from '@angular/core';

@Pipe({
    name: 'phone'
})
export class PhonePipe{
    transform(val, args) {
        if(!val)
            val = "";
            
        val = val.toString().trim().replace(/^\+/, '');
        if (val.match(/[^0-9]/)) {
            return val;
        }
        var country, city, number;

        switch (val.length) {
            case 10:
                country = 1;
                city = val.slice(0, 3);
                number = val.slice(3);
                break;

            case 11:
                country = val[0];
                city = val.slice(1, 4);
                number = val.slice(4);
                break;

            case 12:
                country = val.slice(0, 3);
                city = val.slice(3, 5);
                number = val.slice(5);
                break;

            default:
                return val;
        }

        if (country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);
        return (country + "" + city + "-" + number).trim();
    }
}