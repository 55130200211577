import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common'
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DebtorService } from './debtor.service';
import { CreditorService } from '../creditor/creditor.service';
import { StorageService } from '../common/storage.service';
import { CommonService } from '../common/common.service';
import * as _ from 'underscore';
import { ContactService } from '../contact/contact.service';
import { CompanyService } from '../contact/company.service';
import { AuditTrailService } from '../auditTrail/auditTrail.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageHelper } from '../common/page.helper';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarService } from '../calendar/calendar.service';

const decimalPointFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})

const noDecimalPointFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

declare var $: any;
declare var swal: any;

@Component({
  selector: 'debtor',
  templateUrl: 'debtor.template.html',
  styleUrls: ['../../../assets/css/mat-table.css']
})

export class DebtorComponent implements OnInit {
  @ViewChild('caseUpdatePaginator') caseUpdatePaginator: MatPaginator;
  @ViewChild('caseUpdateSort') caseUpdateSort: MatSort;
  caseUpdatesColumns: string[] = ['UpdateDate', 'OriginalFileName', 'ExternamLink', 'Notes'];
  caseUpdateDataSource: MatTableDataSource<any>;

  @ViewChild('capitalStructureSort') capitalStructureSort: MatSort;
  capitalStructureColumns: string[] = ['InsertionDate', 'originalFileName', 'capitalStructureNote', 'Action'];
  capitalStructureDataSource: MatTableDataSource<any>;

  @ViewChild('valuationPaginator') valuationPaginator: MatPaginator;
  @ViewChild('valuationSort') valuationSort: MatSort;
  valuationColumns: string[] = ['ValuationDate', 'InsertionDate', 'Amount', 'UnsecuredAmount', 'SecuredAmount', 'OriginalFileName', 'User'];
  valuationDataSource: MatTableDataSource<any>;

  @ViewChild('creditorPaginator') creditorPaginator: MatPaginator;
  @ViewChild('creditorSort') creditorSort: MatSort;
  creditorColumns: string[] = ['Company', 'KeyPerson', 'Email', 'ClaimStatus', 'ClaimAmount', 'Amount', 'UnsecuredAmount', 'DebtorCount', 'DoNotCall', 'IsRestricted', 'CUD', 'FileName'];
  creditorDataSource: MatTableDataSource<any>;

  filterCreditors: any = {};
  isInActive = false;
  isEdit = false;

  contactShowMore = false;
  myControlDate1: FormControl = new FormControl();

  claimsAgentList = [];
  industryList = [];

  reasonsChList = [];
  filteredReasonsChList: Observable<string[]>;
  selectedReasonCh = null;

  filteredPrimaryCompanyList: Observable<string[]>;
  selectedPrimaryCompany = null;

  planStatusList = [];
  contactTypeList = [];
  isCaseRegistered: boolean = false;

  beforeDaysList = [];

  //optionsDates = ['Schedules Date', 'Bar Date', '503b9 Bar Date', 'Other Key Dates', 'Key Hearing', 'Disclosure Statement Hearing', 'Confirmation Hearing', 'Other Key Dates'];
  optionsDates = [];
  filteredOptionsDates: Observable<string[]>;

  creditorList: any = [];
  debtorList = [];

  @ViewChild('caseStatsAuditPaginator') caseStatsAuditPaginator: MatPaginator;
  @ViewChild('caseStatsAuditSort') caseStatsAuditSort: MatSort;
  caseStatsAuditColumns: string[] = ['ActionDate', 'Debtor' , 'ActionType', 'ColumnName', 'OldValue', 'NewValue', 'User',];
  caseStatsAuditDataSource: MatTableDataSource<any>;
  auditTrailList: any = [];

  creditorDebtorsList: any = [];
  creditorClaimsList: any = [];

  matrixAndScheduleList: any = [];

  matrixList: any = [];
  scheduleList: any = [];

  currentMatrix: any = "";
  currentSchedule: any = "";
  currentAmenment: any = "";

  debtorForm: FormGroup;
  isDebtorSubmitted: boolean = false;
  isDebtorValueChanged = false;
  EstimatedDistributionDate: any = "";
  applyAllForCaseStats: boolean = false;
  AmountPoolEstimate: any = null;
  UnsecuredClaimsPoolEstimate: any = null;
  copyAmountPoolEstimate: any = null;
  copyUnsecuredClaimsPoolEstimate: any = null;
  editClaimPool: boolean = false;
  isCaseStatsChanged: boolean = false;

  valuationForm: FormGroup;
  isValuationSubmitted: boolean = false;
  valuationList = [];
  valuationAmount = null;
  valuationUnsecuredAmount = null;
  valuationSecuredAmount = null;
  uploadValuationFile = "Upload File";
  listingKey: any = "";

  subDebtorList = [];
  importedFile: any = [];
  subDebtorCount = 0;

  uploadCapitalStructureFile = "Upload File";
  capitalStructureList: any = [];
  capitalStructure = {
    "capitalStructureFileID": null,
    "capitalStructureFileName": null,
    "capitalStructureFileURL": null,
    "capitalStructureFileDate": null,
    "capitalStructureFile": null,
    "capitalStructureNote": null,
    "originalFileName": null,
    "caseListingId": null,
    "IsActive": null
  }
  currentCapitalStructure = null;
  capitalStructureFile: any;

  ContactList = [];
  systemContactList = [];
  companyList = [];
  selectedContact: any = {};
  stateList = [];

  // Add Creditor
  debtorId: any = "";
  allCreditorList = [];
  systemCreditorList = [];
  filterKeyPersonList = [];
  creditor: any = "";
  SelectedCreditor: any = "";
  SelectedKeyPerson: any = "";
  KeyPerson: any = "";
  filter: any = {};
  creditorKeyPerson: any = "";
  keyPersonEmail: any = "";
  keyPersonPhone: any = "";
  claim: any = {
    AddClaim: false,
    ClaimNo: "",
    Amount: 0,
    ClaimType: ""
  }


  caseUpdateForm: FormGroup;
  isCaseUpdateSubmitted: boolean = false;
  caseUpdatesList = [];
  uploadCaseUpdateFile = "Upload File";

  importCreditorListFile = "Upload File";
  creditorListFile: any;
  creditorListFileId: any;
  showInActiveCreditor: boolean = false;
  companyName: any = "";

  searchModel = "";

  matrixFileLabel = "Upload File";
  matrixFile: any;


  uploadScheduleFile = "Upload File";
  scheduleFile: any;
  scheduleSubDebtorId = "";
  scheduleFileType = "";

  contactsCollapsed = false;
  calendarCollapsed = false;

  constructor(private router: Router, private route: ActivatedRoute, private debtorService: DebtorService
    , private storageService: StorageService, private commonService: CommonService, public datepipe: DatePipe
    , public contactService: ContactService, private companyService: CompanyService, private pageHelper: PageHelper
    , private creditorService: CreditorService, private spinner: NgxSpinnerService, private calendarSerivce: CalendarService
    , private auditTrailService: AuditTrailService) {
  }

  ngOnInit() {
    $('#menuDebtors').addClass('active');
    this.clearFilter();
    this.getClaimsList('ClaimStatus');

    this.debtorForm = new FormGroup({
      DebtorId: new FormControl(""),
      CaseListingId: new FormControl(0),
      CaseNumber: new FormControl(""),
      Date: new FormControl(""),
      ReasonCh: new FormControl(""),
      ReasonChNotes: new FormControl(""),
      BankruptcyCourt: new FormControl(""),
      Judge: new FormControl(""),
      Company: new FormControl("", [Validators.required]),
      Description: new FormControl(""),
      Industry: new FormControl(""),
      PlanStatus: new FormControl(""),
      ClaimsAgent: new FormControl(""),
      CriticalVendorMotion: new FormControl(false),
      CriticalVendorMotionNotes: new FormControl(""),
      IsRestricted: new FormControl(false),
      InActive: new FormControl(false)
    });

    this.debtorForm.valueChanges.subscribe(() => {
      this.isDebtorValueChanged = true;
      if (this.isDebtorSubmitted || this.isEdit) {
        Object.keys(this.debtorForm.controls).forEach(key => {
          if ($('#debtor' + key))
            $('#debtor' + key).removeClass('validationError');
        });

        if (this.debtorForm.invalid) {
          Object.keys(this.debtorForm.controls).forEach(key => {
            if (!this.debtorForm.controls[key].valid) {
              if ($('#debtor' + key))
                $('#debtor' + key).addClass('validationError');
            }
          });
        }
      }
    });

    this.valuationForm = new FormGroup({
      Date: new FormControl("", [Validators.required]),
      Amount: new FormControl(""),
      UnsecuredAmount: new FormControl(""),
      SecuredAmount: new FormControl(""),
      File: new FormControl(null),
      CaseListingId: new FormControl(0, [Validators.required])
    });

    this.valuationForm.valueChanges.subscribe(() => {
      if (this.isValuationSubmitted) {
        Object.keys(this.valuationForm.controls).forEach(key => {
          if ($('#valuation' + key))
            $('#valuation' + key).removeClass('validationError');
        });

        if (this.valuationForm.invalid) {
          Object.keys(this.valuationForm.controls).forEach(key => {
            if (!this.valuationForm.controls[key].valid) {
              if ($('#valuation' + key))
                $('#valuation' + key).addClass('validationError');
            }
          });
        }
      }
    });

    let webUrlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.caseUpdateForm = new FormGroup({
      AddLink: new FormControl("", [Validators.pattern(webUrlPattern)]),
      File: new FormControl(null),
      Date: new FormControl("", [Validators.required]),
      caseUpdateNote: new FormControl("")
    });

    this.caseUpdateForm.valueChanges.subscribe(() => {
      if (this.isCaseUpdateSubmitted) {
        Object.keys(this.caseUpdateForm.controls).forEach(key => {
          if ($('#caseUpdate' + key))
            $('#caseUpdate' + key).removeClass('validationError');
        })
        if (this.caseUpdateForm.invalid) {
          Object.keys(this.caseUpdateForm.controls).forEach(key => {
            if (!this.caseUpdateForm.controls[key].valid) {
              if ($('#caseUpdate' + key))
                $('#caseUpdate' + key).addClass('validationError');
            }
          });
        }
      }
    })

    this.getContactList();
    this.getCompanyList();
    this.getAllCreditorList();
    this.filteredOptionsDates = this.myControlDate1.valueChanges.pipe(startWith(''), map(val => this.filterDates(val)));

    this.commonService.getLookup("ClaimsAgent")
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.claimsAgentList = response.data;
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });

    this.commonService.getLookup("Industry")
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.industryList = response.data;
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });

    this.commonService.getLookup("ContactType")
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.contactTypeList = response.data;
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });

    this.commonService.getLookup("ReasonCh11")
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.reasonsChList = response.data;
          this.filteredReasonsChList = this.debtorForm.get("ReasonCh").valueChanges.pipe(
            startWith(''),
            map(value => this._filterReasonCh(value))
          );
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });

    this.commonService.getLookup("PlanStatus")
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.planStatusList = response.data;
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });

    this.commonService.getLookup("DateType")
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.optionsDates = response.data;
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });

    if (this.route.snapshot.queryParams["ListingKey"]) {
      this.listingKey = this.route.snapshot.queryParams["ListingKey"];
      this.isEdit = true;

      this.getDebtor();
      this.getListOfMatrixAndScheduleFiles();
      this.getDebtorList(false);
      this.getImportedFiles();
      //  
      this.commonService.getStates().subscribe(
        response => {
          if (response.Status == 'Success') {
            this.stateList = response.data;
          }
        },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
    }
    else {
      if (this.reasonsChList && this.reasonsChList.length > 0) {
        this.filteredReasonsChList = this.debtorForm.get("ReasonCh").valueChanges.pipe(
          startWith(''),
          map(value => this._filterReasonCh(value))
        );
      }
    }
    this.resizePage();

    for (var _i = 0; _i <= 100; _i++) {
        this.beforeDaysList.push(_i.toString());
    }
  }

  getDebtor() {
    if (this.listingKey) {
      this.spinner.show();
      this.debtorService.getDetails(this.listingKey)
        .subscribe(response => {
          this.spinner.hide();
          if (response.Status == 'Success') {
            this.setDebtorFormValues(response.data);
            this.getCaseListingDates();
            this.getCaseStatsAuditReport(this.debtorId);
            this.subDebtorList = response.data.SubDebtorList;
            this.subDebtorCount = this.subDebtorList.length - 1;
            this.currentCapitalStructure = response.data.currentCapitalStructure;
            this.capitalStructureList = response.data.CapitalStructureList;
            this.capitalStructureDataSource = new MatTableDataSource(this.capitalStructureList);
            this.capitalStructureDataSource.sort = this.capitalStructureSort;
            this.resizePage();

            this.ContactList = response.data.ContactList;
            console.log('ContactList', this.ContactList);
            this.ContactList.forEach(element => {
              element.SelectedContact = element.ContactId;
              element.SelectedCompany = element.CompanyId;
              element.ContactTypeList = this.contactTypeList;
              element.ContactList = this.systemContactList;
              element.CompanyList = this.companyList;
              if (!element.ContactId && !element.Contact) {
                element.isEdit = true;
              }
            });

            this.subDebtorList.forEach(element => {
              // element.CaseStats.ConvenienceClassThreshold = element.CaseStats.ConvenienceClassThreshold;
              // element.CaseStats.ConvenienceCurrentBid = parseFloat(element.CaseStats.ConvenienceCurrentBid).toFixed(2);
              // element.CaseStats.ConvenienceProjectedRecovery = parseFloat(element.CaseStats.ConvenienceProjectedRecovery).toFixed(2);
              // element.CaseStats.CurrentBid_Amount = parseFloat(element.CaseStats.CurrentBid_Amount).toFixed(2);
              // element.CaseStats.ProjectedRecovery_Amount = parseFloat(element.CaseStats.ProjectedRecovery_Amount).toFixed(2);
              // element.CaseStats.UnsecuredCurrentBid = parseFloat(element.CaseStats.UnsecuredCurrentBid).toFixed(2);
              // element.CaseStats.UnsecuredProjectedRecovery = parseFloat(element.CaseStats.UnsecuredProjectedRecovery).toFixed(2);


              element.CaseStats.ConvenienceClassThreshold = element.CaseStats.ConvenienceClassThreshold.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.ConvenienceCurrentBid = element.CaseStats.ConvenienceCurrentBid.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.ConvenienceProjectedRecovery = element.CaseStats.ConvenienceProjectedRecovery.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.CurrentBid_Amount = element.CaseStats.CurrentBid_Amount.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.ProjectedRecovery_Amount = element.CaseStats.ProjectedRecovery_Amount.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.UnsecuredCurrentBid = element.CaseStats.UnsecuredCurrentBid.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.UnsecuredProjectedRecovery = element.CaseStats.UnsecuredProjectedRecovery.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");

              if (element.CaseStats.ConvenienceClassThreshold.toString().indexOf('.') > -1)
                element.CaseStats.ConvenienceClassThreshold = decimalPointFormatter.format(element.CaseStats.ConvenienceClassThreshold);
              else
                element.CaseStats.ConvenienceClassThreshold = noDecimalPointFormatter.format(element.CaseStats.ConvenienceClassThreshold);

              if (element.CaseStats.ConvenienceCurrentBid.toString().indexOf('.') > -1)
                element.CaseStats.ConvenienceCurrentBid = (decimalPointFormatter.format(element.CaseStats.ConvenienceCurrentBid)).replace('$', '') + '%';
              else
                element.CaseStats.ConvenienceCurrentBid = (noDecimalPointFormatter.format(element.CaseStats.ConvenienceCurrentBid)).replace('$', '') + '%';

              if (element.CaseStats.ConvenienceProjectedRecovery.toString().indexOf('.') > -1)
                element.CaseStats.ConvenienceProjectedRecovery = (decimalPointFormatter.format(element.CaseStats.ConvenienceProjectedRecovery)).replace('$', '') + '%';
              else
                element.CaseStats.ConvenienceProjectedRecovery = (noDecimalPointFormatter.format(element.CaseStats.ConvenienceProjectedRecovery)).replace('$', '') + '%';


              if (element.CaseStats.CurrentBid_Amount.toString().indexOf('.') > -1)
                element.CaseStats.CurrentBid_Amount = (decimalPointFormatter.format(element.CaseStats.CurrentBid_Amount)).replace('$', '') + '%';
              else
                element.CaseStats.CurrentBid_Amount = (noDecimalPointFormatter.format(element.CaseStats.CurrentBid_Amount)).replace('$', '') + '%';

              if (element.CaseStats.ProjectedRecovery_Amount.toString().indexOf('.') > -1)
                element.CaseStats.ProjectedRecovery_Amount = (decimalPointFormatter.format(element.CaseStats.ProjectedRecovery_Amount)).replace('$', '') + '%';
              else
                element.CaseStats.ProjectedRecovery_Amount = (noDecimalPointFormatter.format(element.CaseStats.ProjectedRecovery_Amount)).replace('$', '') + '%';

              if (element.CaseStats.UnsecuredCurrentBid.toString().indexOf('.') > -1)
                element.CaseStats.UnsecuredCurrentBid = (decimalPointFormatter.format(element.CaseStats.UnsecuredCurrentBid)).replace('$', '') + '%';
              else
                element.CaseStats.UnsecuredCurrentBid = (noDecimalPointFormatter.format(element.CaseStats.UnsecuredCurrentBid)).replace('$', '') + '%';

              if (element.CaseStats.UnsecuredProjectedRecovery.toString().indexOf('.') > -1)
                element.CaseStats.UnsecuredProjectedRecovery = (decimalPointFormatter.format(element.CaseStats.UnsecuredProjectedRecovery)).replace('$', '') + '%';
              else
                element.CaseStats.UnsecuredProjectedRecovery = (noDecimalPointFormatter.format(element.CaseStats.UnsecuredProjectedRecovery)).replace('$', '') + '%';

              var company = _.find(this.companyList, function (item) {
                return item.CompanyId == element.CompanyId;
              });

              if (company) {
                element.Name = company.Name;
                element.SelectedSubDebtor = company;
                element.CompanyId = company.CompanyId;
              }
              else {
                element.SelectedSubDebtor = null;
                element.CompanyId = "";
              }
              element.FilteredSubDebtorList = this.companyList;
            });

            this.valuationForm.controls['CaseListingId'].setValue(response.data.CaseListingId);
            this.getCreditorList();

            this.getValuation();
            setTimeout(() => {
              this.getCaseUpdateDataList();
            }, 500);

            if (this.companyList && this.companyList.length > 0) {
              var CompanyId = this.debtorForm.get("Company").value;
              var result = _.find(this.companyList, function (company) {
                return company.CompanyId == CompanyId;
              });
              if (result) {
                this.debtorForm.get("Company").setValue(result.Name);
                this.selectedPrimaryCompany = result;
                this.companyName = result.Name;
              }
            }
            this.isDebtorValueChanged = false;
          }
        },
          error => {
            this.spinner.hide();
            this.storageService.clear();
            this.router.navigate(['/login']);
          }
        );
    }
  }
  getCaseStatsAuditReport(debtorId) {
    this.spinner.show();
    this.auditTrailService.getCaseStatsAudit(debtorId).subscribe(response => {
      if (response.Token)
        this.storageService.setData('EncryptedToken', response.Token);

      if (response.Status == 'Success') {
        this.auditTrailList = response.data;
        this.caseStatsAuditDataSource = new MatTableDataSource(this.auditTrailList);
        this.caseStatsAuditDataSource.sort = this.caseStatsAuditSort;
        this.caseStatsAuditDataSource.paginator = this.caseStatsAuditPaginator;
      }
      this.resizePage();
      this.spinner.hide();
    },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  getListOfMatrixAndScheduleFiles() {
    this.debtorService.getMatrixAndScheduleFiles(this.listingKey)
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.matrixAndScheduleList = response.data;


          var matrixfile = this.matrixAndScheduleList.filter(option => option.FileType == 'Matrix');
          var scheduleFile = this.matrixAndScheduleList.filter(option => option.FileType == 'Schedule');
          var amendment = this.matrixAndScheduleList.filter(option => option.FileType == 'Amendment');

          if (matrixfile.length > 0)
            this.currentMatrix = matrixfile[0]['FileURL'];

          if (scheduleFile.length > 0)
            this.currentSchedule = scheduleFile[0]['FileURL'];

          if (amendment.length > 0)
            this.currentAmenment = amendment[0]['FileURL'];

          this.matrixList = this.matrixAndScheduleList.filter(option => option.FileType == 'Matrix');
          this.scheduleList = this.matrixAndScheduleList.filter(option => option.FileType == 'Schedule' || option.FileType == 'Amendment');

        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  getContactList() {
    this.contactService.search(false).subscribe(
      response => {
        if (response.Status == 'Success') {
          this.systemContactList = response.data;
          this.resizePage();
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  getDebtorContacts() {
    this.debtorService.getDebtorContacts(this.debtorForm.get('CaseListingId').value).subscribe(
      response => {
        if (response.Status == 'Success') {
          this.ContactList = response.data;
          this.ContactList.forEach(element => {
            element.SelectedContact = element.ContactId;
            element.SelectedCompany = element.CompanyId;
            element.ContactTypeList = this.contactTypeList;
            element.ContactList = this.systemContactList;
            element.CompanyList = this.companyList;
            if (!element.ContactId && !element.Contact) {
              element.isEdit = true;
            }
          });
          this.resizePage();
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  getCompanyList(showAll: boolean = false) {
    this.companyService.getList(showAll).subscribe(
      response => {
        var isNew = true;
        if (this.companyList && this.companyList.length > 0)
          isNew = false;
        if (response.Status == 'Success') {
          this.companyList = response.data;
          this.filteredPrimaryCompanyList = this.debtorForm.get("Company").valueChanges.pipe(
            startWith(''),
            map(value => this._filterPrimaryDebtorCompany(value))
          );

          if (this.isEdit && isNew) {
            var CompanyId = this.debtorForm.get("Company").value;
            var result = _.find(this.companyList, function (company) {
              return company.CompanyId == CompanyId;
            });
            if (result) {
              this.debtorForm.get("Company").setValue(result.Name);
              this.selectedPrimaryCompany = result;
              this.companyName = result.Name;
            }
          }
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  filterDates(val: string): string[] {
    return this.optionsDates.filter(option =>
      option.toLowerCase().includes(val.toLowerCase()));
  }

  contactToggleShow() {
    if (this.contactShowMore == false)
      this.contactShowMore = true;
    else
      this.contactShowMore = false;
    this.resizePage();
  }

  getDebtorList(showAll: any = false) {
    this.debtorService.search(showAll).subscribe(
      response => {
        if (response.Status == 'Success')
          this.creditorDebtorsList = response.data;
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  getCreditorList() {
    this.spinner.show();
    this.searchModel = "";
    this.debtorService.getCreditorList(this.showInActiveCreditor, this.debtorForm.get('DebtorId').value).subscribe(
      response => {
        this.spinner.hide();
        if (response.Status == 'Success') {
          this.creditorList = response.data;
          this.creditorDataSource = new MatTableDataSource(this.creditorList);
          this.creditorDataSource.paginator = this.creditorPaginator;
          this.creditorDataSource.sort = this.creditorSort;
          this.resizePage();
        }
      },
      error => {
        this.spinner.hide();
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  getClaimsList(type) {
    this.commonService.getLookup(type, false).subscribe(
      response => {
        if (response.Status == 'Success') {
          this.creditorClaimsList = response.data;
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  clearFilterCreditors() {
    this.filterCreditors = {
      "MinAmount": "",
      "Status": "Active",
      "ClaimType": "All"
    }
  }

  _filterReasonCh(value: string): string[] {
    if (this.selectedReasonCh) {
      this.selectedReasonCh = null;
    }

    if (!value)
      value = "";

    const filterValue = value.toLowerCase();
    return this.reasonsChList.filter(option => option.LookupValue.toLowerCase().indexOf(filterValue) === 0);
  }

  selectReason(data) {
    this.selectedReasonCh = data.option.value;
  }

  selectPrimaryCompany(data) {
    if (data.option.value) {
      var result = _.find(this.companyList, function (company) {
        return company.CompanyId == data.option.value;
      });
      if (result) {
        this.debtorForm.get("Company").setValue(result.Name);
        this.selectedPrimaryCompany = result;
        this.companyName = result.Name;
      }
    }
    else {
      this.selectedPrimaryCompany = null;
      this.debtorForm.get("Company").setValue("");
    }
  }

  restrictInput(event: any) {
    event.preventDefault();
  }

  restrictDelete(event: any) {
    var key = event.keyCode || event.charCode;

    if (key == 8 || key == 46)
      return false;
  }

  keyPressInteger(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  keyPressFloat(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  convertDate(str) {
    if (!str)
      return null;

    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  }

  praseTwoDecimalFloat(event) {
    var value = parseFloat(event.target.value.replace('%', '')).toFixed(2);
    if (value == 'NaN') {
      value = "0.00%";
    }
    if (value)
      event.target.value = value + '%';
    else
      event.target.value = "0.00%";
  }

  praseNoDecimalFloat(event) {
    var value = parseFloat(event.target.value.replace('$', '')).toFixed(0);
    if (value == 'NaN') {
      value = "$0";
    }
    if (value)
      event.target.value = '$' + value;
    else
      event.target.value = "$0";
  }

  openLink(link) {
    var tempLink = link;
    if (link.indexOf("http:") == -1 && link.indexOf("https:") == -1) {
      tempLink = "http://" + link;
    }
    window.open(tempLink, "_blank");
  }

  openDebtorsModal(item) {
    if (item) {
      this.debtorList = item.SelectedDebtors;
      $('#modal_creditor_debtors').modal('show');
    }
  }

  closeDebtorModal() {
    this.debtorList = [];
  }

  //#region Valuation

  valuationFileChange(event) {
    if (event.target.files[0]) {
      this.valuationForm.controls['File'].setValue(event.target.files[0]);
      this.uploadValuationFile = event.target.files[0].name;
    }
    else {
      $("#uploadValuationFile").val(null);
      this.valuationForm.controls['File'].setValue(null);
      this.uploadValuationFile = "Upload File";
    }
  }

  valuationAmountChange(event, input) {
    var value = parseFloat(event.target.value).toFixed(2);
    if (value == 'NaN') {
      value = "";
    }
    if (value)
      event.target.value = value;
    else
      event.target.value = "";
    switch (input) {
      case 'Amount':
        this.valuationForm.controls['Amount'].setValue(value);
        break;
      case 'Unsecured':
        this.valuationForm.controls['UnsecuredAmount'].setValue(value);
        break;
      case 'Secured':
        this.valuationForm.controls['SecuredAmount'].setValue(value);
        break;
    }
  }

  getValuation() {
    $("#uploadValuationFile").val(null);
    this.uploadValuationFile = "Upload File";
    this.valuationAmount = null;
    this.valuationUnsecuredAmount = null;
    this.valuationSecuredAmount = null;
    $("#valuationAmount").val(null);
    $("#valuationUnsecuredAmount").val(null);
    $("#valuationSecuredAmount").val(null);

    this.valuationForm.setValue({
      Date: "",
      Amount: "",
      UnsecuredAmount: "",
      SecuredAmount: "",
      File: "",
      CaseListingId: this.debtorForm.get('CaseListingId').value
    });

    this.spinner.show();
    this.commonService.getValuationList(false, this.valuationForm.get('CaseListingId').value).subscribe(
      response => {
        this.spinner.hide();

        if (response.Status == 'Success') {
          this.valuationList = response.data;
          this.valuationDataSource = new MatTableDataSource(this.valuationList);
          this.valuationDataSource.paginator = this.valuationPaginator;
          this.valuationDataSource.sort = this.valuationSort;
          this.resizePage();
        }
      },
      error => {
        this.spinner.hide();
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  saveValuations() {
    Object.keys(this.valuationForm.controls).forEach(key => {
      if ($('#valuation' + key))
        $('#valuation' + key).removeClass('validationError');
    });

    if (this.valuationForm.get('Amount').value && parseFloat(this.valuationForm.get('Amount').value) > 100) {
      swal("Error", "Please enter 50b9 value less then or equal to 100", "error");
      return;
    }

    if (this.valuationForm.get('UnsecuredAmount').value && parseFloat(this.valuationForm.get('UnsecuredAmount').value) > 100) {
      swal("Error", "Please enter unsecured value less then or equal to 100", "error");
      return;
    }

    if (this.valuationForm.get('SecuredAmount').value && parseFloat(this.valuationForm.get('SecuredAmount').value) > 100) {
      swal("Error", "Please enter secured value less then or equal to 100", "error");
      return;
    }

    this.isValuationSubmitted = true;

    if (this.valuationForm.valid) {
      var request = {
        ValuationDate: this.convertDate(this.valuationForm.get('Date').value),
        Amount: this.valuationForm.get('Amount').value ? this.valuationForm.get('Amount').value.replace('%','') : '',
        UnsecuredAmount: this.valuationForm.get('UnsecuredAmount').value ? this.valuationForm.get('UnsecuredAmount').value.replace('%','') : '',
        SecuredAmount: this.valuationForm.get('SecuredAmount').value ? this.valuationForm.get('SecuredAmount').value.replace('%','') : '',
        CaseListingId: this.valuationForm.get('CaseListingId').value
      }
      this.isValuationSubmitted = false;
      this.spinner.show();
      this.commonService.saveValuation(request, this.valuationForm.get('File').value)
        .subscribe(response => {
          this.spinner.hide();

          if (response.Status == 'Success') {
            swal('Success', "Valuation Saved Successfully", 'success');
            this.getValuation();
          }
          else
            swal('Error', response.Message, 'error');
        },
          error => {
            this.spinner.hide();
            this.storageService.clear();
            this.router.navigate(['/login']);
          });
    }
    else {
      Object.keys(this.valuationForm.controls).forEach(key => {
        if (!this.valuationForm.controls[key].valid) {
          if ($('#valuation' + key))
            $('#valuation' + key).addClass('validationError');
        }
      });
    }
  }

  //#endregion

  //#region Debtor

  saveDebtor() {
    Object.keys(this.debtorForm.controls).forEach(key => {
      if ($('#debtor' + key))
        $('#debtor' + key).removeClass('validationError');
    });
    this.isDebtorSubmitted = true;

    if (this.debtorForm.valid) {
      var request = this.getDebtorRequestObject();

      this.spinner.show();
      this.debtorService.saveDebtor(request)
        .subscribe(response => {
          this.spinner.hide();

          this.getCompanyList();
          if (response.Status == 'Success') {
            this.isDebtorSubmitted = false;
            this.isDebtorValueChanged = false;
            swal('Success', response.Message, 'success');
            if (!this.isEdit) {
              //this.router.navigate(["debtor-search"]);
              this.router.navigate(['/debtor'], { queryParams: { ListingKey: response.Data } });
              window.location.reload();
            }
            else
              if (this.debtorForm.controls["CaseNumber"].value)
                this.isCaseRegistered = true;
              else
                this.isCaseRegistered = false;
          }
          else {
            swal('Error', response.Message, 'error');
          }
        },
          error => {
            this.spinner.hide();
            this.storageService.clear();
            this.router.navigate(['/login']);
          });
    }
    else {
      Object.keys(this.debtorForm.controls).forEach(key => {
        if (!this.debtorForm.controls[key].valid) {
          if ($('#debtor' + key))
            $('#debtor' + key).addClass('validationError');
        }
      });
    }
  }

  setDebtorFormValues(data: any) {
    this.isDebtorSubmitted = false;
    Object.keys(this.debtorForm.controls).forEach(key => {
      if ($('#debtor' + key))
        $('#debtor' + key).removeClass('validationError');
    });

    if (data) {
      this.debtorForm.setValue({
        DebtorId: data.DebtorId,
        CaseListingId: data.CaseListingId,
        CaseNumber: data.CaseNumber,
        Date: data.FilingDate,
        ReasonCh: data.Reason,
        ReasonChNotes: data.ReasonChNotes,
        BankruptcyCourt: data.BankruptcyCourt,
        Judge: data.Judge,
        Company: data.Company,
        Description: data.DebtorDescription,
        Industry: data.IndustryId,
        PlanStatus: data.PlanStatusId,
        ClaimsAgent: data.ClaimsAgentId,
        CriticalVendorMotion: data.CriticalVendorMotion,
        CriticalVendorMotionNotes: data.CriticalVendorMotionNotes,
        IsRestricted: data.IsRestricted,
        InActive: !data.IsActive
      });

      if (data.CaseNumber)
        this.isCaseRegistered = true;
      else
        this.isCaseRegistered = false;

      this.debtorId = data.DebtorId;

      if (data.ReasonId)
        this.selectedReasonCh = data.Reason;
      else
        this.selectedReasonCh = null;

      if (data.ApplyAllForCaseStats)
        this.applyAllForCaseStats = true;
      else
        this.applyAllForCaseStats = false;

      if (data.EstimatedDistributionDate)
        this.EstimatedDistributionDate = data.EstimatedDistributionDate;

      if (data.AmountPoolEstAmount) {
        this.AmountPoolEstimate = data.AmountPoolEstAmount;
        this.copyAmountPoolEstimate = data.AmountPoolEstAmount;
      }

      if (data.UnsecuredPoolEstAmount) {
        this.UnsecuredClaimsPoolEstimate = data.UnsecuredPoolEstAmount;
        this.copyUnsecuredClaimsPoolEstimate = data.UnsecuredPoolEstAmount;
      }
    }
  }

  getDebtorRequestObject() {
    var ReasonId = null;
    var Reason = null;
    if (this.selectedReasonCh) {
      var self = this;
      var reason = _.find(this.reasonsChList, function (data) {
        return data.LookupValue == self.selectedReasonCh;
      });

      ReasonId = reason.LookupId;
    }
    else
      Reason = this.debtorForm.get('ReasonCh').value;

    return {
      DebtorId: this.debtorForm.get('DebtorId').value,
      CaseListingId: this.debtorForm.get('CaseListingId').value,
      CaseNumber: this.debtorForm.get('CaseNumber').value,
      FilingDate: this.convertDate(this.debtorForm.get('Date').value),
      ReasonId: ReasonId,
      Reason: Reason,
      ReasonChNotes: this.debtorForm.get('ReasonChNotes').value,
      BankruptcyCourt: this.debtorForm.get('BankruptcyCourt').value,
      Judge: this.debtorForm.get('Judge').value,
      CompanyId: this.selectedPrimaryCompany ? this.selectedPrimaryCompany.CompanyId : '',
      Company: this.debtorForm.get('Company').value,
      DebtorDescription: this.debtorForm.get('Description').value,
      IndustryId: this.debtorForm.get('Industry').value,
      PlanStatusId: this.debtorForm.get('PlanStatus').value,
      ClaimsAgentId: this.debtorForm.get('ClaimsAgent').value,
      CriticalVendorMotion: this.debtorForm.get('CriticalVendorMotion').value,
      CriticalVendorMotionNotes: this.debtorForm.get('CriticalVendorMotionNotes').value,
      IsRestricted: this.debtorForm.get('IsRestricted').value,
      IsActive: !this.debtorForm.get('InActive').value,
      SubDebtorList: this.subDebtorList ? this.subDebtorList : [],
      CapitalStructureList: this.capitalStructureList ? this.capitalStructureList : []
    }
  }
  //#endregion

  //#region Sub-Debtor

  openSubdebtor() {
    if (!this.subDebtorList || this.subDebtorList.length == 0 || this.subDebtorList.length == 1) {
      this.addSubDebtor();
    }
  }

  addSubDebtor() {
    if (!this.subDebtorList)
      this.subDebtorList = [];

    this.subDebtorList.push({
      'SubDebtorKey': "",
      'Name': "",
      'CompanyId': "",
      'SelectedSubDebtor': {},
      'FilteredSubDebtorList': this.companyList,
      'IsActive': true,
      "CaseStats": {
        "CaseStatsId": "",
        "ConvenienceClassThreshold": 0,
        "ConvenienceCurrentBid": 0,
        "ConvenienceProjectedRecovery": 0,
        "CurrentBid_Amount": 0,
        "ProjectedRecovery_Amount": 0,
        "UnsecuredCurrentBid": 0,
        "UnsecuredProjectedRecovery": 0
      }
    });
  }

  removeSubDebtor(index) {
    if (this.subDebtorList[index].SubDebtorKey) {
      var self = this;
      swal({
        title: 'Warning',
        text: "Are you sure you want to delete sub-debtor?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(function (isConfirmed) {
        if (isConfirmed.value) {
          self.subDebtorList[index].IsActive = false;
        }
      });
    }
    else {
      this.subDebtorList.splice(index, 1);
    }
  }

  filterSubDebtor(val, index) {
    if (val) {
      if (this.subDebtorList[index].SelectedSubDebtor) {
        this.subDebtorList[index].SelectedSubDebtor = null;
        this.subDebtorList[index].CompanyId = "";
      }

      const filterValue = val.toLowerCase();
      return this.companyList.filter(company => company.Name.toLowerCase().startsWith(filterValue));
    }
    return this.companyList;
  }

  selectSubDebtor(data, index) {
    var company = _.find(this.companyList, function (item) {
      return item.CompanyId == data.option.value;
    });

    if (company) {
      this.subDebtorList[index].Name = company.Name;
      this.subDebtorList[index].SelectedSubDebtor = company;
      this.subDebtorList[index].CompanyId = company.CompanyId;
    }
    else {
      this.subDebtorList[index].SelectedSubDebtor = null;
      this.subDebtorList[index].CompanyId = "";
    }
  }

  saveSubDebtors() {
    var hasError = false;
    this.subDebtorList.forEach(element => {
      if (element.Name)
        element.Name = element.Name.trim();

      if (!element.Name) {
        hasError = true;
      }

    });

    if (hasError) {
      swal('Error', 'Please enter name of all sub-debtor or delete unnecessary sub-debtors.', 'error');
      return;
    }

    var request = {
      CaseListingId: this.debtorForm.get('CaseListingId').value,
      SubDebtorList: null
    };

    request.SubDebtorList = _.filter(this.subDebtorList, function (subdebtor) {
      if (!subdebtor.IsPrimary)
        return subdebtor;
    });

    this.spinner.show();
    this.debtorService.saveSubDebtor(request)
      .subscribe(response => {
        this.spinner.hide();

        this.getCompanyList();
        if (response.Status == 'Success') {
          this.subDebtorCount = this.subDebtorList.length - 1;
          $('#modal_subdebtor').modal('hide');
          swal('Success', response.Message, 'success')
          var tmpSubDebtorList = [];

          this.debtorService.getSubDebtors(this.debtorForm.get('CaseListingId').value).subscribe(
            response => {
              if (response.Status == 'Success') {
                tmpSubDebtorList = response.data;
                this.isCaseStatsChanged = true;
                tmpSubDebtorList.forEach(element => {
                  var subDebtorTemp = _.find(this.subDebtorList, function (data) {
                    return data.SubDebtorKey == element.SubDebtorKey;
                  });

                  if (subDebtorTemp) {
                    element.CaseStats = subDebtorTemp.CaseStats
                  }
                  else {
                    element.CaseStats = {
                      "CaseStatsId": "",
                      "ConvenienceClassThreshold": '$' + 0,
                      "ConvenienceCurrentBid": 0 + '%',
                      "ConvenienceProjectedRecovery": 0 + '%',
                      "CurrentBid_Amount": 0 + '%',
                      "ProjectedRecovery_Amount": 0 + '%',
                      "UnsecuredCurrentBid": 0 + '%',
                      "UnsecuredProjectedRecovery": 0 + '%'
                    }
                  }

                  var company = _.find(this.companyList, function (item) {
                    return item.CompanyId == element.CompanyId;
                  });

                  if (company) {
                    element.SelectedSubDebtor = company;
                  }

                  element.FilteredSubDebtorList = this.companyList;
                });
                this.subDebtorList = tmpSubDebtorList;
                this.subDebtorCount = this.subDebtorList.length - 1;

                if (this.applyAllForCaseStats)
                  this.applyToAll(null);
              }
            },
            error => {
              this.storageService.clear();
              this.router.navigate(['/login']);
            });
        }
        else {
          swal('Error', response.Message, 'error');
        }
      },
        error => {
          this.spinner.hide();
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  cancelSubDebtors() {
    this.subDebtorList.forEach(element => {
      if (!element.IsPrimary) {
        if (!element.IsActive) {
          element.IsActive = true;
        }
      }
    });

    this.subDebtorList = _.filter(this.subDebtorList, function (subdebtor) {
      if (subdebtor.SubDebtorKey)
        return subdebtor;
    });
  }

  //#endregion

  //#region Capital Structure

  capitalStructureFileChange(event) {
    if (event.target.files[0]) {
      this.capitalStructureFile = event.target.files[0];
      $('#capitalStructureFile').removeClass('validationError');
      this.uploadCapitalStructureFile = event.target.files[0].name;
    }
    else {
      this.capitalStructureFile = null;
      $('#capitalStructureFile').addClass('validationError');
      this.capitalStructure.capitalStructureFile = null;
      $("#uploadCapitalStructureFile").val(null);
      this.uploadCapitalStructureFile = "Upload File";
    }
  }

  uploadCapitalStructure() {
    $('#capitalStructureFile').removeClass('validationError');
    if (!this.capitalStructureFile) {
      $('#capitalStructureFile').addClass('validationError');
      return;
    }
    var CaseListingId = this.debtorForm.get('CaseListingId').value;
    this.spinner.show();
    this.debtorService.uploadCapitalStructure(this.capitalStructureFile, this.capitalStructure.capitalStructureNote, CaseListingId)
      .subscribe(response => {
        this.spinner.hide();

        if (response.Status == 'Success') {
          if (!this.capitalStructureList)
            this.capitalStructureList = [];

          this.getDebtorCapitalStructure();
          this.capitalStructureFile = null;
          $('#modal_capital_structure_add').modal('hide');
          swal('Success', 'Capital Structure uploaded successfully', 'success');
        }
        this.capitalStructure.capitalStructureFile = null;
        $("#uploadCapitalStructureFile").val(null);
        this.uploadCapitalStructureFile = "Upload File";
        this.capitalStructure.capitalStructureNote = null;
      },
        error => {
          this.spinner.hide();
          this.storageService.clear();
          this.router.navigate(['/login']);
        }
      );
  }

  cancelUploadCapitalStructure() {
    this.capitalStructure.capitalStructureFile = null;
    this.capitalStructureFile = null;
    $('#capitalStructureFile').removeClass('validationError');
    $("#uploadCapitalStructureFile").val(null);
    this.uploadCapitalStructureFile = "Upload File";
  }

  deleteCapitalStructure(item, index) {
    var CaseListingId = this.debtorForm.get('CaseListingId').value
    if (CaseListingId) {
      var self = this;
      swal({
        title: 'Warning',
        text: "Are you sure you want to delete Capital Structure File?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(function (isConfirmed) {
        if (isConfirmed.value) {
          self.capitalStructureList[index].IsActive = false;
          var request = {
            CaseListingId: self.debtorForm.get('CaseListingId').value,
            CapitalStructureList: [self.capitalStructureList[index]]
          };
          self.spinner.show();
          self.debtorService.saveCapitalStructure(request)
            .subscribe(response => {
              self.spinner.hide();
              if (response.Token)
                self.storageService.setData('EncryptedToken', response.Token);

              if (response.Status == 'Success') {
                swal('Success', "Capital Structure removed successfully", 'success');
                self.getDebtorCapitalStructure();
              }
              else {
                swal('Error', response.Message, 'error');
              }
            },
              error => {
                self.spinner.hide();
                self.storageService.clear();
                self.router.navigate(['/login']);
              });
        }
      });
    }
  }

  getDebtorCapitalStructure() {
    var CaseListingId = this.debtorForm.get('CaseListingId').value
    this.debtorService.getDebtorCapitalStructure(CaseListingId).subscribe(
      response => {
        if (response.Status == 'Success') {
          this.currentCapitalStructure = response.data.currentCapitalStructure;
          this.capitalStructureList = response.data.CapitalStructureList;
          this.capitalStructureDataSource = new MatTableDataSource(this.capitalStructureList);
          this.capitalStructureDataSource.sort = this.capitalStructureSort;
          this.resizePage();
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  //#endregion

  //#region Case Statistics

  applyToAll(item, changeStatus = true) {
    if (changeStatus)
      this.isCaseStatsChanged = true;
    if (this.applyAllForCaseStats) {
      if (item && !item.IsPrimary) {
        var self = this;
        swal({
          title: 'Warning',
          text: "Are you sure you want to change value of debtor: " + item.Name + "?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(function (isConfirmed) {
          if (isConfirmed.value) {
            self.applyAllForCaseStats = false;
            return;
          }
          else
            self.copyCaseStatsToAll();
        });
      }
      else
        this.copyCaseStatsToAll();
    }
  }

  copyCaseStatsToAll() {
    var primaryDebtorTemp = _.find(this.subDebtorList, function (data) {
      return data.IsPrimary == true;
    });

    if (primaryDebtorTemp) {
      let primaryDebtor = JSON.parse(JSON.stringify(primaryDebtorTemp));

      primaryDebtor.CaseStats.ConvenienceClassThreshold = primaryDebtor.CaseStats.ConvenienceClassThreshold.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
      primaryDebtor.CaseStats.ConvenienceCurrentBid = primaryDebtor.CaseStats.ConvenienceCurrentBid.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
      primaryDebtor.CaseStats.ConvenienceProjectedRecovery = primaryDebtor.CaseStats.ConvenienceProjectedRecovery.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
      primaryDebtor.CaseStats.CurrentBid_Amount = primaryDebtor.CaseStats.CurrentBid_Amount.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
      primaryDebtor.CaseStats.ProjectedRecovery_Amount = primaryDebtor.CaseStats.ProjectedRecovery_Amount.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
      primaryDebtor.CaseStats.UnsecuredCurrentBid = primaryDebtor.CaseStats.UnsecuredCurrentBid.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
      primaryDebtor.CaseStats.UnsecuredProjectedRecovery = primaryDebtor.CaseStats.UnsecuredProjectedRecovery.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");

      this.subDebtorList.forEach(element => {
        if (!element.IsPrimary && element.IsActive) {
          // element.CaseStats.ConvenienceClassThreshold = primaryDebtor.CaseStats.ConvenienceClassThreshold.replace('$','');
          // element.CaseStats.ConvenienceCurrentBid = parseFloat(primaryDebtor.CaseStats.ConvenienceCurrentBid).toFixed(2);
          // element.CaseStats.ConvenienceProjectedRecovery = parseFloat(primaryDebtor.CaseStats.ConvenienceProjectedRecovery).toFixed(2);
          // element.CaseStats.CurrentBid_Amount = parseFloat(primaryDebtor.CaseStats.CurrentBid_Amount).toFixed(2);
          // element.CaseStats.ProjectedRecovery_Amount = parseFloat(primaryDebtor.CaseStats.ProjectedRecovery_Amount).toFixed(2);
          // element.CaseStats.UnsecuredCurrentBid = parseFloat(primaryDebtor.CaseStats.UnsecuredCurrentBid).toFixed(2);
          // element.CaseStats.UnsecuredProjectedRecovery = parseFloat(primaryDebtor.CaseStats.UnsecuredProjectedRecovery).toFixed(2);

          if (primaryDebtor.CaseStats.ConvenienceClassThreshold.toString().indexOf('.') > -1)
            element.CaseStats.ConvenienceClassThreshold = decimalPointFormatter.format(primaryDebtor.CaseStats.ConvenienceClassThreshold);
          else
            element.CaseStats.ConvenienceClassThreshold = noDecimalPointFormatter.format(primaryDebtor.CaseStats.ConvenienceClassThreshold);

          if (primaryDebtor.CaseStats.ConvenienceCurrentBid.toString().indexOf('.') > -1)
            element.CaseStats.ConvenienceCurrentBid = (decimalPointFormatter.format(primaryDebtor.CaseStats.ConvenienceCurrentBid)).replace('$', '') + '%';
          else
            element.CaseStats.ConvenienceCurrentBid = (noDecimalPointFormatter.format(primaryDebtor.CaseStats.ConvenienceCurrentBid)).replace('$', '') + '%';

          if (primaryDebtor.CaseStats.ConvenienceProjectedRecovery.toString().indexOf('.') > -1)
            element.CaseStats.ConvenienceProjectedRecovery = (decimalPointFormatter.format(primaryDebtor.CaseStats.ConvenienceProjectedRecovery)).replace('$', '') + '%';
          else
            element.CaseStats.ConvenienceProjectedRecovery = (noDecimalPointFormatter.format(primaryDebtor.CaseStats.ConvenienceProjectedRecovery)).replace('$', '') + '%';


          if (primaryDebtor.CaseStats.CurrentBid_Amount.toString().indexOf('.') > -1)
            element.CaseStats.CurrentBid_Amount = (decimalPointFormatter.format(primaryDebtor.CaseStats.CurrentBid_Amount)).replace('$', '') + '%';
          else
            element.CaseStats.CurrentBid_Amount = (noDecimalPointFormatter.format(primaryDebtor.CaseStats.CurrentBid_Amount)).replace('$', '') + '%';

          if (primaryDebtor.CaseStats.ProjectedRecovery_Amount.toString().indexOf('.') > -1)
            element.CaseStats.ProjectedRecovery_Amount = (decimalPointFormatter.format(primaryDebtor.CaseStats.ProjectedRecovery_Amount)).replace('$', '') + '%';
          else
            element.CaseStats.ProjectedRecovery_Amount = (noDecimalPointFormatter.format(primaryDebtor.CaseStats.ProjectedRecovery_Amount)).replace('$', '') + '%';

          if (primaryDebtor.CaseStats.UnsecuredCurrentBid.toString().indexOf('.') > -1)
            element.CaseStats.UnsecuredCurrentBid = (decimalPointFormatter.format(primaryDebtor.CaseStats.UnsecuredCurrentBid)).replace('$', '') + '%';
          else
            element.CaseStats.UnsecuredCurrentBid = (noDecimalPointFormatter.format(primaryDebtor.CaseStats.UnsecuredCurrentBid)).replace('$', '') + '%';

          if (primaryDebtor.CaseStats.UnsecuredProjectedRecovery.toString().indexOf('.') > -1)
            element.CaseStats.UnsecuredProjectedRecovery = (decimalPointFormatter.format(primaryDebtor.CaseStats.UnsecuredProjectedRecovery)).replace('$', '') + '%';
          else
            element.CaseStats.UnsecuredProjectedRecovery = (noDecimalPointFormatter.format(primaryDebtor.CaseStats.UnsecuredProjectedRecovery)).replace('$', '') + '%';
        }
      });
    }
  }

  saveCaseStats() {
    if (this.EstimatedDistributionDate && !Date.parse(this.EstimatedDistributionDate)) {
      swal("Error", "Please enter proper Estimate Distribution Date", "error")
      return;
    }

    //#region Validate Case Statistics
    var totalConvenienceCurrentBid = 0;
    var totalConvenienceProjectedRecovery = 0;
    var totalCurrentBid_Amount = 0;
    var totalProjectedRecovery_Amount = 0;
    var totalUnsecuredCurrentBid = 0;
    var totalUnsecuredProjectedRecovery = 0;

    // let subdebtorCopy = this.subDebtorList.map(x => Object.assign({}, x));
    let subdebtorCopy = JSON.parse(JSON.stringify(this.subDebtorList))

    subdebtorCopy.forEach(element => {
      if (element.IsActive) {
        element.CaseStats.ConvenienceClassThreshold = element.CaseStats.ConvenienceClassThreshold.replace('%', '').replace('$', '');
        element.CaseStats.ConvenienceCurrentBid = element.CaseStats.ConvenienceCurrentBid.replace('%', '').replace('$', '');
        element.CaseStats.ConvenienceProjectedRecovery = element.CaseStats.ConvenienceProjectedRecovery.replace('%', '').replace('$', '');
        element.CaseStats.CurrentBid_Amount = element.CaseStats.CurrentBid_Amount.replace('%', '').replace('$', '');
        element.CaseStats.ProjectedRecovery_Amount = element.CaseStats.ProjectedRecovery_Amount.replace('%', '').replace('$', '');
        element.CaseStats.UnsecuredCurrentBid = element.CaseStats.UnsecuredCurrentBid.replace('%', '').replace('$', '');
        element.CaseStats.UnsecuredProjectedRecovery = element.CaseStats.UnsecuredProjectedRecovery.replace('%', '').replace('$', '');

        totalConvenienceCurrentBid += parseFloat(element.CaseStats.ConvenienceCurrentBid);
        totalConvenienceProjectedRecovery += parseFloat(element.CaseStats.ConvenienceProjectedRecovery);
        totalCurrentBid_Amount += parseFloat(element.CaseStats.CurrentBid_Amount);
        totalProjectedRecovery_Amount += parseFloat(element.CaseStats.ProjectedRecovery_Amount);
        totalUnsecuredCurrentBid += parseFloat(element.CaseStats.UnsecuredCurrentBid);
        totalUnsecuredProjectedRecovery += parseFloat(element.CaseStats.UnsecuredProjectedRecovery);
      }
    });

    // var errorMessage = "";
    // if (totalConvenienceCurrentBid > 100)
    //   errorMessage += (errorMessage ? "," : "") + "Convenience Current Bid";

    // if (totalConvenienceProjectedRecovery > 100)
    //   errorMessage += (errorMessage ? "," : "") + "Convenience Projected Recovery";

    // if (totalCurrentBid_Amount > 100)
    //   errorMessage += (errorMessage ? "," : "") + "50b39 Current Bid";

    // if (totalProjectedRecovery_Amount > 100)
    //   errorMessage += (errorMessage ? "," : "") + "50b39 Projected Recovery";

    // if (totalUnsecuredCurrentBid > 100)
    //   errorMessage += (errorMessage ? "," : "") + "Unsecured Current Bid";

    // if (totalUnsecuredProjectedRecovery > 100)
    //   errorMessage += (errorMessage ? "," : "") + "Unsecured Projected Recovery";

    // if (errorMessage) {
    //     swal("Error", "Please enter proper case Statistics: " + errorMessage, "error")
    //   return;
    // }

    //#endregion

    var request = {
      CaseListingId: this.debtorForm.get('CaseListingId').value,
      SubDebtorList: subdebtorCopy,
      EstimatedDistributionDate: this.convertDate(this.EstimatedDistributionDate),
      ApplyAllForCaseStats: this.applyAllForCaseStats
    }

    this.spinner.show();
    this.debtorService.saveCaseStats(request)
      .subscribe(response => {
        this.spinner.hide();
        if (response.Status == 'Success') {
          swal('Success', 'Case Statistics updated successfully', 'success');
          this.getCaseStats();
          this.getCaseStatsAuditReport(this.debtorId)
          this.isCaseStatsChanged = false;
        }
        else {
          swal('Error', response.Message, 'error');
        }

      },
        error => {
          this.spinner.hide();
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  getCaseStats() {
    if (this.listingKey) {
      this.spinner.show();
      this.debtorService.getCaseStats(this.listingKey)
        .subscribe(response => {
          this.spinner.hide();
          if (response.Status == 'Success') {
            this.subDebtorList = response.data.SubDebtorList;

            this.subDebtorList.forEach(element => {
              element.CaseStats.ConvenienceClassThreshold = element.CaseStats.ConvenienceClassThreshold.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.ConvenienceCurrentBid = element.CaseStats.ConvenienceCurrentBid.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.ConvenienceProjectedRecovery = element.CaseStats.ConvenienceProjectedRecovery.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.CurrentBid_Amount = element.CaseStats.CurrentBid_Amount.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.ProjectedRecovery_Amount = element.CaseStats.ProjectedRecovery_Amount.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.UnsecuredCurrentBid = element.CaseStats.UnsecuredCurrentBid.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");
              element.CaseStats.UnsecuredProjectedRecovery = element.CaseStats.UnsecuredProjectedRecovery.toString().replace(/,/g, "").replace("$", "").replace(/%/g, "");

              if (element.CaseStats.ConvenienceClassThreshold.toString().indexOf('.') > -1)
                element.CaseStats.ConvenienceClassThreshold = decimalPointFormatter.format(element.CaseStats.ConvenienceClassThreshold);
              else
                element.CaseStats.ConvenienceClassThreshold = noDecimalPointFormatter.format(element.CaseStats.ConvenienceClassThreshold);

              if (element.CaseStats.ConvenienceCurrentBid.toString().indexOf('.') > -1)
                element.CaseStats.ConvenienceCurrentBid = (decimalPointFormatter.format(element.CaseStats.ConvenienceCurrentBid)).replace('$', '') + '%';
              else
                element.CaseStats.ConvenienceCurrentBid = (noDecimalPointFormatter.format(element.CaseStats.ConvenienceCurrentBid)).replace('$', '') + '%';

              if (element.CaseStats.ConvenienceProjectedRecovery.toString().indexOf('.') > -1)
                element.CaseStats.ConvenienceProjectedRecovery = (decimalPointFormatter.format(element.CaseStats.ConvenienceProjectedRecovery)).replace('$', '') + '%';
              else
                element.CaseStats.ConvenienceProjectedRecovery = (noDecimalPointFormatter.format(element.CaseStats.ConvenienceProjectedRecovery)).replace('$', '') + '%';


              if (element.CaseStats.CurrentBid_Amount.toString().indexOf('.') > -1)
                element.CaseStats.CurrentBid_Amount = (decimalPointFormatter.format(element.CaseStats.CurrentBid_Amount)).replace('$', '') + '%';
              else
                element.CaseStats.CurrentBid_Amount = (noDecimalPointFormatter.format(element.CaseStats.CurrentBid_Amount)).replace('$', '') + '%';

              if (element.CaseStats.ProjectedRecovery_Amount.toString().indexOf('.') > -1)
                element.CaseStats.ProjectedRecovery_Amount = (decimalPointFormatter.format(element.CaseStats.ProjectedRecovery_Amount)).replace('$', '') + '%';
              else
                element.CaseStats.ProjectedRecovery_Amount = (noDecimalPointFormatter.format(element.CaseStats.ProjectedRecovery_Amount)).replace('$', '') + '%';

              if (element.CaseStats.UnsecuredCurrentBid.toString().indexOf('.') > -1)
                element.CaseStats.UnsecuredCurrentBid = (decimalPointFormatter.format(element.CaseStats.UnsecuredCurrentBid)).replace('$', '') + '%';
              else
                element.CaseStats.UnsecuredCurrentBid = (noDecimalPointFormatter.format(element.CaseStats.UnsecuredCurrentBid)).replace('$', '') + '%';

              if (element.CaseStats.UnsecuredProjectedRecovery.toString().indexOf('.') > -1)
                element.CaseStats.UnsecuredProjectedRecovery = (decimalPointFormatter.format(element.CaseStats.UnsecuredProjectedRecovery)).replace('$', '') + '%';
              else
                element.CaseStats.UnsecuredProjectedRecovery = (noDecimalPointFormatter.format(element.CaseStats.UnsecuredProjectedRecovery)).replace('$', '') + '%';

              var company = _.find(this.companyList, function (item) {
                return item.CompanyId == element.CompanyId;
              });

              if (company) {
                element.Name = company.Name;
                element.SelectedSubDebtor = company;
                element.CompanyId = company.CompanyId;
              }
              else {
                element.SelectedSubDebtor = null;
                element.CompanyId = "";
              }
              element.FilteredSubDebtorList = this.companyList;
            });
            this.isCaseStatsChanged = false;
          }
        },
          error => {
            this.spinner.hide();
            this.storageService.clear();
            this.router.navigate(['/login']);
          }
        );
    }
  }

  //#endregion

  //#region Claim Pool

  saveClaimPool() {
    var request = {
      CaseListingId: this.debtorForm.get('CaseListingId').value,
      AmountPoolEstAmount: $('#debtorAmountPoolEstimate').val(),
      UnsecuredPoolEstAmount: $('#debtorUnsecuredClaimsPoolEstimate').val()
    }
    this.debtorService.savePoolEstimate(request)
      .subscribe(response => {

        if (response.Status == 'Success') {
          swal('Success', 'Estimated Claim Pool saved successfully', 'success');
          this.AmountPoolEstimate = $('#debtorAmountPoolEstimate').val();
          this.UnsecuredClaimsPoolEstimate = $('#debtorUnsecuredClaimsPoolEstimate').val();
          this.copyAmountPoolEstimate = $('#debtorAmountPoolEstimate').val();
          this.copyUnsecuredClaimsPoolEstimate = $('#debtorUnsecuredClaimsPoolEstimate').val();
          this.editClaimPool = false;
        }
        else
          swal('Error', response.Message, 'error');
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  cancelClaimPool() {
    this.copyAmountPoolEstimate = this.AmountPoolEstimate;
    this.copyUnsecuredClaimsPoolEstimate = this.UnsecuredClaimsPoolEstimate;
    this.editClaimPool = false;
  }

  //#endregion

  //#region Contacts

  addContact(contact) {
    // if (!this.ContactList)
    //   this.ContactList = [];

    // this.ContactList.push({
    //   'CaseListingContactId': "",
    //   'ContactTypeId': this.contactTypeList[0].LookupId,
    //   'ContactType': this.contactTypeList[0].LookupValue,
    //   'ContactTypeList': this.contactTypeList,
    //   'ContactList': this.systemContactList,
    //   'CaseListingId': this.debtorForm.get('CaseListingId').value,
    //   "ContactId": "",
    //   "Contact": "",
    //   "SelectedContact": null,
    //   "CompanyId": "",
    //   "Company": "",
    //   "CompanyList": this.companyList,
    //   "SelectedCompany": null,
    //   "Phone": "",
    //   "Email": "",
    //   'IsActive': true,
    //   'isEdit': true
    // });


    if (contact) {
      this.selectedContact = {};
      this.copyContact(contact, this.selectedContact);
    }
    else {
      this.selectedContact = {
        'CaseListingContactId': "",
        'ContactTypeId': "", //this.contactTypeList[0].LookupId,
        'ContactType': this.contactTypeList[0].LookupValue,
        'ContactTypeList': this.contactTypeList,
        'ContactList': this.systemContactList,
        'CaseListingId': this.debtorForm.get('CaseListingId').value,
        "ContactId": "",
        "Contact": "",
        "SelectedContact": null,
        "CompanyId": "",
        "Company": "",
        "CompanyList": this.companyList,
        "SelectedCompany": null,
        "Phone": "",
        "PhoneExt": "",
        "Email": "",
        'IsActive': true,
        'isEdit': true,
        "IsDefault": false,
        "Title": "",
        "FirstName": "",
        "LastName": "",
        "Position": "",
        "Address": "",
        "Address2": "",
        "City": "",
        "State": "",
        "ZipCode": ""
      };
    }
    //this.selectedContact.ContactTypeList = this.filterContactType(this.selectedContact.ContactType);
    this.selectContactType(this.selectedContact.ContactTypeId);
    $('#modal_add_contact').modal('show');
  }

  selectContactType(data) {
    if (data) {
      var contactType = _.find(this.contactTypeList, function (type) {
        return type.LookupId == data.option ? data.option.value : data
        });
        
      if (contactType) {
        this.selectedContact.ContactType = contactType.LookupValue;
        this.selectedContact.ContactTypeId = data.option ? data.option.value : data;
      }
    }
  }

  filterContactType(val) {
    if (val) {
      if (this.selectedContact.ContactTypeId)
        this.selectedContact.ContactTypeId = null;

      const filterValue = val.toLowerCase();
      return this.contactTypeList.filter(contactType => contactType.LookupValue.toLowerCase().startsWith(filterValue));
    }
    else {
      if (this.selectedContact.ContactTypeId)
        this.selectedContact.ContactTypeId = null;
    }
    return this.contactTypeList;
  }

  filterContact(val) {
    if (val) {
      this.clearFilterContact();
      const filterValue = val.toLowerCase();
      return this.systemContactList.filter(contact => contact.Contact.toLowerCase().startsWith(filterValue));
    }
    else {
      this.clearFilterContact();
    }
    return this.systemContactList;
  }

  clearFilterContact() {
    if (this.selectedContact.SelectedContact) {
      this.selectedContact.SelectedContact = null;
      this.selectedContact.ContactId = "";
      this.selectedContact.CompanyId = "";
      this.selectedContact.Company = "";
      this.selectedContact.Phone = "";
      this.selectedContact.PhoneExt = "";
      this.selectedContact.Email = "";
      this.selectedContact.Title = "";
      this.selectedContact.FirstName = "";
      this.selectedContact.LastName = "";
      this.selectedContact.Position = "";
      this.selectedContact.Address = "";
      this.selectedContact.Address2 = "";
      this.selectedContact.City = "";
      this.selectedContact.State = "";
      this.selectedContact.ZipCode = "";
      this.selectedContact.SelectedCompany = "";
      this.selectedContact.CompanyId = "";
      this.selectedContact.Company = "";
    }
  }




  selectContact(data) {
    var contact = _.find(this.systemContactList, function (item) {
      return item.ContactId == data.option.value;
    });

    if (contact) {
      // this.selectedContact.Contact = contact.Name;
      // this.selectedContact.ContactId = contact.ContactId;
      // this.selectedContact.CompanyId = contact.CompanyId;
      // this.selectedContact.Company = contact.Company;
      // this.selectedContact.Phone = contact.Phone;
      // this.selectedContact.Email = contact.Email;
      // this.selectedContact.FirstName = contact.FirstName;
      // this.selectedContact.LastName = contact.LastName;
      contact.ContactTypeId = this.selectedContact.ContactTypeId;
      contact.ContactType = this.selectedContact.ContactType;
      contact.CaseListingContactId = this.selectedContact.CaseListingContactId;
      contact.IsDefault = this.selectedContact.IsDefault;
      this.copyContact(contact, this.selectedContact);
      this.selectedContact.SelectedContact = data.option.value;
    }
  }

  removeContact(contact, index) {
    if (contact.CaseListingContactId) {
      var self = this;
      swal({
        title: 'Warning',
        text: "Are you sure you want to delete contact?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(function (isConfirmed) {
        if (isConfirmed.value) {
          //self.ContactList[index].IsActive = false;
          contact.IsActive = false;
          var request = {
            CaseListingId: self.debtorForm.get('CaseListingId').value,
            ContactList: [contact]
          };

          self.spinner.show();
          self.debtorService.saveContacts(request)
            .subscribe(response => {
              self.spinner.hide();
              $('#modal_add_contact').modal('hide');
              if (response.Token)
                self.storageService.setData('EncryptedToken', response.Token);
              if (response.Status == 'Success') {
                swal('Success', "Contacts saved successfully", 'success');
                self.selectedContact = {};
                self.getDebtorContacts();
                self.getContactList();
                self.getCompanyList();
              }
              else {
                swal('Error', response.Message, 'error');
              }
            },
              error => {
                self.spinner.hide();
                self.storageService.clear();
                self.router.navigate(['/login']);
              });
        }
      });
    }
    else {
      this.ContactList.splice(index, 1);
    }
  }

  saveContact(item) {
    if (item.IsActive) {
      if (item.Contact)
        item.Contact = item.Contact.trim();

      if (!item.ContactTypeId) {
        swal('Error', 'Please select Contact Type.', 'error');
        return;
      }

      if (!item.ContactId && (!item.FirstName || !item.LastName)) {
        swal('Error', 'Please enter/select Contact Name.', 'error');
        return;
      }

      if (!item.CompanyId && !item.Company) {
        swal('Error', 'Please enter/select Company Name.', 'error');
        return;
      }
      if (item.Email) {
        let EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!EMAIL_REGEXP.test(item.Email)) {
          swal('Error', 'Please enter proper email address.', 'error');
          return;
        }
      }
    }

    item.CaseListingId = Number(this.debtorForm.get('CaseListingId').value);
    item.ContactType = _.find(this.contactTypeList, function (data) {
      return data.LookupId == item.ContactTypeId;
    }).LookupValue;

    var request = {
      CaseListingId: this.debtorForm.get('CaseListingId').value,
      ContactList: [item]
    };

    this.spinner.show();
    this.debtorService.saveContacts(request)
      .subscribe(response => {
        this.spinner.hide();
        $('#modal_add_contact').modal('hide');

        if (response.Status == 'Success') {
          swal('Success', response.Message, 'success');
          this.selectedContact = null;
          this.getDebtorContacts();
          this.getContactList();
          this.getCompanyList();
        }
        else {
          swal('Error', response.Message, 'error');
        }
      },
        error => {
          this.spinner.hide();
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  _filterPrimaryDebtorCompany(value) {
    if (this.selectedPrimaryCompany) {
      this.selectedPrimaryCompany = null;
    }

    if (!value)
      value = "";

    const filterValue = value.toLowerCase();
    return this.companyList.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
  }

  filterCompany(val) {
    if (val) {
      if (this.selectedContact.SelectedCompany) {
        this.selectedContact.SelectedCompany = null;
      }
      this.selectedContact.CompanyId = "";
      const filterValue = val.toLowerCase();
      return this.companyList.filter(company => company.Name.toLowerCase().startsWith(filterValue));
    }
    return this.companyList;
  }

  selectCompany(data) {
    var company = _.find(this.companyList, function (item) {
      return item.CompanyId == data.option.value;
    });

    if (company) {
      this.selectedContact.CompanyId = company.CompanyId;
      this.selectedContact.Company = company.Name;
      this.selectedContact.SelectedCompany = data.option.value;
    }
  }

  editContact(contact) {
    this.selectedContact = {};

    this.copyContact(contact, this.selectedContact);
    //this.selectedContact.ContactTypeList = this.filterContactType(this.selectedContact.ContactType);
    //this.selectContactType(this.selectedContact.ContactTypeId);
    var self = this;

    var contactType = _.find(this.contactTypeList, function (type) {
        return type.LookupId == contact.ContactTypeId
    });

    if (contactType) {
        this.selectedContact.ContactType = contactType.LookupValue;
        this.selectedContact.ContactTypeId = contactType.LookupId;
    }

    this.selectedContact.Contact = _.find(this.systemContactList, function (item) {
      return item.ContactId == self.selectedContact.ContactId;
    }).Contact;

    $('#modal_add_contact').modal('show');
  }

  copyContact(sourceContact, destinationContact) {
    var tempContact = JSON.parse(JSON.stringify(sourceContact));
    destinationContact.CaseListingContactId = tempContact.CaseListingContactId;
    destinationContact.ContactTypeId = tempContact.ContactTypeId;
    destinationContact.ContactType = tempContact.ContactType;
    destinationContact.ContactTypeList = this.contactTypeList;
    destinationContact.ContactList = tempContact.ContactList;
    destinationContact.CaseListingId = tempContact.CaseListingId;
    destinationContact.ContactId = tempContact.ContactId;
    destinationContact.Contact = tempContact.Contact;
    destinationContact.SelectedContact = tempContact.SelectedContact;
    destinationContact.CompanyId = tempContact.CompanyId;
    destinationContact.Company = tempContact.Company;
    destinationContact.CompanyList = tempContact.CompanyList;
    destinationContact.SelectedCompany = tempContact.SelectedCompany;
    destinationContact.Phone = tempContact.Phone;
    destinationContact.PhoneExt = tempContact.PhoneExt;
    destinationContact.Email = tempContact.Email;
    destinationContact.IsActive = tempContact.IsActive;
    destinationContact.IsDefault = tempContact.IsDefault ? tempContact.IsDefault : false;
    destinationContact.Title = tempContact.Title ? tempContact.Title : "";
    destinationContact.FirstName = tempContact.FirstName;
    destinationContact.LastName = tempContact.LastName;
    destinationContact.Position = tempContact.Position;
    destinationContact.Address = tempContact.Address;
    destinationContact.Address2 = tempContact.Address2;
    destinationContact.City = tempContact.City;
    destinationContact.State = tempContact.State ? tempContact.State : "";
    destinationContact.ZipCode = tempContact.ZipCode;
    destinationContact.Country = tempContact.Country;
  }

  //#endregion

  //#region CaseUpdate
  caseUpdateFileChange(event) {
    if (event.target.files[0]) {
      this.caseUpdateForm.controls['File'].setValue(event.target.files[0]);
      this.uploadCaseUpdateFile = event.target.files[0].name;
    }
    else {
      $("#uploadValuationFile").val(null);
      this.caseUpdateForm.controls['File'].setValue(null);
      this.uploadCaseUpdateFile = "Upload File";
    }

  }
  getCaseUpdateDataList() {
    $("#uploadCaseUpdateFile").val(null);
    this.uploadCaseUpdateFile = "Upload File";
    this.caseUpdateForm.setValue({
      Date: "",
      AddLink: "",
      File: "",
      caseUpdateNote: ""
    });

    this.debtorService.getCaseUpdateList(false, this.debtorForm.get('CaseListingId').value)
      .subscribe(response => {

        if (response.Status == 'Success') {
          // Assign the data to the data source for the table to render
          this.caseUpdateDataSource = new MatTableDataSource(response.data);
          this.caseUpdateDataSource.paginator = this.caseUpdatePaginator;
          this.caseUpdateDataSource.sort = this.caseUpdateSort;
          this.resizePage();
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  saveCaseUpdate() {
    Object.keys(this.caseUpdateForm.controls).forEach(key => {
      if ($('#caseUpdate' + key))
        $('#caseUpdate' + key).removeClass('validationError');
    });
    this.isCaseUpdateSubmitted = true;

    if (this.caseUpdateForm.valid) {
      var request = {
        "UpdateDate": this.convertDate(this.caseUpdateForm.get('Date').value),
        "ExternamLink": this.caseUpdateForm.get('AddLink').value,
        "Note": this.caseUpdateForm.get('caseUpdateNote').value,
        "CaseListingId": this.debtorForm.get('CaseListingId').value
      };

      this.spinner.show();
      this.debtorService.saveCaseUpdate(request, this.caseUpdateForm.get('File').value)
        .subscribe(response => {
          this.spinner.hide();

          this.isCaseUpdateSubmitted = false;
          if (response.Status == 'Success') {
            swal('Success', "Case Updates Saved Successfully", 'success');
            this.getCaseUpdateDataList();
          }
          else
            swal('Error', response.Message, 'error');
        },
          error => {
            this.spinner.hide();
            this.storageService.clear();
            this.router.navigate(['/login']);
          });
    }
    else {
      Object.keys(this.caseUpdateForm.controls).forEach(key => {
        if (this.caseUpdateForm.controls[key].invalid) {
          if ($('#caseUpdate' + key))
            $('#caseUpdate' + key).addClass('validationError');
        }
      })
    }
  }

  uploadCaseUpdate(caseUpdateId, event) {
    if (event.target.files[0]) {
      //event.target.files[0]

      var self = this;
      swal({
        title: 'Warning',
        text: "Are you sure you want to upload " + event.target.files[0].name + " ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(function (isConfirmed) {
        if (isConfirmed.value) {
          self.spinner.show();
          self.debtorService.uploadCaseUpdate(caseUpdateId, event.target.files[0])
            .subscribe(response => {
              self.spinner.hide();
              if (response.Token)
                self.storageService.setData('EncryptedToken', response.Token);

              if (response.Status == 'Success') {
                swal('Success', response.Message, 'success');
                self.getCaseUpdateDataList();
                $("#caseUpdateFileUpload").val(null);
              }
              else
                swal('Error', response.Message, 'error');
            },
              error => {
                self.spinner.hide();
                self.storageService.clear();
                self.router.navigate(['/login']);
              });
        }
        else
          $("#caseUpdateFileUpload").val(null);
      });
    }
  }

  //#endregion

  //#region Creditors

  onChangeCreditorsList(event) {
    if (event.target.files[0]) {
      var checkItsExcelFile = this.validateFile(event.target.files[0].name);
      if (checkItsExcelFile) {
        this.creditorListFileId = event.target.files[0];
        $('#creditorListFileId').removeClass('validationError');
        this.importCreditorListFile = event.target.files[0].name;
      }
      else {
        swal('Error', 'Import only Excel file', 'error')
      }

    }
    else {
      this.creditorListFile = null;
      this.creditorListFileId = null;
      $('#creditorListFileId').addClass('validationError');
      $("#importCreditorListFile").val(null);
      this.importCreditorListFile = "Upload File";
    }
  }

  importCreditorList() {
    $('#creditorListFileId').removeClass('validationError');
    if (!this.creditorListFileId)
      return $('#creditorListFileId').addClass('validationError');

    var debtorId = this.debtorForm.get('DebtorId').value;
    this.spinner.show();
    this.debtorService.importCreditorList(this.creditorListFileId, debtorId).
      subscribe(res => {
        this.spinner.hide();
        if (res.Status == 'Success') {
          swal('Success', res.Message, 'success');

          var responseResult = res.Data;

          swal({
            title: 'Results',
            text: '',
            width: 300,
            height: 400,
            type: 'info',
            html: '<div style="text-align:left">'
              + 'File Name: ' + '<span style="color:#22262e; font-weight:bold;">' + responseResult.OriginalFileName + '</span>'
              + '</br>TotalCount: ' + '<span style="color:#22262e; font-weight:bold;">' + responseResult.TotalCount + '</span>, '
              + 'Success: ' + '<span style="color:#036303; font-weight:bold;">' + responseResult.SuccessCount + '</span>, '
              + 'Fails: ' + '<span style="color:#9c0000; font-weight:bold;">' + responseResult.FailureCount + '</span>'
              + '</div>',
            confirmButtonText: 'Ok'
          })

          $('#modal_ImportCreditorListFile').modal('hide');
          this.getImportedFiles();
          this.creditorListFileId = null;
          $("#importCreditorListFile").val(null);
          this.importCreditorListFile = "Upload File";
          this.getCreditorList();
        }
        else {
          swal('Error', res.Message, 'error');
        }
      },
        error => {
          this.spinner.hide();
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  cancelImportCreditorListFile() {
    $('#creditorListFileId').removeClass('validationError');
    this.creditorListFile = null;
    this.creditorListFileId = null;
    $("#importCreditorListFile").val(null);
    this.importCreditorListFile = "Upload File";
  }
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'xlsx' || ext.toLowerCase() == "xls") {
      return true;
    }
    else {
      return false;
    }
  }

  creditorFilter(filterValue: string) {
    this.creditorDataSource.filter = filterValue.trim().toLowerCase();
  }

  getImportedFiles() {
    this.debtorService.getImportedFiles(false)
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.importedFile = response.data;
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }
  //#endregion

  //#region Creditors

  clearFilter() {
    this.filter = {
      "MinAmount": '',
      "DebtorId": '',
      "ClaimType": "All",
      "ClaimStatus": '',
      "AddedBy": "",
      "FileId": ""
    }
    this.searchModel = "";
    this.applyFilter();
  }

  applyFilter() {
    if (this.filter.AddedBy != "I") {
      this.filter.FileId = "";
    }
    var tempList = this.creditorList.map(x => Object.assign({}, x));
    var self = this;

    if (this.filter.ClaimStatus) {
      tempList = _.filter(tempList, function (data) {
        return data.ClaimStatus == self.filter.ClaimStatus
      });
    }

    if (this.filter.ClaimType) {
      if (this.filter.MinAmount) {
        if (this.filter.ClaimType == "All")
          tempList = _.filter(tempList, function (data) {
            // return data.Amount >= self.filter.MinAmount && data.ClaimAmount >= self.filter.MinAmount && data.UnsecuredAmount >= self.filter.MinAmount
            return data.Amount >= self.filter.MinAmount || data.ClaimAmount >= self.filter.MinAmount || data.UnsecuredAmount >= self.filter.MinAmount
          });

        if (this.filter.ClaimType == "503b9")
          tempList = _.filter(tempList, function (data) {
            return data.Amount >= self.filter.MinAmount
          });

        if (this.filter.ClaimType == "ClaimAmount")
          tempList = _.filter(tempList, function (data) {
            return data.ClaimAmount >= self.filter.MinAmount
          });

        if (this.filter.ClaimType == "Unsecured")
          tempList = _.filter(tempList, function (data) {
            return data.UnsecuredAmount >= self.filter.MinAmount
          });
      }
    }

    if (this.filter.AddedBy) {
      if (this.filter.AddedBy == "I") {
        tempList = _.filter(tempList, function (data) {
          if (data.CreditorImportId)
            return data;
        });
      }
      else {
        tempList = _.filter(tempList, function (data) {
          if (!data.CreditorImportId)
            return data;
        });
      }
    }

    if (this.filter.FileId) {
      tempList = _.filter(tempList, function (data) {
        return data.CreditorImportId == self.filter.FileId
      });
    }

    this.creditorDataSource = new MatTableDataSource(tempList);
    this.creditorDataSource.sort = this.creditorSort;
    this.creditorDataSource.paginator = this.creditorPaginator;
    this.resizePage();
  }

  addCreditor() {
    $('#modal_add_creditor').modal('show');
    this.SelectedCreditor = "";
    this.selectedContact = "";
    this.SelectedKeyPerson = "";
    this.KeyPerson = "";
    this.creditor = "";
    this.filterKeyPersonList = null;
    this.creditorKeyPerson = "";
    this.keyPersonEmail = "";
    this.keyPersonPhone = "";
    this.claim = {
      AddClaim: false,
      ClaimNo: "",
      Amount: 0,
      ClaimType: ""
    }

    this.systemCreditorList = this.filterCreditor("");
  }

  getAllCreditorList() {
    this.creditorService.search(false).subscribe(
      response => {
        if (response.Status == 'Success') {
          this.allCreditorList = response.data;
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });

  }

  selectCreditor(data) {
    if (data.option.value) {
      var result = _.find(this.allCreditorList, function (creditor) {
        return creditor.CreditorId == data.option.value;
      });

      if (result) {
        this.creditor = result.Company;
        this.SelectedCreditor = data.option.value;
        this.getCreditorContact(this.SelectedCreditor);
      }

    }
    else {
      this.SelectedCreditor = null;
      this.creditor = "";
      this.KeyPerson = "";
      this.SelectedKeyPerson = "";
      this.creditorKeyPerson = "";
      this.keyPersonEmail = "";
      this.keyPersonPhone = "";
    }
  }

  getCreditorContact(creditorId) {
    this.creditorService.getCreditorContacts(creditorId).subscribe(
      response => {
        if (response.Status == 'Success') {
          this.filterKeyPersonList = response.data;
          this.filterKeyPersonList = _.uniq(response.data, 'ContactId');
        }
      },
      error => {
        this.storageService.clear();
        this.router.navigate(['/login']);
      });
  }

  filterCreditor(val) {
    var self = this;
    var list = this.allCreditorList;
    list = _.filter(list, function (c) {
      var creditorValue = _.find(self.creditorList, function (o) {
        return o.CreditorId == c.CreditorId
      })
      if (!creditorValue)
        return c;
    });
    if (val) {
      if (this.SelectedCreditor) {
        this.SelectedCreditor = "";
        this.SelectedKeyPerson = "";
        this.KeyPerson = "";
        this.filterKeyPersonList = null;
        this.creditorKeyPerson = "";
        this.keyPersonEmail = "";
        this.keyPersonPhone = "";
      }
      const filterValue = val.toLowerCase();
      list = list.filter(creditor => creditor.Company.toLowerCase().startsWith(filterValue))
      return list;
    }
    else {
      this.SelectedCreditor = "";
      this.SelectedKeyPerson = "";
      this.KeyPerson = "";
      this.filterKeyPersonList = null;
      this.creditorKeyPerson = "";
      this.keyPersonEmail = "";
      this.keyPersonPhone = "";
    }
    return list;
  }

  addCreditorforDebtor() {
    if (!this.SelectedCreditor && !this.creditor) {
      swal('Error', 'Please select creditor', 'error');
      return;
    }

    // if (this.SelectedCreditor) {
    //   if (!this.SelectedKeyPerson) {
    //     swal('Error', 'Please select key person', 'error');
    //     return;
    //   }
    // }
    // else {
    //   if (!this.creditorKeyPerson) {
    //     swal('Error', 'Please select key person', 'error');
    //     return;
    //   }
    // }

    // if (!this.SelectedKeyPerson && !this.creditorKeyPerson) {
    //     swal('Error', 'Please select key person', 'error');
    //   return;
    // }

    if (!this.SelectedCreditor) {
      if (this.keyPersonEmail) {
        let EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!EMAIL_REGEXP.test(this.keyPersonEmail)) {
          swal('Error', 'Please enter proper email address.', 'error');
          return;
        }
      }
    }

    if (this.claim.AddClaim) {
      if (!this.claim.ClaimNo) {
        swal('Error', 'Please enter claim number', 'error');
        return;
      }
      if (!this.claim.Amount || this.claim.Amount <= 0) {
        swal('Error', 'Please claim amount', 'error');
        return;
      }
      if (!this.claim.ClaimType) {
        swal('Error', 'Please claim type', 'error');
        return;
      }
    }

    var request = {
      CreditorId: this.SelectedCreditor,
      Company: this.creditor,
      KeyPerson: this.SelectedKeyPerson ? this.SelectedKeyPerson : this.creditorKeyPerson,
      DebtorId: this.debtorId,
      IsNewKeyPerson: this.creditorKeyPerson ? true : false,
      IsNewCreditor: this.SelectedCreditor ? false : true,
      Email: this.keyPersonEmail,
      PhoneNumber: this.keyPersonPhone,
      AddClaim: this.claim.AddClaim,
      ClaimsList: [this.claim]
    };

    this.spinner.show();
    this.debtorService.saveDebtorCreditor(request).subscribe(response => {
      this.spinner.hide();

      if (response.Status == 'Success') {
        $('#modal_add_creditor').modal('hide');
        swal('Success', response.Message, 'success');
        swal('Success', "Creditor added successfully", 'success');
        this.getCreditorList();
      }
      else {
        swal('Error', response.Message, 'error');
      }
    },
      error => {
        this.spinner.hide();
        this.storageService.clear();
        this.router.navigate(['/login']);
      });

  }
  redirectToCreditor() {
    $('#modal_add_creditor').modal('hide');
    this.router.navigate(['/creditor'], { queryParams: { DebtorId: this.debtorId } });
  }

  ExportTOExcel() {
    var data = this.creditorDataSource.filteredData;

    var exportData = [];
    data.forEach(creditor => {
      exportData.push({
        "Creditor": creditor.Company,
        "Key Person": creditor.KeyPerson,
        "Email": creditor.Email,
        "Claim": '$' + creditor.ClaimAmount,
        "503b9": '$' + creditor.Amount,
        "Unsecured": '$' + creditor.UnsecuredAmount,
        "Debtors": creditor.DebtorCount,
        "DNC": creditor.DoNotCall ? "TRUE" : "FALSE",
        "Restricted": creditor.IsRestricted ? "Restricted" : ""
      })
    });

    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.writeFile(wb, 'Debtor_Creditors.xlsx');

    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(exportData[0]);
    let csv = exportData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var a = document.createElement('a');
    var blob = new Blob([csvArray], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = "Debtor_Creditors.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  //#endregion

  //#region Creditor Matrix Upload File

  openMatrixModal() {
    $('#modal_matrix').modal('show');
    $('#matrixFileUploadId').removeClass('validationError');
    this.matrixFile = null;
    this.matrixFileLabel = "Upload File";
  }
  onChangeMatrixFile(event) {
    if (event.target.files[0]) {
      this.matrixFile = event.target.files[0];
      $('#matrixFileUploadId').removeClass('validationError');
      this.matrixFileLabel = event.target.files[0].name;
    }
    else {
      $('#matrixFileUploadId').addClass('validationError');
      this.matrixFile = null;
      this.matrixFileLabel = "Upload File";
    }
  }

  UploadMatrixFile() {
    $('#matrixFileUploadId').removeClass('validationError');

    if (!this.matrixFile) {
      return $('#matrixFileUploadId').addClass('validationError');
    }
    var request = {
      FileType: 'Matrix',
      ListingKey: this.listingKey,
      subDebtorId: ""
    };

    this.debtorService.saveMatrixAndScheduleFile(request, this.matrixFile)
      .subscribe(response => {
        this.isCaseUpdateSubmitted = false;
        if (response.Status == 'Success') {
          swal('Success', response.Message, 'success');
          this.getListOfMatrixAndScheduleFiles();
          $('#modal_matrix').modal('hide');
          this.getListOfMatrixAndScheduleFiles();
        }
        else
          swal('Error', response.Message, 'error');
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }
  //#endregion

  //#region Calendar
  CalendarList: any = [];
  cloneCalendarList: any = [];
  selectedCalendar: any = {};
  isEditCalendar = false;

  getCaseListingDates() {
    this.cancelCalendar();
    this.calendarSerivce.getCaseListingDates(this.debtorForm.controls["CaseListingId"].value)
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.CalendarList = response.Data;
          this.resizePage();
          this.CalendarList.forEach(element => {
            element.SelectedDateType = element.DateType;

            if(!element.Notes)
                element.Notes = "";
              element.isNotesExpanded = false;

            if (element.CalendarTime != null) {
              element.Time = new Date('01-01-1970 ' + element.CalendarTime);
              element.TimeHH = formatDate(element.Time, 'hh', 'en-US');
              element.TimeMM = formatDate(element.Time, 'mm', 'en-US');
              element.TimeAP = formatDate(element.Time, 'a', 'en-US');
            }
            else {
              element.TimeHH = '';
              element.TimeMM = '';
              element.TimeAP = '';
            }

            var type = _.find(this.optionsDates, function (o) {
              return o.LookupId == element.SelectedDateType;
            });
            if (type)
              element.DateType = type.LookupValue;
          });
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  cancelCalendar(){
    this.selectedCalendar = {};
    this.isEditCalendar = false;
    $('#modal_calendar').modal('hide');
  }

  addCalendarEvent() {
    this.cancelCalendar();
    this.selectedCalendar.CaseListingCalendarDatesId= "";
    this.selectedCalendar.CaseListingId= this.debtorForm.controls["CaseListingId"].value;
    this.selectedCalendar.DateType= this.optionsDates[0].LookupValue;
    this.selectedCalendar.CalendarDate= new Date();
    this.selectedCalendar.TimeHH= "";
    this.selectedCalendar.TimeMM= "";
    this.selectedCalendar.TimeAP= "";
    this.selectedCalendar.Notes= "";
    this.selectedCalendar.NotificationList= [];
    this.selectedCalendar.RemainingDays= "";
    this.selectedCalendar.isEdit= true;
    this.selectedCalendar.DateTypeList= this.optionsDates;
    this.selectedCalendar.SelectedDateType= this.optionsDates[0].LookupId;
    this.selectedCalendar.IsActive= true;
    this.selectedCalendar.isValidTime= true;
    $('#modal_calendar').modal('show');
  }

  filterDateType(val) {
    if (val) {
      if (this.selectedCalendar.SelectedDateType) {
        this.selectedCalendar.SelectedDateType = null;
      }
      const filterValue = val.toLowerCase();
      return this.optionsDates.filter(type => type.LookupValue.toLowerCase().startsWith(filterValue));
    }
    else {
      if (this.selectedCalendar.SelectedDateType) {
        this.selectedCalendar.SelectedDateType = null;
      }
    }
    return this.optionsDates;
  }

  selectDateType(data) {
    var type = _.find(this.optionsDates, function (item) {
      return item.LookupId == data.option.value;
    });

    if (type) {
      this.selectedCalendar.DateType = type.LookupValue;
      this.selectedCalendar.SelectedDateType = data.option.value;
    }
  }

  editCalendar(calendar) {
    this.cancelCalendar();
    this.isEditCalendar = true
    this.selectedCalendar = JSON.parse(JSON.stringify(calendar));
    $('#modal_calendar').modal('show');
  }

  saveCalendar() {
    var request = this.getCalendarRequestObject();

    if (!request.CalendarDate) {
      swal('Error', 'Please enter/select Date.', 'error');
      return;
    }

    if (!request.DateType) {
      swal('Error', 'Please enter/select Date Type.', 'error');
      return;
    }
    this.calendarSerivce.saveCaseListingDate(request)
      .subscribe(response => {
        if (response.Status == 'Success') {
          swal('Success', response.Message, 'success');
          this.isEditCalendar = false;
          this.cancelCalendar();
          this.getCaseListingDates();
        }
        else {
          swal('Error', response.Message, 'error');
        }
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  getCalendarRequestObject() {
    var time = null;

    var timeHH = this.selectedCalendar.TimeHH
    var timeMM = this.selectedCalendar.TimeMM
    var timeAP = this.selectedCalendar.TimeAP

    if (Number(timeHH) && (Number(timeMM) || Number(timeMM) == 0) && timeAP != '') {
      time = timeHH + ':' + timeMM + ':00 ' + timeAP;
    }
    var data = {
      CaseListingCalendarDatesId: this.selectedCalendar.CaseListingCalendarDatesId,
      CaseListingId: this.selectedCalendar.CaseListingId,
      DateType: this.selectedCalendar.SelectedDateType,
      CalendarDate: this.convertDate(this.selectedCalendar.CalendarDate),
      Notes: this.selectedCalendar.Notes,
      IsActive: true,
      CalendarTime: time
    }

    return data;
  }

  removeCalendar(calendar) {
    if (calendar.CaseListingCalendarDatesId) {
      var self = this;
      swal({
        title: 'Warning',
        text: "Are you sure you want to delete Calendar?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(function (isConfirmed) {
        if (isConfirmed.value) {
          var request = JSON.parse(JSON.stringify(calendar));
          request.IsActive = false;
          request.DateType = request.SelectedDateType;

          self.calendarSerivce.saveCaseListingDate(request)
            .subscribe(response => {
              if (response.Token)
                self.storageService.setData('EncryptedToken', response.Token);

              if (response.Status == 'Success') {
                swal('Success', "Calendars deleted successfully", 'success');
                self.isEditCalendar = false;
                self.selectedCalendar = {};
                self.getCaseListingDates();
              }
              else {
                swal('Error', response.Message, 'error');
              }
            },
              error => {
                self.storageService.clear();
                self.router.navigate(['/login']);
              });
        }
      });
    }
  }

  openNotificationPopup(data) {
    this.selectedCalendar = JSON.parse(JSON.stringify(data));
    $('#modal_notification').modal('show');
  }

  closeNotificationPopup(type) {
    if (type == 'close') {
      $('#modal_notification').modal('hide');
      this.cancelCalendar();
    }
    else {
      this.calendarSerivce.saveNotification(this.selectedCalendar.NotificationList)
        .subscribe(response => {

          if (response.Status == 'Success') {
            swal('Success', response.Message, 'success');
            $('#modal_notification').modal('hide');
            this.cancelCalendar();
            this.getCaseListingDates();
          }
          else {
            swal('Error', response.Message, 'error');
          }
        },
          error => {
            this.storageService.clear();
            this.router.navigate(['/login']);
          });
    }
  }

  addNotification() {
    if (!this.selectedCalendar.NotificationList) {
      this.selectedCalendar.NotificationList = [];
    }

    this.selectedCalendar.NotificationList.push({
      Type: "Debtor",
      DaysBefore: 1,
      NotificationType: "Days",
      IsActive: true,
      CalendarDateId: this.selectedCalendar.CaseListingCalendarDatesId
    });
  }

  deleteNotification(item, index) {
    if (item.CalendarNotificationId) {
      item.IsActive = false;
    }
    else {
      this.selectedCalendar.NotificationList.splice(index, 1);
    }
  }

  //#endregion

  //#region Creditor Schedule upload

  openScheduleModal() {
    $('#modal_schedule').modal('show');
    $('#scheduleFileUploadId').removeClass('validationError');
    $('#fileTypeId').removeClass('validationError');
    $('#scheduleDebtorId').removeClass('validationError');
    this.scheduleFile = "";
    this.uploadScheduleFile = "Upload File";
    this.scheduleFileType = "";
    this.scheduleSubDebtorId = "";

  }

  onChangescheduleFile(event) {
    if (event.target.files[0]) {
      this.scheduleFile = event.target.files[0];
      $('#scheduleFileUploadId').removeClass('validationError');
      this.uploadScheduleFile = event.target.files[0].name;
    }
    else {
      $('#scheduleFileUploadId').addClass('validationError');
      this.scheduleFile = "";
      this.uploadScheduleFile = "Upload File";
    }

  }

  UploadScheduleFile() {
    $('#scheduleFileUploadId').removeClass('validationError');
    $('#fileTypeId').removeClass('validationError');
    $('#scheduleDebtorId').removeClass('validationError');

    if (!this.scheduleFile)
      $('#scheduleFileUploadId').addClass('validationError');
    if (!this.scheduleFileType)
      $('#fileTypeId').addClass('validationError');

    if (!this.scheduleSubDebtorId)
      $('#scheduleDebtorId').addClass('validationError');

    if (!this.scheduleFile || !this.scheduleFileType || !this.scheduleSubDebtorId)
      return

    var request = {
      FileType: this.scheduleFileType,
      subDebtorId: this.scheduleSubDebtorId,
      ListingKey: this.listingKey
    };
    this.debtorService.saveMatrixAndScheduleFile(request, this.scheduleFile)
      .subscribe(response => {
        this.isCaseUpdateSubmitted = false;
        if (response.Status == 'Success') {
          this.getListOfMatrixAndScheduleFiles();
          swal('Success', response.Message, 'success');
          $('#modal_schedule').modal('hide');
        }
        else
          swal('Error', response.Message, 'error');
      },
        error => {
          this.storageService.clear();
          this.router.navigate(['/login']);
        });
  }

  //#endregion

  contactsToggle(collapsed) {
      this.contactsCollapsed = collapsed;
  }

  calendarToggle(collapsed) {
      this.calendarCollapsed = collapsed;
  }

  resizePage() {
    setTimeout(() => {
      this.pageHelper.page_content_onresize();
    }, 500);
  }
}