import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContactService } from './contact.service';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { CompanyService } from './company.service';
import * as _ from 'underscore';
import { CommonService } from '../common/common.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';

declare var swal: any;
declare var $: any;

@Component({
    selector: 'contact',
    templateUrl: 'contact.template.html',
    styleUrls: ['../../../assets/css/mat-table.css']
})

export class ContactComponent implements OnInit {
    @ViewChild('contactPaginator') contactPaginator: MatPaginator;
    @ViewChild('contactSort') contactSort: MatSort;
    contactColumns: string[] = ['Name', 'Company', 'Email', 'Phone', 'PhoneExt', 'IsRestricted'];
    contactDataSource: MatTableDataSource<any>;

    showHistory: boolean = false;
    selectedCompany = null;
    disbableWebsite: boolean = false;
    isEdit: boolean = false;
    isSubmitted: boolean = false;
    isRistricted: boolean = false;
    showContact: boolean = true;
    stateList = [];
    listingKey: any = "";

    form: FormGroup;
    contactList: any = [];
    contactHistoryList: any = [];
    searchModel = "";

    creditorId: any = "";
    contactId: any = "";
    onCancelFlag: any = true;

    companyList = [];
    filteredCompanyList: Observable<string[]>;
    filter:any = {
        "CompanyId" : "",
        "Restricted" : ""
    }

    constructor(private router: Router, private contactService: ContactService
        , private companyService: CompanyService, public commonService: CommonService, private route: ActivatedRoute
        , private pageHelper: PageHelper, private spinner: NgxSpinnerService) {

    }

    ngOnInit() {
        $('#menuContacts').addClass('active');
        this.getCompanyList(false);

        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // let webUrlPattern=/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
        let webUrlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        let zipPattern = /^\d{5}(?:-\d{4})?$/;

        this.route.params.subscribe(params => {
            if (!params['ContactId'] && params['ContactId'] != "") {
                this.getContactList(false);
            }
            else {
                this.showContact = false;
                this.creditorId = this.route.snapshot.queryParams["CreditorId"];
                this.listingKey = this.route.snapshot.queryParams["ListingKey"];
                this.form = new FormGroup({
                    ContactId: new FormControl(""),
                    Title: new FormControl(""),
                    FirstName: new FormControl("", [Validators.required]),
                    LastName: new FormControl("", [Validators.required]),
                    Email: new FormControl("", [Validators.pattern(emailPattern)]),
                    Company: new FormControl("", [Validators.required]),
                    WebsiteUrl: new FormControl("", [Validators.pattern(webUrlPattern)]),
                    Position: new FormControl(""),
                    Address: new FormControl(""),
                    City: new FormControl(""),
                    State: new FormControl(""),
                    ZipCode: new FormControl("", [Validators.pattern(zipPattern)]),
                    Phone: new FormControl(""),
                    PhoneExt: new FormControl(""),
                    IsActive: new FormControl(true),
                    IsRestricted: new FormControl(false),
                    Address2:  new FormControl(""),
                    Country:  new FormControl("")
                });
                this.filteredCompanyList = this.form.get("Company").valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value)));

                this.form.valueChanges.subscribe(() => {
                    if (this.isSubmitted || this.isEdit) {
                        Object.keys(this.form.controls).forEach(key => {
                            if ($('#' + key))
                                $('#' + key).removeClass('validationError');
                        });

                        if (this.form.invalid) {
                            Object.keys(this.form.controls).forEach(key => {
                                if (!this.form.controls[key].valid) {
                                    if ($('#' + key))
                                        $('#' + key).addClass('validationError');
                                }
                            });
                        }
                    }
                });


                var contactId = params['ContactId'];
                if (contactId)
                    this.getContactDetails(contactId);
                else
                    this.setFormValues(null);

                this.commonService.getStates().subscribe(
                    response => {
                        if (response.Status == 'Success') {
                            this.stateList = response.data;
                        }
                    },
                    error => {
                        this.router.navigate(['/login']);
                    });
            }
        });
    }

    getContactList(showAll: boolean = false) {
        this.searchModel = "";
        this.spinner.show();
        this.contactService.search(showAll).subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.contactList = response.data;
                    this.applyFilter(this.searchModel);
                }
            },
            error => {
                this.spinner.hide();
                this.router.navigate(['/login']);
            });
    }

    getCompanyList(showAll: boolean = false) {
        this.companyService.getList(showAll).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.companyList = response.data;
                    this.companyList = _.sortBy(this.companyList, function(c){
                        return c.Name
                    });
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    getContactDetails(ContactId: any) {
        if (ContactId) {
            this.spinner.show();
            this.contactService.getDetails(ContactId)
                .subscribe(response => {
                    this.spinner.hide();
                    if (response.Status == 'Success') {
                        this.setFormValues(response.data);
                    }
                },
                    error => {
                        this.spinner.hide();
                        this.router.navigate(['/login']);
                    }
                );
        }
    }

    save() {
        Object.keys(this.form.controls).forEach(key => {
            if ($('#' + key))
                $('#' + key).removeClass('validationError');
        });
        this.isSubmitted = true;
        if (this.form.valid) {
            var CompanyId = null;
            if (this.selectedCompany) {
                var self = this;
                var company = _.find(this.companyList, function (data) {
                    return data.Name == self.selectedCompany;
                });
                CompanyId = company.CompanyId;
            }

            var request = {
                "ContactId": this.form.get('ContactId').value,
                "Title": this.form.get('Title').value,
                "FirstName": this.form.get('FirstName').value,
                "LastName": this.form.get('LastName').value,
                "Email": this.form.get('Email').value,
                "CompanyId": CompanyId,
                "Company": CompanyId ? this.selectedCompany : this.form.get('Company').value,
                "WebsiteUrl": this.form.get('WebsiteUrl').value,
                "Address": this.form.get('Address').value,
                "City": this.form.get('City').value,
                "State": this.form.get('State').value,
                "ZipCode": this.form.get('ZipCode').value,
                "Position": this.form.get('Position').value,
                "Phone": this.form.get('Phone').value,
                "PhoneExt": this.form.get('PhoneExt').value,
                "IsActive": this.form.get('IsActive').value,
                "IsRestricted": this.form.get('IsRestricted').value,
                "Address2": this.form.get('Address2').value,
                "Country": this.form.get('Country').value
            }
            this.spinner.show();
            this.contactService.save(request).subscribe(
                response => {
                    this.spinner.hide();
                    if (response.Status == 'Success') {
                        swal('Success', response.Message, 'success');
                        if(!this.isEdit){
                            this.router.navigate(['/contact/' + response.Data]);
                            window.location.reload();
                        }
                    }
                    else {
                        swal('Error', response.Message, 'error');
                    }
                },
                () => {
                    this.spinner.hide();
                    this.router.navigate(['/login']);
                }
            );
        }
        else {
            Object.keys(this.form.controls).forEach(key => {
                if (!this.form.controls[key].valid) {
                    if ($('#' + key))
                        $('#' + key).addClass('validationError');
                }
            });
        }
    }

    setFormValues(data: any) {
        this.isEdit = false;
        this.showHistory = false;
        this.isSubmitted = false;
        Object.keys(this.form.controls).forEach(key => {
            if ($('#' + key))
                $('#' + key).removeClass('validationError');
        });
        this.isRistricted = false;

        if (data) {
            this.showContact = false;

            this.form.setValue({
                ContactId: data.ContactId,
                Title: data.Title,
                FirstName: data.FirstName,
                LastName: data.LastName,
                Email: data.Email,
                Company: data.Company,
                WebsiteUrl: data.WebsiteUrl,
                Position: data.Position,
                Address: data.Address,
                City: data.City,
                State: data.State,
                ZipCode: data.ZipCode,
                Phone: data.Phone,
                PhoneExt: data.PhoneExt,
                IsActive: data.IsActive,
                IsRestricted: data.IsRestricted,
                Address2: data.Address2,
                Country: data.Country
            });

            if (data.CompanyId)
                this.selectedCompany = data.Company;
            else
                this.selectedCompany = null;

            this.isEdit = true;

            if (data.WebsiteUrl && this.isEdit)
                this.disbableWebsite = true;

            if (data.IsRestricted)
            this.isRistricted = true;


          this.contactHistoryList = data.ContactHistoryList;
        }
        else {
            this.form.setValue({
                ContactId: "",
                Title: "",
                FirstName: "",
                LastName: "",
                Email: "",
                Company: "",
                WebsiteUrl: "",
                Position: "",
                Address: "",
                City: "",
                State: "",
                ZipCode: "",
                Phone: "",
                PhoneExt: "",
                IsActive: true,
                IsRestricted: false,
                Address2: "",
                Country: ""
            });
            Object.keys(this.form.controls).forEach(key => {
                if ($('#' + key))
                    $('#' + key).removeClass('validationError');
            });
          this.contactHistoryList = [];
        }
    }

    private _filter(value: string): string[] {
        if (this.selectedCompany) {
            this.selectedCompany = null;
            this.form.controls['WebsiteUrl'].setValue("");
            this.form.controls['IsRestricted'].setValue(false);
            this.isRistricted = false;
        }

        const filterValue = value.toLowerCase();
        return this.companyList.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }

    selectCompany(data) {
        this.selectedCompany = data.option.value;

        var self = this;
        var company = _.find(this.companyList, function (item) {
            return item.Name == self.selectedCompany;
        });
        if (company) {
            if (company.WebsiteUrl && this.isEdit)
                this.disbableWebsite = true;

            if (company.IsRestricted)
                this.isRistricted = true;

            this.form.controls['WebsiteUrl'].setValue(company.WebsiteUrl);
            this.form.controls['IsRestricted'].setValue(company.IsRestricted);
        }
        else {
            this.form.controls['WebsiteUrl'].setValue("");
            this.form.controls['IsRestricted'].setValue(false);
        }
    }

    navigateToContact(Type, ContactKey) {
        if (Type == 'display') {
            if (this.listingKey)
                this.router.navigate(['/debtor'], { queryParams: { ListingKey: this.listingKey } });
            else if (this.creditorId)
                this.router.navigate(['/creditor'], { queryParams: { CreditorId: this.creditorId } });
            else
                this.router.navigate(['/contact']);
        }
        else
            this.router.navigate(['contact', ContactKey]);
    }

    applyFilter(filterValue: string) {
        if(!filterValue)
            filterValue = this.searchModel;

        var tempList = this.contactList.map(x => Object.assign({}, x));
        
        var self = this;
        if(this.filter.CompanyId){
            tempList = _.filter(tempList, function (data) {
                return data.CompanyId == self.filter.CompanyId
            });
        }

        if(this.filter.Restricted){
            if(this.filter.Restricted == "1")
                tempList = _.filter(tempList, function (data) {
                    if(data.IsRestricted)
                        return data;
                });
            else if(this.filter.Restricted == "0")
                tempList = _.filter(tempList, function (data) {
                    if(!data.IsRestricted)
                        return data;
                });
        }

        this.contactDataSource = new MatTableDataSource(tempList);
        this.contactDataSource.sort = this.contactSort;
        this.contactDataSource.paginator = this.contactPaginator;

        this.contactDataSource.filter = filterValue.trim().toLowerCase();

        this.resizePage();
    }
    
    clearFilter(){
        this.filter = {
            "CompanyId" : "",
            "Restricted" : ""
        }
        this.searchModel = "";
        this.applyFilter("");
    }

    resizePage(){
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }
}
