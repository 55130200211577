import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../../login/confirm.component';
import { CommonService } from '../common.service';
import { StorageService } from '../storage.service';
import { onresize, x_navigation_minimize, x_navigation } from '../navigation.helper';

declare var $: any;

@Component({
  selector: 'top-navbar',
  templateUrl: 'top-navbar.template.html'
})
export class TopNavbarComponent{
  toggleNavigation() {
    
      if ($(".page-sidebar .x-navigation").hasClass("x-navigation-minimized")) {
          $(".page-container").removeClass("page-navigation-toggled");
      x_navigation_minimize("open");
    } else {
          $(".page-container").addClass("page-navigation-toggled");
      x_navigation_minimize("close");
    }

    onresize(null);
  }

  ngAfterViewInit() {
    x_navigation();
  }

  countryList: any = [];
  userFullName: any = '';
  userCountry: any = '';
  notificationList: any = [];

  constructor(private dialogService: DialogService, private router: Router, private commonService: CommonService, private storageService: StorageService) {
      this.userFullName = this.storageService.getData('UserFullName'); //.replace(/"/g, '');
      this.userCountry = this.storageService.getData('UserCountry'); //.replace(/"/g, '');
  }

  ngOnInit() {
      this.commonService.getUpcomingNotifications(true, '')
          .subscribe(response => {
              if (response.Status == 'Success') {
                  this.notificationList = response.data;
                  console.log(this.notificationList);
              }
          },
          error => {
              this.router.navigate(['/login']);
          });
  }

  showConfirm() {
    let disposable = this.dialogService.addDialog(ConfirmComponent)
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.router.navigate(['/login']);
        }
      });
    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    // setTimeout(() => {
    //   disposable.unsubscribe();
    // }, 10000);
  }

  openModal() {
      $('#modal_notifications').modal('show');
  }

  onChange(country) {
      this.storageService.setData('UserCountry', country);
  }
}
