import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '../../config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { StorageService } from '../common/storage.service';

// Decorator to tell Angular that this class can be injected as a service to another class
@Injectable()
export class CreditorService {
    httpUrl: config = new config();
    constructor(private http: HttpClient, private storageService: StorageService) {

    }

    search(showAll: boolean = false): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/search?showAll=" + showAll)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getDetails(creditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getDetail?CreditorId=" + creditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getCreditorContacts(creditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getCreditorContacts?creditorId=" + creditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveContacts(request): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/saveContact", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    save(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/save", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getDebtors(isPrimary: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getDebtors?isPrimary=" + isPrimary)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getCreditorNotes(CreditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getCreditorNotes?CreditorId=" + CreditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    addCreditorNote(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/addCreditorNote", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    deleteCreditorNote(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/deleteCreditorNote", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    uploadDocument(file: any, request: any): Observable<any> {
        let formData = new FormData();
        formData.append('creditorId', request.creditorId);
        formData.append('debtorId', request.debtorId);
        formData.append('name', request.name);
        formData.append('description', request.description);
        formData.append('File', file);

        return Observable.create(observer => {
            var xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', this.httpUrl.url + 'creditor/uploadDocument', false);
            xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
            xhr.send(formData);
        });
    }
    uploadPOCDocument(file: any, request: any): Observable<any> {
        let formData = new FormData();
        formData.append('creditorId', request.creditorId);
        formData.append('debtorId', request.debtorId);
        formData.append('POCDocumentId', request.POCDocumentId);
        formData.append('FileId', request.FileId);
        formData.append('File', file);

        return Observable.create(observer => {
            var xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', this.httpUrl.url + 'creditor/UploadPOCDocument', false);
            xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
            xhr.send(formData);
        });
    }

    getPOCDocuments(CreditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getPOCDocumentList?CreditorId=" + CreditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getDocuments(CreditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getDocumentList?CreditorId=" + CreditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getClaims(CreditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getClaims?CreditorId=" + CreditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveClaim(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/saveClaim", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getCreditorDebtor(CreditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getCreditorDebtor?CreditorId=" + CreditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveBidWithFile(file: any, request: any): Observable<any> {
        let formData = new FormData();
        formData.append('BidId', request.BidId);
        formData.append('BidDate', request.BidDate);
        formData.append('BidAmount', request.BidAmount);
        formData.append('ClaimType', request.ClaimType);
        formData.append('BidStatus', request.BidStatus);
        formData.append('CreditorId', request.CreditorId);
        formData.append('ContactId',request.ContactId);
        formData.append('DebtorId', request.DebtorId);
        formData.append('ClaimList', JSON.stringify(request.ClaimList));
        formData.append('IsActive', request.IsActive);
        formData.append('Note', request.Note);
        formData.append('EventNote', request.Note);
        formData.append('EventDate', request.EventDate);
        formData.append('EventTime', request.EventTime);
        formData.append('NotificationList',JSON.stringify(request.NotificationList));
        formData.append('File', file);

        return Observable.create(observer => {
            var xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.open('POST', this.httpUrl.url + 'creditor/saveBidFile', false);
            xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
            xhr.send(formData);
        });
    }

    saveBid(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/saveBid", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getBids(CreditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getBids?CreditorId=" + CreditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getClaimsReport(CreditorId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "creditor/getClaimsReport?CreditorId=" + CreditorId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveBidDates(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/saveBidDates", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveClaimStatus(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "creditor/saveClaimStatus", request)
            .map(this.extractData)
            .catch(this.handleError);
    }

    extractData(res: Response) {
        return res || {};
    }

    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg, error.status);
        return errMsg;
    }
}
