import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'underscore';
import { DebtorService } from '../debtor/debtor.service';
import * as XLSX from 'xlsx';

declare var swal: any;
declare var $: any;

@Component({
    selector: 'importError',
    templateUrl: 'importError.template.html',
    styleUrls: ['../../../assets/css/mat-table.css']
})

export class ImportErrorComponent implements OnInit {
    @ViewChild('errorPaginator') errorPaginator: MatPaginator;
    @ViewChild('errorSort') errorSort: MatSort;
    errorColumns: string[] = ['OriginalFileName', 'Creditor', 'Debtor', 'Contact' , 'Amount', 'Notes','ErrorMessage'];
    errorDataSource: MatTableDataSource<any>;
    errorList:any = [];
    filteredErrorList: any = [];
    fileId:any = "";

    constructor(private router: Router, private pageHelper: PageHelper, private spinner: NgxSpinnerService
        , private debtorService : DebtorService , private route: ActivatedRoute) { 

    }

    ngOnInit() {
        $('#menuImportError').addClass('active');

        if (this.route.snapshot.queryParams["fileId"]) {
            this.fileId = this.route.snapshot.queryParams["fileId"];
            this.geterrorList();
        }
        else{
            this.router.navigate(['/import']);
        }
    }

    geterrorList() {
        this.spinner.show();
        this.debtorService.getErrorList(this.fileId).subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.errorList = response.data;
                    this.errorList.forEach(element => {
                        if(!element.Notes)
                            element.Notes = "";
                        element.isNoteExpanded = false;
                        element.isErrorExpanded = false;
                    });
                    this.filteredErrorList = JSON.parse(JSON.stringify(this.errorList));

                    this.errorDataSource = new MatTableDataSource(this.filteredErrorList);
                    this.errorDataSource.sort = this.errorSort;
                    this.errorDataSource.paginator = this.errorPaginator;
                    this.resizePage();   
                }
            },
            error => {
                this.spinner.hide();
                this.router.navigate(['/login']);
            });
    }

    resizePage(){
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }

    exportToExcel(){
        var data = this.errorDataSource.filteredData;
        
        var exportData = [];
        data.forEach(debtor => {
            exportData.push({
                "File Name" : debtor.OriginalFileName,
                "Creditor": debtor.Creditor,
                "Debtor": debtor.Debtor,
                "Contact": debtor.Contact,
                "Amount": debtor.Amount,
                "Notes": debtor.Notes,
                "Error Message": debtor.ErrorMessage
            })
        });

        if(!data || data.length == 0)
        return;
        
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
        XLSX.writeFile(wb, 'Creditor_Import_Error.xlsx');
    }
}