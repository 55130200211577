import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { DebtorService } from './debtor.service';
import { CommonService } from '../common/common.service';
import * as _ from 'underscore';
import * as XLSX from 'xlsx';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'debtor-search',
  templateUrl: 'debtor-search.template.html',
  styleUrls: ['../../../assets/css/mat-table.css']
})

export class DebtorSearchComponent {
  @ViewChild('debtorPaginator') debtorPaginator: MatPaginator;
  @ViewChild('debtorSort') debtorSort: MatSort;
  debtorColumns: string[] = ['CaseNumber', 'PrimaryDebtor', 'FilingDate', 'ClaimsAgent', 'Industry', 'PlanStatus', 'IsActive', 'IsRestricted'];
  debtorDataSource: MatTableDataSource<any>;

  clonedList = [];
  debtorList: any = [];
  showAll: boolean = false;
  filter: any = {

  };
  searchModel = "";

  claimsAgentList = [];
  industryList = [];
  planStatusList = [];

  constructor(private router: Router, private debtorService: DebtorService,
    private commonService: CommonService, private pageHelper : PageHelper, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    $('#menuDebtors').addClass('active');

    this.getDebtorList(this.showAll);
    this.getLookupValues('ClaimsAgent');
    this.getLookupValues('Industry');
    this.getLookupValues('PlanStatus');

    this.clearFilter();
  }

  getDebtorList(showAll: any = false) {
    this.searchModel = "";
    this.spinner.show();
    this.debtorService.search(!showAll).subscribe(
      response => {
        this.spinner.hide();
        if (response.Status == 'Success') {
          response.data.forEach(function (item) {
            if (item.FilingDate)
              item.FilingDate = new Date(item.FilingDate).setHours(0, 0, 0, 0)
          });

          this.clonedList = response.data.map(x => Object.assign({}, x));
          this.debtorList = response.data;
          this.debtorDataSource = new MatTableDataSource(this.debtorList);
          this.debtorDataSource.sort = this.debtorSort;
          this.debtorDataSource.paginator = this.debtorPaginator;
          setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
        }
      },
      error => {
        this.router.navigate(['/login']);
        this.spinner.hide();
      });
  }


  getLookupValues(type) {
    this.commonService.getLookup(type, false).subscribe(
      response => {
        if (response.Status == 'Success') {
          switch (type) {
            case 'ClaimsAgent':
              this.claimsAgentList = response.data;
              break;
            case 'Industry':
              this.industryList = response.data;
              break;
            case 'PlanStatus':
              this.planStatusList = response.data;
              break;
            default:
              break;
          }
        }
      },
      error => {
        this.router.navigate(['/login']);
      });
  }

  clearFilter() {
    this.filter = {
      "ClaimAgent": "",
      "Industry": '',
      "PlanStatus": "",
      "FillingStartDate": null,
      "FillingEndDate": null
    }
    this.applyFilter();
  }

  applyFilter() {
    var tempList = this.clonedList.map(x => Object.assign({}, x));

    var self = this;

    if (this.filter.ClaimAgent) {
      tempList = _.filter(tempList, function (data) {
        return data.ClaimsAgent == self.filter.ClaimAgent
      });
    }

    if (this.filter.Industry) {
      tempList = _.filter(tempList, function (data) {
        return data.Industry == self.filter.Industry
      });
    }

    if (this.filter.PlanStatus) {
      tempList = _.filter(tempList, function (data) {
        return data.PlanStatus == self.filter.PlanStatus
      });
    }

    if (this.filter.FillingStartDate && this.filter.FillingEndDate) {
      tempList = _.filter(tempList, function (data) {
        return (new Date(self.filter.FillingStartDate) <= new Date(data.FilingDate)) && (new Date(data.FilingDate) <= new Date(self.filter.FillingEndDate))
      });
    }
    else if (this.filter.FillingStartDate) {
      tempList = _.filter(tempList, function (data) {
        return new Date(self.filter.FillingStartDate) <= new Date(data.FilingDate)
      });
    }
    else if (this.filter.FillingEndDate) {
      tempList = _.filter(tempList, function (data) {
        return new Date(self.filter.FillingEndDate) >= new Date(data.FilingDate)
      });
    }

    this.debtorList = tempList;
    this.debtorDataSource = new MatTableDataSource(this.debtorList);
    this.debtorDataSource.sort = this.debtorSort;
    this.debtorDataSource.paginator = this.debtorPaginator;
    setTimeout(() => {
      this.pageHelper.page_content_onresize();
    }, 500);
  }
  restrictInput(event: any) {
    event.preventDefault();
  }

  restrictDelete(event:any){
    var key = event.keyCode || event.charCode;

    if(key == 8 || key == 46 )
        return false;
  }

  debtorFilter(filterValue: string) {
    this.debtorDataSource.filter = filterValue.trim().toLowerCase();
  }

  ExportTOExcel(){
    var data = this.debtorDataSource.filteredData;

    var exportData = [];
    data.forEach(debtor => {
      exportData.push({
        "CaseNumber" : debtor.CaseNumber,
        "Primary Debtor": debtor.PrimaryDebtor,
        "Filing Date": this.convertDate(debtor.FilingDate),
        "Calims Agent": debtor.ClaimsAgent,
        "Industry": debtor.Industry,
        "Plan Status": debtor.PlanStatus,
        "Status": debtor.IsActive ? "Active" : "InActive",
        "Restricted" : debtor.IsRestricted ? "Restricted" : ""
      })
    });

    if(!data || data.length == 0)
      return;
      
    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    // XLSX.writeFile(wb, 'Debtors.xlsx');
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(exportData[0]);
    let csv = exportData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var a = document.createElement('a');
    var blob = new Blob([csvArray], {type: 'text/csv' }),
    url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = "Debtors.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  convertDate(str) {
    if (!str)
      return null;

    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  }
}
