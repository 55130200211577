import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '../../config';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

// Decorator to tell Angular that this class can be injected as a service to another class
@Injectable()
export class CommonService {
    httpUrl: config = new config();
    constructor(private http: HttpClient, private storageService : StorageService) {

    }

    getLookup(lookupType: any, showAll:any= false): Observable<any> {
        return this.http.get(this.httpUrl.url + "lookup/getLookup?LookupType="+ lookupType+"&showAll="+showAll)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getLookupDetails(LookupId: any): Observable<any> {
        return this.http.get(this.httpUrl.url + "lookup/getLookupById?LookupId="+ LookupId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveLookup(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "lookup/save", request )
            .map(this.extractData)
            .catch(this.handleError);
    }

    deleteLookup(request: any): Observable<any> {
      return this.http.post(this.httpUrl.url + "lookup/delete", request)
        .map(this.extractData)
        .catch(this.handleError);
    }

    getRestrictedList(): Observable<any> {
        return this.http.get(this.httpUrl.url + "common/getRestricted")
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveRestricted(request: any): Observable<any> {
        return this.http.post(this.httpUrl.url + "common/saveRestricted", request )
            .map(this.extractData)
            .catch(this.handleError);
    }

    getValuationList(showAll, CaseListingId): Observable<any> {
        return this.http.get(this.httpUrl.url + "valuation/getList?showAll="+showAll+"&CaseListingId="+CaseListingId)
            .map(this.extractData)
            .catch(this.handleError);
    }

    getStates(): Observable<any> {
        return this.http.get(this.httpUrl.url + "common/getStates")
            .map(this.extractData)
            .catch(this.handleError);
    }

    getUpcomingNotifications(forUser, type): Observable<any> {
        return this.http.get(this.httpUrl.url + "calendar/getUpcomingNotifications?forUser=" + forUser + "&Type=" + type)
            .map(this.extractData)
            .catch(this.handleError);
    }

    saveValuation(request: any, file:any): Observable<any> {
        let formData = new FormData();
        formData.append('ValuationDate', request.ValuationDate);
        formData.append('503b9Amount', request.Amount);
        formData.append('UnsecuredAmount', request.UnsecuredAmount);
        formData.append('SecuredAmount', request.SecuredAmount);
        formData.append('CaseListingId', request.CaseListingId);
        formData.append('File', file);

        return Observable.create(observer => {
            var xhr: XMLHttpRequest = new XMLHttpRequest();
      
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  observer.next(JSON.parse(xhr.response));
                  observer.complete();
                } else {
                  observer.error(xhr.response);
                }
              }
            };
            xhr.open('POST', this.httpUrl.url + "valuation/saveValuation", false);
            xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
            xhr.send(formData);
          });
    }
       
    extractData(res: Response) {
        return res || {};
    }
    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
         let errMsg: string;
         if (error instanceof Response) {
         const body = error.json() || '';
         const err = JSON.stringify(body);
         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
         } else {
         errMsg = error.message ? error.message : error.toString();
         }
         console.error(errMsg, error.status);
         return errMsg;
    }
}
