import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../common/common.service';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'datetype',
  templateUrl: 'datetype.template.html',
  styleUrls: ['../../../assets/css/mat-table.css']
})

export class DateTypeComponent implements OnInit {
  form: FormGroup;
  RecordType: any = "New";
  showAll: boolean = false;

  @ViewChild('Paginator') paginator: MatPaginator;
  @ViewChild('Sort') sort: MatSort;
  dataColumns: string[] = ['LookupValue', 'IsActive', 'Action'];
  dataSource: MatTableDataSource<any>;
  searchModel = "";

  dateTypeList: any = [];
  saveButton:boolean = false;

  constructor(private router: Router, private commonService: CommonService, private pageHelper : PageHelper, private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    $('#menuManage').addClass('active');
    $('#menuManage1').addClass('active');
    $('#menuManage1_7').addClass('active');

    this.getDateType();

    this.form = new FormGroup({
      LookupId: new FormControl(""),
      LookupValue: new FormControl('', [Validators.required]),
      LookupType: new FormControl('DateType'),
      IsActive: new FormControl(true)
    });
    this.form.get('LookupValue').valueChanges.subscribe(() => {
      this.saveButton = true;
    });
  }

  getDateType(GetActive: any = false) {
    this.spinner.show();
    this.searchModel = "";
    this.commonService.getLookup("DateType", GetActive).subscribe(
      response => {
        this.spinner.hide();
        if (response.Status == 'Success') {
          this.dateTypeList = response.data;
          this.dataSource = new MatTableDataSource(this.dateTypeList);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
        }
      },
      error => {
        this.spinner.hide();
        this.router.navigate(['/login']);
      });
  }

  save() {
    if (this.form.valid) {
      Object.keys(this.form.controls).forEach(key => {
        if ($('#' + key))
          $('#' + key).removeClass('validationError');
      });
      var request = {
        "LookupId": this.form.get('LookupId').value,
        "LookupValue": this.form.get('LookupValue').value,
        "LookupType": this.form.get('LookupType').value,
        "IsActive": this.form.get('IsActive').value
      }
      this.spinner.show();
      this.commonService.saveLookup(request).subscribe(
        response => {
          this.spinner.hide();
          if (response.Status == 'Success') {
            var message = response.Message.replace("##name##", "Date Type");
            swal('Success', message, 'success');

            this.setFormValues(null);
            this.getDateType(this.showAll);
          }
          else {
            var message = response.Message.replace("##name##", "Date Type");
            swal('Error', message, 'error');
          }
        },
        () => {
          this.spinner.hide();
          this.router.navigate(['/login']);
        }
      );
    }
    else {
      Object.keys(this.form.controls).forEach(key => {
        if (!this.form.controls[key].valid) {
          if ($('#' + key))
            $('#' + key).addClass('validationError');
        }
      });
    }
  }

  setFormValues(data: any) {
    if (data) {
      this.form.setValue({
        LookupId: data.LookupId,
        LookupValue: data.LookupValue,
        LookupType: data.LookupType,
        IsActive: data.IsActive
      });
      this.RecordType = "Update";
    }
    else {
      this.form.setValue({
        LookupId: "",
        LookupValue: "",
        LookupType: "DateType",
        IsActive: true
      });
      this.RecordType = "New";
      this.saveButton = false;
      Object.keys(this.form.controls).forEach(key => {
        if ($('#' + key))
          $('#' + key).removeClass('validationError');
      });
    }
  }

  updateRecordType() {
    this.RecordType = "Update";
  }

  deleteDateType(data: any) {
    var self = this;

    swal({
        title: 'Warning',
      text: "Are you sure you want to delete Date Type?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(function (isConfirmed) {
      if (isConfirmed.value) {
        var request = {
          "LookupId": data.LookupId,
          "LookupValue": '',
          "LookupType": data.LookupType
        }
        self.spinner.show();
        self.commonService.deleteLookup(request).subscribe(
          response => {
            self.spinner.hide();
            if (response.Status == 'Success') {
              var message = response.Message.replace("##name##", "Date Type");
              swal('Success', message, 'success');

              self.setFormValues(null);
              self.getDateType(self.showAll);
            }
            else {
              var message = response.Message.replace("##name##", "Date Type");
              swal('Error', message, 'error');
            }
          },
          () => {
            self.spinner.hide();
            self.router.navigate(['/login']);
          }
        );
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
