import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './login.service';
import { StorageService } from '../common/storage.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

declare var swal: any;

@Component({
  selector: 'app-resetPassword',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./login.component.css']
})
export class ResetPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  resetForm: FormGroup;
  year: any;
  userKey: string = "";

  constructor(private router: Router, private loginService: LoginService, private storageService: StorageService
    , private route: ActivatedRoute, private spinner: NgxSpinnerService) {
      this.year = new Date().getFullYear();

      this.forgotForm = new FormGroup({
          UserName: new FormControl('', [Validators.required])
      });

      if(this.route.snapshot.queryParams["Key"])
        this.userKey = this.route.snapshot.queryParams["Key"];

      this.resetForm = new FormGroup({
          TempPassword: new FormControl('', [Validators.required]),
          NewPassword: new FormControl('', [Validators.required]),
          ConfirmPassword: new FormControl('', [Validators.required])
      }, {validators: this.checkPasswords });
  }

  ngOnInit() {
    if (this.storageService.getData('EncryptedToken')) {
      this.storageService.removeData('EncryptedToken');
      this.storageService.removeData('UserFullName');
    }
  }

  forgotPassword() {
    if(this.forgotForm.valid){
      var request = {
        "UserName": this.forgotForm.get('UserName').value
      }
      this.spinner.show();
      this.loginService.forgotPassword(request).subscribe(
        response => {
            this.spinner.hide();
            if (response.Status == 'Success') {
              swal('Success', response.Message , 'success')
              this.router.navigate(['/login']);
            }
            else{
              swal('Error', response.Message, 'error');
            }
        },
        () => {
            this.spinner.hide();
            this.storageService.clear();
        }
      );
    }
  }

  resetPassword() {
    if(this.resetForm.valid){
      var request = {
        "UserKey" : this.userKey,
        "TempPassword": this.resetForm.get('TempPassword').value,
        "Password": this.resetForm.get('NewPassword').value
      }
      this.spinner.show();
      this.loginService.resetPassword(request).subscribe(
        response => {
            this.spinner.hide();
            if (response.Status == 'Success') {
              swal('Success',response.Message , 'success')
              this.router.navigate(['/login']);
            }
            else{
              swal('Error', response.Message, 'error');
            }
        },
        () => {
            this.spinner.hide();
            this.storageService.clear();
        }
      );
    }
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.NewPassword.value;
    let confirmPass = group.controls.ConfirmPassword.value;

    if(pass && confirmPass)
      return pass === confirmPass ? null : { notSame: true }
    else
      return null;
  }
}
