import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageHelper } from '../common/page.helper';
import * as _ from 'underscore';
import { DebtorService } from '../debtor/debtor.service';

declare var swal: any;
declare var $: any;

@Component({
    selector: 'import',
    templateUrl: 'import.template.html',
    styleUrls: ['../../../assets/css/mat-table.css']
})

export class ImportComponent implements OnInit {
    @ViewChild('filePaginator') filePaginator: MatPaginator;
    @ViewChild('fileSort') fileSort: MatSort;
    fileColumns: string[] = ['OriginalFileName', 'InsertionDate'];
    fileDataSource: MatTableDataSource<any>;
    importedFile: any = [];

    searchModel = "";

    constructor(private router: Router , private pageHelper: PageHelper
        , private debtorService : DebtorService , private route: ActivatedRoute) { 

    }

    ngOnInit() {
        $('#menuImportError').addClass('active');
        this.getImportedFiles();
    }

    resizePage(){
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }

    getImportedFiles() {
        this.debtorService.getImportedFiles(true)
          .subscribe(response => {
            if (response.Status == 'Success') {
              this.importedFile = response.data;
              this.fileDataSource = new MatTableDataSource(this.importedFile);
              this.fileDataSource.sort = this.fileSort;
              this.fileDataSource.paginator = this.filePaginator;
              this.resizePage();   
            }
          },
            error => {
              this.router.navigate(['/login']);
            });
    }

    applyFilter() {
        this.fileDataSource.filter = this.searchModel.trim().toLowerCase();
        this.resizePage();
    }
}