import {Routes} from "@angular/router";

import { LoginComponent } from "./component/login/login.component";
import { DashboardComponent } from "./component/dashboard/dashboard.component";
import { DebtorComponent } from "./component/debtor/debtor.component";
import { DebtorSearchComponent } from "./component/debtor/debtor-search.component";
import { CreditorComponent } from "./component/creditor/creditor.component";
import { CreditorSearchComponent } from "./component/creditor/creditor-search.component";
import { ContactComponent } from "./component/contact/contact.component";
import { UserComponent } from "./component/user/user.component";
import { ClaimsAgentComponent } from './component/claimsAgent/claimsagent.component';
import { IndustryComponent } from './component/industry/industry.component';
import { CapitalComponent } from './component/capital/capital.component';
import { ReasonComponent } from './component/reason/reason.component';
import { PlanComponent } from './component/plan/plan.component';
import { AuditTrailSearchComponent } from "./component/auditTrail/auditTrail-search.component";
import { ClaimStatusComponent } from './component/claimStatus/claimstatus.component';
import {SourcerReportsComponent} from './component/reports/SourcerReport/sourcer.reports.component'
import {DailyTradeReportsComponent} from './component/reports/dailyTrade/dailyTrade.reports.component';import { DateTypeComponent } from './component/DateType/datetype.component';
import { RestrictedComponent } from "./component/restricted/restricted.component";
import { CalendarComponent } from "./component/calendar/calendar.component";
import { ContactTypeComponent } from './component/contactType/contactType.component';
import { ValuationReportComponent } from './component/reports/ValuationReport/valuationReports.component';
import { ClaimReportComponent } from './component/reports/ClaimReport/claimreport.component';
import { ImportErrorComponent } from "./component/importError/importError.component";
import { ImportComponent } from "./component/importError/import.component";
import { AuthGuard } from "./component/common/auth.guard";
import { ResetPasswordComponent } from "./component/login/resetPassword.component";

export const ROUTES: Routes = [
    {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
    },
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]
    },
    {
        path: 'debtor-search', component: DebtorSearchComponent, canActivate: [AuthGuard]
    },
    {
        path: 'debtor', component: DebtorComponent, canActivate: [AuthGuard]
    },
    {
        path: 'creditor-search', component: CreditorSearchComponent, canActivate: [AuthGuard]
    },
    {
        path: 'creditor', component: CreditorComponent, canActivate: [AuthGuard]
    },
    {
        path: 'contact', component: ContactComponent, canActivate: [AuthGuard]
    },
    {
        path: 'contact/:ContactId', component: ContactComponent, canActivate: [AuthGuard]
    },
    {
        path: 'user', component: UserComponent, canActivate: [AuthGuard]
    },
    {
        path: 'claimsAgent', component: ClaimsAgentComponent, canActivate: [AuthGuard]
    },
    {
        path: 'industry', component: IndustryComponent, canActivate: [AuthGuard]
    },
    {
        path: 'reason', component: ReasonComponent, canActivate: [AuthGuard]
    },
    {
        path: 'capital', component: CapitalComponent, canActivate: [AuthGuard]
    },
    {
        path: 'plan', component: PlanComponent, canActivate: [AuthGuard]
    },
    {
        path: 'auditTrail-search', component: AuditTrailSearchComponent, canActivate: [AuthGuard]
    },
    {
        path: 'claimStatus', component: ClaimStatusComponent, canActivate: [AuthGuard]
    },
    {
        path: 'sourcer-reports', component: SourcerReportsComponent, canActivate: [AuthGuard]
    },
    {
        path: 'dailyTrade-reports', component: DailyTradeReportsComponent, canActivate: [AuthGuard]
    },
    {
        path: 'dateType', component: DateTypeComponent, canActivate: [AuthGuard]
    },
    {
        path: 'restricted', component: RestrictedComponent, canActivate: [AuthGuard]
    },
    {
        path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard]
    },
    {
        path: 'contactType', component: ContactTypeComponent, canActivate: [AuthGuard]
    },
    {
        path: 'valuationreport', component: ValuationReportComponent, canActivate: [AuthGuard]
    },
    {
        path: 'importError', component: ImportErrorComponent, canActivate: [AuthGuard]
    },
    {
        path: 'import', component: ImportComponent, canActivate: [AuthGuard]
    },
    {
      path: 'claimreport', component: ClaimReportComponent, canActivate: [AuthGuard]
    },
    {
      path: 'claimreport', component: ClaimReportComponent, canActivate: [AuthGuard]
    },
    {
      path: 'forgotPassword', component: ResetPasswordComponent
    },
    {
      path: 'resetPassword', component: ResetPasswordComponent
    },
    { path: '**', redirectTo: 'dashboard' }
];
