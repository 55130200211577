import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportService } from '../reports.service';
import * as _ from 'underscore';
import { PageHelper } from '../../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditorService } from '../../creditor/creditor.service';
import { CdkDetailRowDirective } from '../../common/cdk-detail-row.directive';
import {animate, state, style, transition, trigger} from '@angular/animations';

declare var swal: any;
declare var $: any;

@Component({
  selector: 'dailyTrade-reports',
  templateUrl: 'dailyTrade.reports.template.html',
  styleUrls: ['../../../../assets/css/mat-table.css'],
  animations: [
      trigger('detailExpand', [
          state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
          state('*', style({ height: '*', visibility: 'visible' })),
          transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
  ],
})

export class DailyTradeReportsComponent implements OnInit {

  @ViewChild('dailyTradeReportPaginator') dailyTradeReportPaginator: MatPaginator;
  @ViewChild('dailyTradeReportSort') dailyTradeReportSort: MatSort;
  dailyTradeReportColumns: string[] = ['Debtor', 'CaseNo', 'BidDate', 'ClaimType','TotalAmount', 'BidAmount', 'BidPercent', 'Creditor', 'TradeDate','SettleDate' ,'TradeDateId', 'User'];
  dailyTradeReportDataSource: MatTableDataSource<any>;
  clonedList = [];

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');

  dailyTradeAndSettleReportList: any = [];

  filteringDebtorList: any = [];

  filter = {
    debtorId: '',
    fromdate: null,
    todate: null
  }
  Debtor: any = "";
  DebtorId: any = "";

  searchModel = "";
  debtorList: any = [];

  constructor(private router: Router, private reportService: ReportService,
    private pageHelper: PageHelper, private spinner: NgxSpinnerService, private creditorService: CreditorService) {

  }

  ngOnInit() {
    $('#menuReport').addClass('active');
    $('#menuTradeSettle').addClass('active');
    this.getDebtors(); //to fill filter drop down list
    this.clearFilter();
  }

  getDailyTradeReport() {
    this.searchModel = "";
    this.spinner.show();

    this.reportService.getDailyTradeReport(this.filter.debtorId, this.convertDate(this.filter.fromdate), this.convertDate(this.filter.todate), '').subscribe(
      response => {
        this.spinner.hide();

        if (response.Status == 'Success') {
          this.dailyTradeAndSettleReportList = response.data;
          this.dailyTradeReportDataSource = new MatTableDataSource(this.dailyTradeAndSettleReportList);
          this.dailyTradeReportDataSource.sort = this.dailyTradeReportSort;
          this.dailyTradeReportDataSource.paginator = this.dailyTradeReportPaginator;
          setTimeout(() => {
            this.pageHelper.page_content_onresize();
          }, 500);
        }
      },
      error => {
        this.spinner.hide();
        this.router.navigate(['/login']);
      });
  }


  getDebtors() {
    this.creditorService.getDebtors(false)
      .subscribe(response => {
        if (response.Status == 'Success') {
          this.debtorList = response.data;

        }
      },
        error => {
          this.router.navigate(['/login']);
        });
  }

  clearFilter() {
    this.filter = {
      debtorId: '',
      fromdate: null,
      todate: null 
    }

    this.applyFilter();
  }



  applyFilter() {

    this.getDailyTradeReport();

  }


  restrictInput(event: any) {
    event.preventDefault();
  }

  restrictDelete(event: any) {
    var key = event.keyCode || event.charCode;

    if (key == 8 || key == 46)
      return false;
  }

  selectDebtor(data) {
    if (data.option.value) {
      var result = _.find(this.debtorList, function (valuation) {
        return valuation.DebtorId == data.option.value;
      });
      if (result) {
        this.Debtor = result.Debtor;
        this.DebtorId = data.option.value;
        this.filter.debtorId = data.option.value;
        this.applyFilter();
      }
    }
    else {
      this.Debtor = "";
    }
  }

  convertDate(str) {
    if (!str)
      return null;

    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  }

  ExportTOExcel() {
    var data = this.dailyTradeAndSettleReportList;
    var exportData = [];

    data.forEach(element => {
      exportData.push({
        "Debtor": element.Debtor,
        "Case Nunmber": element.ClaimNo,
        "Bid Date": element.BidDate,
        "Claim Type": element.ClaimType,
        "Bid On":element.TotalAmount,
        "Bid Amount": '$' + element.BidAmount,
        "Bid Percent":  element.BidPercent + '%',
        "Sourcer": '$' + element.User,
        "Creditor": element.Creditor,
        "Trade Date": element.TradeDate,
        "Settle Date":element.SettleDate,
        "Trade Date ID": element.TradeDateId
      })
    });

    if (!exportData || exportData.length == 0)
      return;
    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.writeFile(wb, 'DailyTradeAndSettleDateReport.xlsx');
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(exportData[0]);
      let csv = exportData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      var a = document.createElement('a');
      var blob = new Blob([csvArray], {type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = "TradeDateReport.csv";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();    
  }

  filterDebtor(val) {
    var self = this;
    var list = this.debtorList;
    this.DebtorId = "";
    if (val) {
      const filterValue = val.toLowerCase();
      list = list.filter(valuation => valuation.Debtor.toLowerCase().startsWith(filterValue))
      return list;
    }
    return list;
  }
  
  @Input() singleChildRowDetail: boolean;

  private openedRow: CdkDetailRowDirective
  onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
      if (this.singleChildRowDetail && this.openedRow && this.openedRow.expended) {
          this.openedRow.toggle();
      }
      this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
      setTimeout(() => {
          this.pageHelper.page_content_onresize();
      }, 500);
  }
}
