import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { ReportService } from '../../reports/reports.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageHelper } from '../../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'underscore';
import { debug } from 'util';
import { formatDate } from '@angular/common';

declare var swal: any;
declare var $: any;

@Component({
    selector: 'sourcer-reports',
    templateUrl: 'sourcer.reports.template.html',
    styleUrls: ['../../../../assets/css/mat-table.css']
})

export class SourcerReportsComponent implements OnInit {
    @ViewChild('sourcerPaginator') sourcerPaginator: MatPaginator;
    @ViewChild('sourcerSort') sourcerSort: MatSort;
    sourcerColumns: string[] = ['Name','NoteCount'];
    sourcerDataSource: MatTableDataSource<any>;
    sourcerList: any = [];

    userList: any = [];
    userName: any = "";
    userKey: any = "";
    filteringUserList: any = [];

    clonedList: any = [];

    filter: any = {

    };


    constructor(private router: Router, private pageHelper: PageHelper, private spinner: NgxSpinnerService,
        private userService: UserService, private reportService: ReportService) {

    }

    ngOnInit() {
        $('#menuReport').addClass('active');
        $('#menuSourcerReport').addClass('active');
        this.getUserList();
        this.clearFilter();
    }

    getSourcerReport(userKey, FromDate, Todate) {
        this.spinner.show();
        this.reportService.getSourcerReport(userKey, FromDate, Todate, "")
            .subscribe(response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.clonedList = response.data.map(x => Object.assign({}, x));
                    this.sourcerList = response.data;
                    this.sourcerDataSource = new MatTableDataSource(this.sourcerList);
                    this.sourcerDataSource.sort = this.sourcerSort;
                    this.sourcerDataSource.paginator = this.sourcerPaginator;
                    setTimeout(() => {
                        this.pageHelper.page_content_onresize();
                    }, 500);
                }
            },
                error => {
                    this.router.navigate(['/login']);
                    this.spinner.hide();
                })
    }

    getUserList(showAll: boolean = false) {
        this.userService.search(showAll).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.userList = response.data;
                    this.filteringUserList = this.filterUser("");
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    ///Filtering Start Date and End Date and UserList

    applyFilter() {

        var startdate = null;
        var endDate = null;

        if (this.filter.FillingStartDate)
            startdate = formatDate(this.filter.FillingStartDate, 'MM-dd-yyyy', 'en-US', '+0530');

        if (this.filter.FillingEndDate)
            endDate = formatDate(this.filter.FillingEndDate, 'MM-dd-yyyy', 'en-US', '+0530');


        this.getSourcerReport(this.userKey, startdate, endDate);
    }

    clearFilter() {
        this.filter = {
            "FillingStartDate": null,
            "FillingEndDate": null
        }
        this.userKey = "";
        this.userName = "";
        this.filteringUserList = this.filterUser("");
        this.getSourcerReport("", "", "");
    }


    restrictInput(event: any) {
        event.preventDefault();
    }

    restrictDelete(event: any) {
        var key = event.keyCode || event.charCode;

        if (key == 8 || key == 46)
            return false;
    }

    selectUser(data) {
        if (data.option.value) {
            var result = _.find(this.userList, function (user) {
                return user.UserKey == data.option.value;
            });
            if (result) {
                this.userName = result.Name;
                this.userKey = data.option.value;
                this.applyFilter();
            }
        }
        else {
            this.userName = "";
        }
    }

    filterUser(val) {
        var self = this;
        var list = this.userList;
        this.userKey = "";
        if (val) {
            const filterValue = val.toLowerCase();
            list = list.filter(user => user.Name.toLowerCase().startsWith(filterValue))
            return list;
        }
        return list;
    }

    ExportTOExcel() {
        var data = this.sourcerDataSource.filteredData;
        var exportData = [];
        data.forEach(item => {
            exportData.push({
                "Sourcer": item.Name,
                "Notes Count": item.NoteCount
            })
        });

        if (!exportData || exportData.length == 0)
            return;

        const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
        const header = Object.keys(exportData[0]);
        let csv = exportData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var a = document.createElement('a');
        var blob = new Blob([csvArray], { type: 'text/csv' }),
            url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = "sourcerReport.csv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
