import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import * as _ from 'underscore';
import { PageHelper } from '../../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportService } from '../reports.service';

import {animate, state, style, transition, trigger} from '@angular/animations';
import { CdkDetailRowDirective } from '../../common/cdk-detail-row.directive';

declare var $: any;

@Component({
  selector: 'claimreport',
  templateUrl: 'claimreport.template.html',
  animations: [
      trigger('detailExpand', [
          state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
          state('*', style({ height: '*', visibility: 'visible' })),
          transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
  ],
})

export class ClaimReportComponent implements OnInit {

    @ViewChild('claimReportPaginator') claimReportPaginator: MatPaginator;

  claimStatusId: any = "";
  claimStatusList : any = ""
  claimReportList: any = [];
  reportSummary: any = {};
  reportDataSource: MatTableDataSource<any>;
  reportColumns: string[] = ['debtor', 'creditor', 'cost', 'faceValue', 'expectedRecovey', 'ucost', 'ufaceValue', 'uexpectedRecovey', 'scost', 'sfaceValue', 'sexpectedRecovey'];

  constructor(private router: Router, private reportService: ReportService,
    private pageHelper: PageHelper, private spinner: NgxSpinnerService, private route : ActivatedRoute) {

  }

  ngOnInit() {
    $('#menuReport').addClass('active');
    $('#menuClaimReport').addClass('active');

    if(this.route.snapshot.queryParams["ClaimStatusId"])
      this.claimStatusId = this.route.snapshot.queryParams["ClaimStatusId"];

    this.getClaimReportLookup();
  }

  getClaimReportLookup(){
    this.spinner.show();
    this.reportService.getClaimReportLookup()
        .subscribe(response => {
            this.spinner.hide();
            if (response.Status == 'Success') {
                this.claimStatusList = response.data;

                //var list = JSON.parse(JSON.stringify(this.claimStatusList));
                if (!this.claimStatusId) {
                    this.claimStatusList.forEach(element => {
                        if (element.LookupValue == "Claim Purchased and Funded") {
                            this.claimStatusId = element.LookupId;
                        }
                    });
                }

              this.getClaimStatus();
            }
        },
        error => {
            this.router.navigate(['/login']);
            this.spinner.hide();
        })
  }

  setClaimStatus(claimStatusId) {
      this.claimStatusId = claimStatusId;
      this.getClaimStatus();
  }

  getClaimStatus(){
    this.spinner.show();
    this.reportService.getClaimReport(this.claimStatusId)
        .subscribe(response => {
            this.spinner.hide();
            if (response.Status == 'Success') {
                this.claimReportList = response.data.Report;
                console.log(this.claimReportList);
                this.reportDataSource = new MatTableDataSource(this.claimReportList);
                this.reportDataSource.paginator = this.claimReportPaginator;
                this.reportSummary = response.data.Summary;
                setTimeout(() => {
                    this.pageHelper.page_content_onresize();
                }, 500);
            }
        },
        error => {
            this.router.navigate(['/login']);
            this.spinner.hide();
        })
  }

    ExportTOExcel(){
        var data = this.reportDataSource.filteredData;
    
        var exportData = [];

        data.forEach(debtor => {
            exportData.push({
                "Primary Debtor": debtor.PrimaryDebtor,
                "Sub Debtor": "",
                "Creditor": "",
                "503b9 Cost": '$' + debtor.AmountCost,
                "503b9 Face Value": '$' + debtor.AmountFaceValue,
                "503b9 Expected Recovery": '$' + debtor.AmountExpectedRecovery,
                "Unsecured Cost": '$' + debtor.UnSecuredCost,
                "Unsecured Face Value": '$' + debtor.UnSecuredFaceValue,
                "Unsecured Expected Recovery": '$' + debtor.UnSecuredExpectedRecovery,
                "Secured Cost": '$' + debtor.SecuredCost,
                "Secured Face Value": '$' + debtor.SecuredFaceValue,
                "Secured Expected Recovery": '$' + debtor.SecuredExpectedRecovery
            })
            debtor.CreditorList.forEach(creditor => {
                exportData.push({
                    "Primary Debtor": debtor.Debtor,
                    "Sub Debtor": creditor.DebtorName,
                    "Creditor": creditor.CreditorName,
                    "503b9 Cost": '$' + creditor.AmountCost,
                    "503b9 Face Value": '$' + creditor.AmountFaceValue,
                    "503b9 Expected Recovery": '$' + creditor.AmountExpectedRecovery,
                    "Unsecured Cost": '$' + creditor.UnSecuredCost,
                    "Unsecured Face Value": '$' + creditor.UnSecuredFaceValue,
                    "Unsecured Expected Recovery": '$' + creditor.UnSecuredExpectedRecovery,
                    "Secured Cost": '$' + creditor.SecuredCost,
                    "Secured Face Value": '$' + creditor.SecuredFaceValue,
                    "Secured Expected Recovery": '$' + creditor.SecuredExpectedRecovery
                })
            })
        });

        if (!exportData || exportData.length == 0)
            return;

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
        // XLSX.writeFile(wb, 'Creditors.xlsx');
        const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
        const header = Object.keys(exportData[0]);
        let csv = exportData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var a = document.createElement('a');
        var blob = new Blob([csvArray], {type: 'text/csv' }),
        url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = "ClaimReport.csv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

  @Input() singleChildRowDetail: boolean;

  private openedRow: CdkDetailRowDirective
  onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
      if (this.singleChildRowDetail && this.openedRow && this.openedRow.expended) {
          this.openedRow.toggle();
      }
      this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
      setTimeout(() => {
          this.pageHelper.page_content_onresize();
      }, 500);
  }
}
