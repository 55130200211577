import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '../../config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

@Injectable()

export class ReportService {
  httpUrl: config = new config();
  constructor(private http: HttpClient) {
  }


  getSourcerReport(userKey, FromDate, ToDate,pageName): Observable<any> {    
    return this.http.get(this.httpUrl.url + "report/getSourcerReport?userKey=" + userKey + "&FromDate=" + FromDate + "&ToDate=" + ToDate+ "&pageName=" + pageName)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getValuationReport(debtorId: any, fromdate: any, todate: any, source: any): Observable<any> {
    return this.http.get(this.httpUrl.url + 'report/getValuationReport?DebtorId=' + debtorId + '&FromDate=' + fromdate + '&ToDate=' + todate + '&Source=' + source)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getClaimReport(claimStatus: any): Observable<any> {
    return this.http.get(this.httpUrl.url + 'report/getClaimReport?ClaimStatus=' + claimStatus)
      .map(this.extractData)
      .catch(this.handleError);
  }
  getDailyTradeReport(debtorId: any, fromdate: any, todate: any, pageName: any): Observable<any> {
    return this.http.get(this.httpUrl.url + 'report/getDailyTradeReport?DebtorId=' + debtorId + '&FromDate=' + fromdate + '&ToDate=' + todate + "&pageName=" + pageName)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getClaimReportLookup(): Observable<any> {
    return this.http.get(this.httpUrl.url + 'report/getClaimReportLookup')
      .map(this.extractData)
      .catch(this.handleError);
  }

  getDashboardClaimReportSummary(): Observable<any> {
    return this.http.get(this.httpUrl.url + 'report/getDashboardClaimReportSummary')
      .map(this.extractData)
      .catch(this.handleError);
  }

  extractData(res: Response) {
    return res || {};
  }

  private handleError(error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg, error.status);
    return errMsg;
  }
}


