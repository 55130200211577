declare var jQuery: any;

export function onresize(timeout: any) {
  timeout = timeout ? timeout : 200;
  setTimeout(function () {
     page_content_onresize();
  }, timeout);
}

export function page_content_onresize() {
  jQuery(".page-content,.content-frame-body,.content-frame-right,.content-frame-left").css("width", "").css("height", "");

  var content_minus = 0;
  content_minus = (jQuery(".page-container-boxed").length > 0) ? 40 : content_minus;
  content_minus += (jQuery(".page-navigation-top-fixed").length > 0) ? 50 : 0;

  var content = jQuery(".page-content");
  var sidebar = jQuery(".page-sidebar");

  if (content.height() < jQuery(document).height() - content_minus) {
    content.height(jQuery(document).height() - content_minus);
  }

  if (sidebar.height() > content.height()) {
    content.height(sidebar.height());
  }

  if (jQuery(window).width() > 1024) {

    if (jQuery(".page-sidebar").hasClass("scroll")) {
      var doc_height = 0;
      if (jQuery("body").hasClass("page-container-boxed")) {
        doc_height = jQuery(document).height() - 40;
      } else {
        doc_height = jQuery(window).height();
      }
      jQuery(".page-sidebar").height(doc_height);

    }

    if (jQuery(".content-frame-body").height() < jQuery(document).height() - 162) {
      jQuery(".content-frame-body,.content-frame-right,.content-frame-left").height(jQuery(document).height() - 162);
    } else {
      jQuery(".content-frame-right,.content-frame-left").height(jQuery(".content-frame-body").height());
    }

    jQuery(".content-frame-left").show();
    jQuery(".content-frame-right").show();
  } else {
    jQuery(".content-frame-body").height(jQuery(".content-frame").height() - 80);

    if (jQuery(".page-sidebar").hasClass("scroll"))
      jQuery(".page-sidebar").css("height", "");
  }

  if (jQuery(window).width() < 1200) {
    if (jQuery("body").hasClass("page-container-boxed")) {
      jQuery("body").removeClass("page-container-boxed").data("boxed", "1");
    }
  } else {
    if (jQuery("body").data("boxed") === "1") {
      jQuery("body").addClass("page-container-boxed").data("boxed", "");
    }
  }
}


/* X-NAVIGATION CONTROL FUNCTIONS */
export function x_navigation_onresize() {

  var inner_port = window.innerWidth || jQuery(document).width();

  if (inner_port < 1025) {
    jQuery(".page-sidebar .x-navigation").removeClass("x-navigation-minimized");
    jQuery(".page-container").removeClass("page-container-wide");
    jQuery(".page-sidebar .x-navigation li.active").removeClass("active");


    jQuery(".x-navigation-horizontal").each(function () {
      if (!jQuery(this).hasClass("x-navigation-panel")) {
        jQuery(".x-navigation-horizontal").addClass("x-navigation-h-holder").removeClass("x-navigation-horizontal");
      }
    });


  } else {
    if (jQuery(".page-navigation-toggled").length > 0) {
      x_navigation_minimize("close");
    }

    jQuery(".x-navigation-h-holder").addClass("x-navigation-horizontal").removeClass("x-navigation-h-holder");
  }

}

export function x_navigation_minimize(action: any) {

  if (action == 'open') {
    jQuery(".page-container").removeClass("page-container-wide");
    jQuery(".page-sidebar .x-navigation").removeClass("x-navigation-minimized");
    jQuery(".x-navigation-minimize").find(".fa").removeClass("fa-indent").addClass("fa-dedent");
    jQuery(".page-sidebar.scroll").mCustomScrollbar("update");
  }

  if (action == 'close') {
    jQuery(".page-container").addClass("page-container-wide");
    jQuery(".page-sidebar .x-navigation").addClass("x-navigation-minimized");
    jQuery(".x-navigation-minimize").find(".fa").removeClass("fa-dedent").addClass("fa-indent");
    jQuery(".page-sidebar.scroll").mCustomScrollbar("disable", true);
  }

  jQuery(".x-navigation li.active").removeClass("active");

}

export function x_navigation() {

  jQuery(".x-navigation-control").click(function () {
    jQuery(this).parents(".x-navigation").toggleClass("x-navigation-open");
    onresize(null);
    return false;
  });

  if (jQuery(".page-navigation-toggled").length > 0) {
    x_navigation_minimize("close");
  }
}
/* EOF X-NAVIGATION CONTROL FUNCTIONS */
