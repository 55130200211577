import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable()  
export class Interceptor implements HttpInterceptor {  
    constructor(private router: Router, private storageService: StorageService, private spinner: NgxSpinnerService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      var token = this.storageService.getData('EncryptedToken');
      const modified = req.clone({setHeaders: {'AuthorizationToken': token ? token : ""}});
      return next.handle(modified).pipe(
          map(resp => { 
              if(resp.type!=0){
                if (resp instanceof HttpResponse) {  
                    if (resp.body.Token) {
                        this.storageService.setData('EncryptedToken', resp.body.Token);
                    }
                }  
              }
              return resp;  
          }),catchError(err => {  
              console.log(err);  
              if (err instanceof HttpResponse)  
              {  
                  console.log(err.status);  
                  console.log(err.body);  
              }  
              this.spinner.hide();
              this.router.navigate(['/login']);
              return Observable.throw(err);  
          }));  
    }  
} 