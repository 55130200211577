import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditorService } from './creditor.service';
import { formatDate } from '@angular/common'
import { StorageService } from '../common/storage.service';
import { CommonService } from '../common/common.service';
import { ContactService } from '../contact/contact.service';
import { CompanyService } from '../contact/company.service';
import * as _ from 'underscore';
import { startWith, map } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarService } from '../calendar/calendar.service';

declare var swal: any;
declare var $: any;

@Component({
    selector: 'creditor',
    templateUrl: 'creditor.template.html',
    styleUrls: ['../../../assets/css/mat-table.css']
})

export class CreditorComponent implements OnInit {

    @ViewChild('overviewTab') tabOverview: any;

    copyPOC = {
        poc: 0,
        pocSecured: 0,
        pocAmount: 0,
        pocUnsecured: 0
    };
    poc = {
        poc: 0,
        pocSecured: 0,
        pocAmount: 0,
        pocUnsecured: 0
    };
    debtorId = "";

    contactsCollapsed = false;

    editPOC: boolean = false;

    POCDocumentList = [];
    filterPOCDocumentList = [];
    POCDocumentFile = "";
    labelPOCDocumentFile = "Upload File";
    currentPOC: any = "";

    stateList = [];
    parameterDebtorId: any = "";

    claimsList: any = [];
    claimForm: FormGroup;
    isClaimSubmitted = false;
    editClaimId = "";
    splitedClaim: any = null;
    isSplitClaim = false;
    creditorDebtor = [];
    filteredCreditorDebtor = [];
    selectedClaim = {};

    filteredCreditorContact = [];
    selectedDebtors = [];
    ContactList = [];
    systemContactList = [];
    companyList = [];
    filteredCreditorCompanyList: Observable<string[]>;
    selectedContact: any = {};

    bidsList: any = [];
    selectedClaimList = [];
    isBidSubmitted = false;
    bidForm: FormGroup;
    uploadedBid = "Upload File";
    bidFile: any = null;
    isBidFileChanged: boolean = false;

    creditorForm: FormGroup;
    isCreditorSubmitted: boolean = false;
    isCreditorCommitteeChnaged: boolean = false;
    masterDebtors = [];
    debtors = [];
    isCreditorChange: boolean = false;

    creditorId: any = "";

    creditorNotes = [];
    filteredCreditorNotes = [];
    selectedCreditorNotification = [];

    beforeDaysList = [];

    notesForm: FormGroup;
    isNotesSubmitted: boolean = false;

    documentList = [];
    filteredDocumentList = [];
    document = {
        "File": null,
        "Name": "",
        "Description": ""
    };
    creditorDocument: any = null;
    uploadDocument = "Upload File"

    contactShowMore = false;
    myControl: FormControl = new FormControl();
    isEdit = false;

    report: any = {
        Total: 0,
        Secured: 0,
        Amount: 0,
        Unsecured: 0
    }
    totalValue: 0;

    claimStatusList: any = [];

    contactTypeList: any = [];
    BidReports: any = [];
    masterBidReports: any = [];

    debtorEvents = [];
    filteredDebtorEvents = [];


    @ViewChild('notesPaginator') notesPaginator: MatPaginator;
    @ViewChild('notesSort') notesSort: MatSort;
    notesDataColumns: string[] = ['NoteDate', 'Debtor', 'ClaimStatus', 'Note', 'EventDate', 'EventNote', 'Contact', 'User', 'Action'];
    notesDataSource: MatTableDataSource<any>;
    searchModel = "";

    @ViewChild('claimsSort') claimsSort: MatSort;
    cliamsDataColumns: string[] = ['ClaimNo', 'ClaimDebtor', 'Amount', 'ClaimType', 'ClaimStatus', 'Split', 'Bid', 'Edit'];
    claimsDataSource: MatTableDataSource<any>;

    @ViewChild('bidsSort') bidsSort: MatSort;
    bidsDataColumns: string[] = ['BidDate', 'ClaimNo', 'ClaimType', 'TotalAmount', 'BidAmount', 'BidPercent', 'FileName', 'BidStatus', 'TradeDate', 'SettleDate', 'TradeDateId', 'User', 'Edit'];
    bidsDataSource: MatTableDataSource<any>;

    @ViewChild('documentSort') documentSort: MatSort;
    documentColumns: string[] = ['OriginalFileName', 'Name', 'InsertionDate', 'Description', 'UserName'];
    documentDataSource: MatTableDataSource<any>;

    @ViewChild('pocDocumentSort') pocDocumentSort: MatSort;
    pocDocumentColumns: string[] = ['InsertionDate', 'OriginalFileName', 'UserName', 'Action'];
    pocDocumentDataSource: MatTableDataSource<any>;

    constructor(private router: Router, private route: ActivatedRoute, private creditorService: CreditorService, private storageService: StorageService
        , private commonService: CommonService, private contactService: ContactService, private companyService: CompanyService
        , private pageHelper: PageHelper, private spinner: NgxSpinnerService, private calendarSerivce: CalendarService) { }

    ngOnInit() {
        $('#menuCreditors').addClass('active');

        this.creditorForm = new FormGroup({
            CreditorId: new FormControl(""),
            CompanyId: new FormControl(""),
            Company: new FormControl("", [Validators.required]),
            DebtorId: new FormControl("", [Validators.required]),
            KeyPersonId: new FormControl(""),
            KeyPerson: new FormControl(""),
            Email: new FormControl(""),
            PhoneNumber: new FormControl(""),
            Address: new FormControl(""),
            City: new FormControl(""),
            State: new FormControl(""),
            Zip: new FormControl(""),
            IsRestricted: new FormControl(false),
            DoNotCall: new FormControl(false),
            CUD: new FormControl(false),
            CUDContigent: new FormControl(false),
            CUDUnliquidated: new FormControl(false),
            CUDDisputed: new FormControl(false),
            CreditorCommittee: new FormControl(""),
            IsActive: new FormControl(false)
        });

        this.creditorForm.valueChanges.subscribe(() => {
            this.isCreditorChange = true;
            if (this.isCreditorSubmitted || this.isEdit) {
                Object.keys(this.creditorForm.controls).forEach(key => {
                    if ($('#creditor' + key))
                        $('#creditor' + key).removeClass('validationError');
                });

                if (this.creditorForm.invalid) {
                    Object.keys(this.creditorForm.controls).forEach(key => {
                        if (!this.creditorForm.controls[key].valid) {
                            if ($('#creditor' + key))
                                $('#creditor' + key).addClass('validationError');
                        }
                    });
                }
            }
        });


        this.notesForm = new FormGroup({
            CreditorNoteId: new FormControl(""),
            CreditorId: new FormControl(""),
            ContactId: new FormControl(""),
            ClaimStatusId: new FormControl(""),
            ClaimStatus: new FormControl(""),
            Note: new FormControl("", [Validators.required]),
            NoteDate: new FormControl(null, [Validators.required]),
            EventNote: new FormControl(""),
            EventDate: new FormControl(""),
            TimeHH: new FormControl(""),
            TimeMM: new FormControl(""),
            TimeAP: new FormControl(""),

        });

        this.notesForm.valueChanges.subscribe(() => {
            if (this.isNotesSubmitted) {
                Object.keys(this.notesForm.controls).forEach(key => {
                    if ($('#notes' + key))
                        $('#notes' + key).removeClass('validationError');
                });

                if (this.notesForm.invalid) {
                    Object.keys(this.notesForm.controls).forEach(key => {
                        if (!this.notesForm.controls[key].valid) {
                            if ($('#notes' + key))
                                $('#notes' + key).addClass('validationError');
                        }
                    });
                }
            }
        });

        this.claimForm = new FormGroup({
            ClaimNo: new FormControl("", [Validators.required]),
            Debtor: new FormControl("", [Validators.required]),
            Amount: new FormControl("", [Validators.required]),
            ClaimType: new FormControl("", [Validators.required]),
            ParentClaimId: new FormControl("")
        });

        this.claimForm.valueChanges.subscribe(() => {
            if (this.isClaimSubmitted) {
                Object.keys(this.claimForm.controls).forEach(key => {
                    if ($('#claims' + key))
                        $('#claims' + key).removeClass('validationError');
                });

                if (this.claimForm.invalid) {
                    Object.keys(this.claimForm.controls).forEach(key => {
                        if (!this.claimForm.controls[key].valid) {
                            if ($('#claims' + key))
                                $('#claims' + key).addClass('validationError');
                        }
                    });
                }
            }
        });

        this.bidForm = new FormGroup({
            BidId: new FormControl(""),
            BidDate: new FormControl("", [Validators.required]),
            BidAmount: new FormControl(0),
            ClaimType: new FormControl("Blended"),
            BidStatus: new FormControl("Open", [Validators.required]),
            FileId: new FormControl(""),
            IsActive: new FormControl(true),
            Note: new FormControl(""),
            ContactId: new FormControl(""),
            EventNote: new FormControl(""),
            EventDate: new FormControl(""),
            TimeHH: new FormControl(""),
            TimeMM: new FormControl(""),
            TimeAP: new FormControl(""),
        });

        this.bidForm.valueChanges.subscribe(() => {
            if (this.isBidSubmitted) {
                Object.keys(this.bidForm.controls).forEach(key => {
                    if ($('#bid' + key))
                        $('#bid' + key).removeClass('validationError');
                });

                if (this.bidForm.invalid) {
                    Object.keys(this.bidForm.controls).forEach(key => {
                        if (!this.bidForm.controls[key].valid) {
                            if ($('#bid' + key))
                                $('#bid' + key).addClass('validationError');
                        }
                    });
                }
            }
        });

        if (this.storageService.getData('EncryptedToken')) {
            this.getDebtors();
            this.getCompanyList();

            if (this.route.snapshot.queryParams["DebtorId"]) {
                this.parameterDebtorId = this.route.snapshot.queryParams["DebtorId"];
            }

            if (this.route.snapshot.queryParams["CreditorId"]) {
                this.isEdit = true;
                this.creditorForm.controls["CreditorId"].setValue(this.route.snapshot.queryParams["CreditorId"]);
                this.creditorId = this.route.snapshot.queryParams["CreditorId"];
                this.getCreditorDetail();
                this.getContactList();
            }
            else {
                if (this.parameterDebtorId) {
                    this.debtorId = this.parameterDebtorId;
                }
            }

            this.commonService.getLookup("ClaimStatus")
                .subscribe(response => {
                    if (response.Status == 'Success') {
                        this.claimStatusList = response.data;
                    }
                },
                    error => {
                        this.storageService.clear();
                        this.router.navigate(['/login']);
                    }
                );

            this.commonService.getLookup("ContactType")
                .subscribe(response => {
                    if (response.Status == 'Success') {
                        this.contactTypeList = response.data;
                    }
                },
                    error => {
                        this.storageService.clear();
                        this.router.navigate(['/login']);
                    });

            this.commonService.getStates().subscribe(
                response => {
                    if (response.Status == 'Success') {
                        this.stateList = response.data;
                    }
                },
                error => {
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
        }
        else
            this.router.navigate(['/login']);

        for (var _i = 0; _i <= 100; _i++) {
            this.beforeDaysList.push(_i.toString());
        }
    }

    getContactList() {
        this.contactService.search(false).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.systemContactList = response.data;
                }
            },
            error => {
                this.storageService.clear();
                this.router.navigate(['/login']);
            });
    }

    getCompanyList(showAll: boolean = false) {
        this.companyService.getList(showAll).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.companyList = response.data;
                    this.filteredCreditorCompanyList = this.creditorForm.get("Company").valueChanges.pipe(
                        startWith(''),
                        map(value => this._filterCreditorCompany(value))
                    );
                }
            },
            error => {
                this.storageService.clear();
                this.router.navigate(['/login']);
            });
    }

    getCreditorContact() {
        this.spinner.show();
        this.creditorService.getCreditorContacts(this.creditorForm.controls["CreditorId"].value).subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.ContactList = response.data;
                    this.ContactList.forEach(element => {
                        element.SelectedContact = element.ContactId;
                        element.SelectedCompany = element.CompanyId;
                        element.ContactTypeList = this.contactTypeList;
                        element.ContactList = this.systemContactList;
                        element.CompanyList = this.companyList;
                        if (!element.ContactId && !element.Contact) {
                            element.isEdit = true;
                        }
                    });
                    var self = this;
                    if (self.debtorId) {
                        var filterCreditorContactList = _.filter(this.ContactList, function (contact) {
                            return contact.DebtorId == self.debtorId || !contact.DebtorId;
                        });
                        this.filteredCreditorContact = filterCreditorContactList;
                    }
                    else {
                        this.filteredCreditorContact = JSON.parse(JSON.stringify(this.ContactList));
                    }
                }
            },
            error => {
                this.spinner.hide();
                this.storageService.clear();
                this.router.navigate(['/login']);
            });
    }

    getCreditorDetail() {
        this.isCreditorChange = false;
        if (this.creditorForm.controls["CreditorId"].value) {
            this.spinner.show();
            this.creditorService.getDetails(this.creditorForm.controls["CreditorId"].value)
                .subscribe(response => {
                    this.spinner.hide();
                    if (response.Status == 'Success') {
                        this.setCreditorFormValue(response.data);
                        this.getCreditorDebtors(this.creditorForm.controls["CreditorId"].value);
                        this.creditorNotes = response.data.NotesList.map(x => Object.assign({}, x));
                        this.masterBidReports = response.data.ClaimsReports.map(x => Object.assign({}, x));
                        var self = this;
                        var filteredNotesList = _.filter(this.creditorNotes, function (notes) {
                            return notes.DebtorId == self.debtorId || !notes.DebtorId;
                        });

                        this.debtorEvents = response.data.CalendarDates.map(x => Object.assign({}, x));

                        var selectedDebtorEventList = _.filter(this.debtorEvents, function (events) {
                            return events.DebtorId == self.debtorId;
                        });

                        if (selectedDebtorEventList && selectedDebtorEventList.length > 0) {
                            this.filteredDebtorEvents = selectedDebtorEventList[0].CalendarDateList;

                            this.filteredDebtorEvents.forEach(element => {
                                element.isExpanded = false;
                            });
                        }
                        else
                            this.filteredDebtorEvents = [];
                        //this.filteredDebtorEvents = _.filter(this.debtorEvents, function (events) {
                        //  return events.DebtorId == self.debtorId || !events.DebtorId;
                        //});

                        this.ContactList = response.data.CreditorContactList.map(x => Object.assign({}, x));
                        this.ContactList.forEach(element => {
                            element.SelectedContact = element.ContactId;
                            element.SelectedCompany = element.CompanyId;
                            element.ContactTypeList = this.contactTypeList;
                            element.ContactList = this.systemContactList;
                            element.CompanyList = this.companyList;
                            if (!element.ContactId && !element.Contact) {
                                element.isEdit = true;
                            }
                        });

                        var filterCreditorContactList = _.filter(this.ContactList, function (contact) {
                            return contact.DebtorId == self.debtorId || !contact.DebtorId;
                        });

                        this.filteredCreditorContact = filterCreditorContactList;

                        this.filteredCreditorNotes = filteredNotesList;
                        this.notesDataSource = new MatTableDataSource(this.filteredCreditorNotes);
                        this.notesDataSource.sort = this.notesSort;
                        this.notesDataSource.paginator = this.notesPaginator;
                        this.searchModel = "";

                        this.selectedCreditorNotification = [];

                        if (response.data.DocumentList) {
                            this.documentList = response.data.DocumentList;
                            if (this.debtorId) {
                                var filteredList = _.filter(this.documentList, function (document) {
                                    return document.DebtorId == self.debtorId;
                                });
                                if (filteredList && filteredList.length > 0)
                                    this.filteredDocumentList = JSON.parse(JSON.stringify(filteredList));
                                else
                                    this.filteredDocumentList = [];
                            }
                            else
                                this.filteredDocumentList = [];
                        }
                        else {
                            this.documentList = [];
                            this.filteredDocumentList = [];
                        }

                        this.documentDataSource = new MatTableDataSource(this.filteredDocumentList);
                        this.documentDataSource.sort = this.documentSort;

                        if (response.data.POCDocumentList) {
                            this.POCDocumentList = response.data.POCDocumentList;

                            if (this.debtorId) {
                                var filteredList = _.filter(this.POCDocumentList, function (document) {
                                    return document.DebtorId == self.debtorId;
                                });
                                if (filteredList && filteredList.length > 0) {
                                    this.filterPOCDocumentList = JSON.parse(JSON.stringify(filteredList));
                                    this.currentPOC = this.filterPOCDocumentList[0].FileURL;
                                }
                                else {
                                    this.filterPOCDocumentList = [];
                                    this.currentPOC = null;
                                }

                            }
                            else {
                                this.filterPOCDocumentList = [];
                                this.currentPOC = null;
                            }


                        }
                        else {
                            this.POCDocumentList = [];
                            this.filterPOCDocumentList = [];
                            this.currentPOC = null;
                        }


                        this.pocDocumentDataSource = new MatTableDataSource(this.filterPOCDocumentList);
                        this.pocDocumentDataSource.sort = this.pocDocumentSort;



                        if (response.data.ClaimsList) {
                            this.claimsList = response.data.ClaimsList;
                            this.claimsList.forEach(element => {
                                element.Selected = false;
                            });
                        }
                        else
                            this.claimsList = [];

                        this.claimsDataSource = new MatTableDataSource(this.claimsList);
                        this.claimsDataSource.sort = this.claimsSort;

                        if (response.data.BidList)
                            this.bidsList = response.data.BidList;
                        else
                            this.bidsList = [];

                        this.bidsDataSource = new MatTableDataSource(this.bidsList);
                        this.bidsDataSource.sort = this.bidsSort;

                        if (response.data.SelectedDebtors)
                            this.selectedDebtors = response.data.SelectedDebtors;
                        else
                            this.selectedDebtors = [];

                        this.debtors = _.filter(this.debtors, function (data) {
                            var response = _.find(self.selectedDebtors, function (res) {
                                return res.DebtorId == data.DebtorId
                            });
                            if (response)
                                return data;
                        });

                        var filteredBidReport = _.find(this.masterBidReports, function (bidReport) {
                            return bidReport.DebtorId == self.debtorId;
                        });


                        this.BidReports = filteredBidReport.ClaimReportList.map(x => Object.assign({}, x));
                        //this.report.Total = _.find(this.BidReports, function (report) {
                        //    return report.Type == "Blended"
                        //}).Total;

                        this.report.Secured = _.find(this.BidReports, function (report) {
                            return report.Type == "Secured"
                        }).Total;

                        this.report.Amount = _.find(this.BidReports, function (report) {
                            return report.Type == "503b9"
                        }).Total;

                        this.report.Unsecured = _.find(this.BidReports, function (report) {
                            return report.Type == "Unsecured"
                        }).Total;

                        this.report.Total = this.report.Secured + this.report.Amount + this.report.Unsecured;

                        this.isCreditorSubmitted = false;
                        this.isCreditorChange = false;
                        this.isCreditorCommitteeChnaged = false;

                        this.resizePage();

                        if (this.parameterDebtorId) {
                            this.debtorId = this.parameterDebtorId;
                            this.assignDebtor();
                        }
                    }
                    else {
                        swal('Error', response.Message, 'error');
                    }
                },
                    error => {
                        this.spinner.hide();
                        this.storageService.clear();
                        this.router.navigate(['/login']);
                    });
        }
    }

    getDebtors() {
        this.creditorService.getDebtors(true)
            .subscribe(response => {
                if (response.Status == 'Success') {
                    this.masterDebtors = response.data;
                    this.debtors = response.data.map(x => Object.assign({}, x));
                    if (this.selectedDebtors && this.selectedDebtors.length > 0) {
                        var self = this;
                        this.debtors = _.filter(this.debtors, function (data) {
                            var response = _.find(self.selectedDebtors, function (res) {
                                return res.DebtorId == data.DebtorId
                            });
                            if (response)
                                return data;
                        });
                    }
                }
            },
                error => {
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    getCreditorDebtors(CreditorId) {
        this.creditorService.getCreditorDebtor(CreditorId)
            .subscribe(response => {
                if (response.Status == 'Success') {
                    this.creditorDebtor = response.data.map(x => Object.assign({}, x));
                    var self = this;
                    var filteredList = _.filter(this.creditorDebtor, function (debtor) {
                        return debtor.PrimaryDebtorId == self.debtorId;
                    });
                    this.filteredCreditorDebtor = filteredList;
                }
            },
                error => {
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                }
            );
    }

    convertDate(str) {
        if (!str)
            return null;

        var date = new Date(str),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [month, day, date.getFullYear()].join("/");
    }

    restrictInput(event: any) {
        event.preventDefault();
    }

    restrictDelete(event: any) {
        var key = event.keyCode || event.charCode;

        if (key == 8 || key == 46)
            return false;
    }

    contactToggleShow() {
        if (this.contactShowMore == false)
            this.contactShowMore = true;
        else
            this.contactShowMore = false;
        this.resizePage();
    }

    praseTwoDecimalFloat(event) {
        var value = parseFloat(event.target.value).toFixed(2);
        if (value == 'NaN') {
            value = "";
        }
        if (value)
            event.target.value = value;
        else
            event.target.value = "";
    }

    praseNoDecimalFloat(event) {
        var value = parseFloat(event.target.value).toFixed(0);
        if (value == 'NaN') {
            value = "";
        }
        if (value)
            event.target.value = value;
        else
            event.target.value = "";
    }

    creditorCommitteeChnaged() {
        this.isCreditorCommitteeChnaged = !this.isCreditorCommitteeChnaged;
    }

    //#region Creditor

    saveCreditor() {
        Object.keys(this.creditorForm.controls).forEach(key => {
            if ($('#creditor' + key))
                $('#creditor' + key).removeClass('validationError');
        });
        this.isCreditorSubmitted = true;
        if (!this.creditorForm.invalid) {
            this.spinner.show();
            var request = this.getCreditorRequestObject();
            this.creditorService.save(request)
                .subscribe(response => {
                    this.spinner.hide();

                    if (response.Status == 'Success') {
                        this.isCreditorSubmitted = false;
                        this.isCreditorChange = false;

                        swal('Success', response.Message, 'success');
                        if (!this.isEdit) {
                            this.router.navigate(['/creditor'], { queryParams: { CreditorId: response.Data } });
                            window.location.reload();
                        }
                        else {
                            this.getCreditorDetail();
                        }
                    }
                    else {
                        swal('Error', response.Message, 'error');
                    }
                },
                    error => {
                        this.spinner.hide();
                        this.storageService.clear();
                        this.router.navigate(['/login']);
                    });
        }
        else {
            Object.keys(this.creditorForm.controls).forEach(key => {
                if (!this.creditorForm.controls[key].valid) {
                    if ($('#creditor' + key))
                        $('#creditor' + key).addClass('validationError');
                }
            });
        }
    }

    setCreditorFormValue(data) {
        Object.keys(this.creditorForm.controls).forEach(key => {
            if ($('#creditor' + key))
                $('#creditor' + key).removeClass('validationError');
        });

        this.isCreditorSubmitted = false;
        if (data) {
            this.creditorForm.setValue({
                CreditorId: data.CreditorId,
                CompanyId: data.CompanyId,
                Company: data.Company,
                DebtorId: data.DebtorId,
                KeyPersonId: data.KeyPersonId,
                KeyPerson: data.KeyPerson,
                Email: data.Email,
                PhoneNumber: data.PhoneNumber,
                Address: data.Address,
                City: data.City,
                State: data.State,
                Zip: data.Zip,
                IsRestricted: data.IsRestricted,
                DoNotCall: data.DoNotCall,
                CUD: data.CUD,
                CUDContigent: data.CUDContigent,
                CUDUnliquidated: data.CUDUnliquidated,
                CUDDisputed: data.CUDDisputed,
                CreditorCommittee: data.CreditorCommittee,
                IsActive: data.IsActive
            });

            this.poc.poc = data.POC;
            this.poc.pocAmount = data.POCAmount;
            this.poc.pocSecured = data.POCSecured;
            this.poc.pocUnsecured = data.POCUnsecured;

            this.debtorId = data.DebtorId;
            this.isCreditorChange = false;
        }
        else {
            this.creditorForm.setValue({
                CreditorId: "",
                CompanyId: "",
                Company: "",
                DebtorId: "",
                KeyPersonId: "",
                KeyPerson: "",
                Email: "",
                PhoneNumber: "",
                Address: "",
                City: "",
                State: "",
                Zip: "",
                IsRestricted: false,
                DoNotCall: false,
                CUD: false,
                CUDContigent: false,
                CUDUnliquidated: false,
                CUDDisputed: false,
                CreditorCommittee: false,
                IsActive: true
            });

            this.poc.poc = 0;
            this.poc.pocAmount = 0;
            this.poc.pocSecured = 0;
            this.poc.pocUnsecured = 0;
        }
    }

    getCreditorRequestObject() {
        return {
            'CreditorId': this.creditorForm.controls["CreditorId"].value,
            'CompanyId': this.creditorForm.controls["CompanyId"].value,
            'Company': this.creditorForm.controls["Company"].value,
            'DebtorId': this.creditorForm.controls["DebtorId"].value,
            'KeyPerson': this.creditorForm.controls["KeyPerson"].value,
            'KeyPersonId': this.creditorForm.controls["KeyPersonId"].value,
            'Email': this.creditorForm.controls["Email"].value,
            'PhoneNumber': this.creditorForm.controls["PhoneNumber"].value,
            'Address': this.creditorForm.controls["Address"].value,
            'City': this.creditorForm.controls["City"].value,
            'State': this.creditorForm.controls["State"].value,
            'Zip': this.creditorForm.controls["Zip"].value,
            'IsRestricted': this.creditorForm.controls["IsRestricted"].value,
            'DoNotCall': this.creditorForm.controls["DoNotCall"].value,
            'CUD': this.creditorForm.controls["CUD"].value,
            'CUDContigent': this.creditorForm.controls["CUDContigent"].value,
            'CUDUnliquidated': this.creditorForm.controls["CUDUnliquidated"].value,
            'CUDDisputed': this.creditorForm.controls["CUDDisputed"].value,
            'CreditorCommittee': this.creditorForm.controls["CreditorCommittee"].value,
            'IsActive': this.creditorForm.controls["IsActive"].value,
            'IsCreditorCommitteeChnaged': this.isCreditorCommitteeChnaged,
            'POCSecured': this.poc.pocSecured,
            'POCAmount': this.poc.pocAmount,
            'POCUnsecured': this.poc.pocUnsecured
        }
    }

    assignDebtor() {
        this.BidReports = [];
        this.report = {
            Total: 0,
            Secured: 0,
            Amount: 0,
            Unsecured: 0
        }

        if (this.isEdit) {
            var isCreditorChanged = false;
            if (this.isCreditorChange)
                isCreditorChanged = true;

            this.claimsList.forEach(element => {
                element.Selected = false;
            });
            if (this.debtorId) {
                var self = this;
                var result = _.find(this.selectedDebtors, function (data) {
                    return data.DebtorId == self.debtorId
                });
                if (result) {
                    var data = JSON.parse(JSON.stringify(result));
                    this.creditorForm.controls["DebtorId"].setValue(data.DebtorId);
                    this.creditorForm.controls["KeyPerson"].setValue(data.KeyPerson);
                    this.creditorForm.controls["KeyPersonId"].setValue(data.KeyPersonId);
                    this.creditorForm.controls["PhoneNumber"].setValue(data.PhoneNumber);
                    this.creditorForm.controls["Email"].setValue(data.Email);
                    this.creditorForm.controls["CreditorCommittee"].setValue(data.CreditorCommittee);

                    this.poc.poc = data.POC;
                    this.poc.pocAmount = data.POCAmount;
                    this.poc.pocSecured = data.POCSecured;
                    this.poc.pocUnsecured = data.POCUnsecured;

                    var self = this;
                    var filteredNotesList = _.filter(this.creditorNotes, function (notes) {
                        return notes.DebtorId == self.debtorId || !notes.DebtorId;
                    });
                    var filteredCreditorContactList = _.filter(this.ContactList, function (contact) {
                        return contact.DebtorId == self.debtorId || !contact.DebtorId;
                    });

                    var selectedDebtorEventList = _.filter(this.debtorEvents, function (events) {
                        return events.DebtorId == self.debtorId;
                    });

                    if (selectedDebtorEventList && selectedDebtorEventList.length > 0) {
                        this.filteredDebtorEvents = selectedDebtorEventList[0].CalendarDateList;

                        this.filteredDebtorEvents.forEach(element => {
                            element.isExpanded = false;
                        });
                    }
                    else
                        this.filteredDebtorEvents = [];



                    this.filteredCreditorContact = filteredCreditorContactList;

                    this.filteredCreditorNotes = filteredNotesList;
                    this.notesDataSource = new MatTableDataSource(this.filteredCreditorNotes);
                    this.notesDataSource.sort = this.notesSort;
                    this.notesDataSource.paginator = this.notesPaginator;
                    this.searchModel = "";

                    var filteredDocList = _.filter(this.documentList, function (document) {
                        return document.DebtorId == self.debtorId;
                    });
                    if (filteredDocList && filteredDocList.length > 0)
                        this.filteredDocumentList = JSON.parse(JSON.stringify(filteredDocList));
                    else
                        this.filteredDocumentList = [];

                    this.documentDataSource = new MatTableDataSource(this.filteredDocumentList);
                    this.documentDataSource.sort = this.documentSort;

                    var filteredPOCDocList = _.filter(this.POCDocumentList, function (document) {
                        return document.DebtorId == self.debtorId;
                    });

                    if (filteredPOCDocList && filteredPOCDocList.length > 0) {
                        this.filterPOCDocumentList = JSON.parse(JSON.stringify(filteredPOCDocList));
                        this.currentPOC = this.filterPOCDocumentList[0].FileURL;
                    }
                    else {
                        this.filterPOCDocumentList = [];
                        this.currentPOC = null;
                    }

                    this.pocDocumentDataSource = new MatTableDataSource(this.filterPOCDocumentList);
                    this.pocDocumentDataSource.sort = this.pocDocumentSort;

                    var filteredList = _.filter(this.creditorDebtor, function (debtor) {
                        return debtor.PrimaryDebtorId == self.debtorId;
                    });
                    this.filteredCreditorDebtor = filteredList;

                    this.BidReports = [];

                    var filteredBidReport = _.find(this.masterBidReports, function (bidReport) {
                        return bidReport.DebtorId == self.debtorId;
                    });

                    this.BidReports = filteredBidReport.ClaimReportList.map(x => Object.assign({}, x));
                    this.report.Secured = _.find(this.BidReports, function (report) {
                        return report.Type == "Secured"
                    }).Total;

                    this.report.Amount = _.find(this.BidReports, function (report) {
                        return report.Type == "503b9"
                    }).Total;

                    this.report.Unsecured = _.find(this.BidReports, function (report) {
                        return report.Type == "Unsecured"
                    }).Total;

                    this.report.Total = this.report.Secured + this.report.Amount + this.report.Unsecured;

                    this.resizePage();
                }
                else {
                    this.creditorForm.controls["DebtorId"].setValue(this.debtorId);
                    this.creditorForm.controls["KeyPerson"].setValue("");
                    this.creditorForm.controls["KeyPersonId"].setValue("");
                    this.creditorForm.controls["PhoneNumber"].setValue("");
                    this.creditorForm.controls["Email"].setValue("");
                    this.creditorForm.controls["CreditorCommittee"].setValue(false);

                    this.filteredCreditorNotes = [];
                    this.notesDataSource = new MatTableDataSource(this.filteredCreditorNotes);
                    this.notesDataSource.sort = this.notesSort;
                    this.notesDataSource.paginator = this.notesPaginator;
                    this.searchModel = "";

                    this.filteredDocumentList = [];
                    this.documentDataSource = new MatTableDataSource(this.filteredDocumentList);
                    this.documentDataSource.sort = this.documentSort;

                    this.filterPOCDocumentList = [];
                    this.pocDocumentDataSource = new MatTableDataSource(this.filterPOCDocumentList);
                    this.pocDocumentDataSource.sort = this.pocDocumentSort;
                    this.currentPOC = null;

                    this.filteredCreditorDebtor = [];
                    this.poc.poc = 0;
                    this.poc.pocAmount = 0;
                    this.poc.pocSecured = 0;
                    this.poc.pocUnsecured = 0;

                    this.resizePage();
                }
            }
            else {
                this.tabOverview.nativeElement.click();
                this.creditorForm.controls["DebtorId"].setValue("");
                this.creditorForm.controls["KeyPerson"].setValue("");
                this.creditorForm.controls["KeyPersonId"].setValue("");
                this.creditorForm.controls["PhoneNumber"].setValue("");
                this.creditorForm.controls["Email"].setValue("");
                this.creditorForm.controls["CreditorCommittee"].setValue(false);

                this.poc.poc = 0;
                this.poc.pocAmount = 0;
                this.poc.pocSecured = 0;
                this.poc.pocUnsecured = 0;

                this.filteredDebtorEvents = [];
                this.filteredCreditorNotes = JSON.parse(JSON.stringify(this.creditorNotes));
                this.notesDataSource = new MatTableDataSource(this.filteredCreditorNotes);
                this.notesDataSource.sort = this.notesSort;
                this.notesDataSource.paginator = this.notesPaginator;
                this.searchModel = "";

                this.filteredDocumentList = [];
                this.documentDataSource = new MatTableDataSource(this.filteredDocumentList);
                this.documentDataSource.sort = this.documentSort;
                this.filteredCreditorDebtor = [];
                this.filteredCreditorContact = this.ContactList;

                this.resizePage();
            }

            this.isCreditorChange = isCreditorChanged;
        }
        else
            this.creditorForm.controls["DebtorId"].setValue(this.debtorId);
    }

    _filterCreditorCompany(value) {
        if (value) {
            if (this.creditorForm.get('CompanyId').value) {
                this.creditorForm.get('CompanyId').setValue('');
            }

            if (!value)
                value = "";

            const filterValue = value.toLowerCase();
            return this.companyList.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
        }
        else
            return this.companyList;
    }

    selectCreditorCompany(data) {
        if (data.option.value) {
            var result = _.find(this.companyList, function (company) {
                return company.CompanyId == data.option.value;
            });
            if (result) {
                this.creditorForm.get("Company").setValue(result.Name);
                this.creditorForm.get("CompanyId").setValue(result.CompanyId);
            }
        }
        else {
            this.creditorForm.get("CompanyId").setValue(result.Name);
            this.creditorForm.get("CompanyId").setValue(result.CompanyId);
        }
    }

    //#endregion

    //#region Contact

    addContact(contact) {
        // if (!this.filteredCreditorContact)
        //     this.filteredCreditorContact = [];

        // this.filteredCreditorContact.push({
        //     'CreditorContactId': "",
        //     'ContactTypeId': this.contactTypeList[0].LookupId,
        //     'ContactType': this.contactTypeList[0].LookupValue,
        //     'ContactTypeList': this.contactTypeList,
        //     'ContactList': this.systemContactList,
        //     'CreditorId': this.creditorForm.controls["CreditorId"].value,
        //     "ContactId": "",
        //     'DebtorId': this.creditorForm.controls["DebtorId"].value,
        //     "Contact": "",
        //     "SelectedContact": null,
        //     "CompanyId": "",
        //     "Company": "",
        //     "CompanyList": this.companyList,
        //     "SelectedCompany": null,
        //     "Phone": "",
        //     "Email": "",
        //     'IsActive': true,
        //     'isEdit': true
        // });


        if (contact) {
            this.selectedContact = {};
            this.copyContact(contact, this.selectedContact);
            //this.selectedContact.ContactTypeList = this.filterContactType(this.selectedContact.ContactType);
            //this.selectedContact.ContactType = this.contactTypeList[0].LookupValue;
            //this.selectedContact.ContactTypeId = this.contactTypeList[0].LookupId;
        }
        else {
            this.selectedContact = {
                'CreditorContactId': "",
                'CaseListingContactId': "",
                'ContactTypeId': "", //this.contactTypeList[0].LookupId,
                'ContactType': this.contactTypeList[0].LookupValue,
                'ContactTypeList': this.contactTypeList,
                'ContactList': this.systemContactList,
                'CreditorId': this.creditorForm.controls["CreditorId"].value,
                "ContactId": "",
                'DebtorId': this.debtorId,
                "Contact": "",
                "SelectedContact": null,
                "CompanyId": "",
                "Company": "",
                "CompanyList": this.companyList,
                "SelectedCompany": null,
                "Phone": "",
                "PhoneExt": "",
                "Email": "",
                'IsActive': true,
                'isEdit': true,
                "IsDefault": false,
                "Title": "",
                "FirstName": "",
                "LastName": "",
                "Position": "",
                "Address": "",
                "Address2": "",
                "City": "",
                "State": "",
                "ZipCode": ""
            };
        }
        $('#modal_add_contact').modal('show');
    }

    filterCompany(val) {
        if (val) {
            if (this.selectedContact.SelectedCompany) {
                this.selectedContact.SelectedCompany = null;
            }
            this.selectedContact.CompanyId = "";
            const filterValue = val.toLowerCase();
            return this.companyList.filter(company => company.Name.toLowerCase().startsWith(filterValue));
        }
        return this.companyList;
    }

    selectCompany(data) {
        var company = _.find(this.companyList, function (item) {
            return item.CompanyId == data.option.value;
        });

        if (company) {
            this.selectedContact.CompanyId = company.CompanyId;
            this.selectedContact.Company = company.Name;
            this.selectedContact.SelectedCompany = data.option.value;
        }
    }

    selectContactType(data) {
        if (data) {
            var contactType = _.find(this.contactTypeList, function (type) {
                return type.LookupId == data.option.value
            });

            if (contactType) {
                this.selectedContact.ContactType = contactType.LookupValue;
                this.selectedContact.ContactTypeId = data.option.value;
            }
        }
    }

    filterContactType(val) {
        if (val) {
            if (this.selectedContact.ContactTypeId)
                this.selectedContact.ContactTypeId = null;

            const filterValue = val.toLowerCase();
            return this.contactTypeList.filter(contactType => contactType.LookupValue.toLowerCase().startsWith(filterValue));
        }
        return this.contactTypeList;
    }

    filterContact(val) {
        if (val) {
            this.clearFilterContact();
            const filterValue = val.toLowerCase();
            return this.systemContactList.filter(contact => contact.Contact.toLowerCase().startsWith(filterValue));
        }
        else {
            this.clearFilterContact();
        }
        return this.systemContactList;
    }

    clearFilterContact() {
        if (this.selectedContact.SelectedContact) {
            this.selectedContact.SelectedContact = null;
            this.selectedContact.ContactId = "";
            this.selectedContact.CompanyId = "";
            this.selectedContact.Company = "";
            this.selectedContact.Phone = "";
            this.selectedContact.PhoneExt = "";
            this.selectedContact.Email = "";
            this.selectedContact.Title = "";
            this.selectedContact.FirstName = "";
            this.selectedContact.LastName = "";
            this.selectedContact.Position = "";
            this.selectedContact.Address = "";
            this.selectedContact.Address2 = "";
            this.selectedContact.City = "";
            this.selectedContact.State = "";
            this.selectedContact.ZipCode = "";
            this.selectedContact.SelectedCompany = "";
            this.selectedContact.CompanyId = "";
            this.selectedContact.Company = "";
        }
    }




    selectContact(data) {
        var contact = _.find(this.systemContactList, function (item) {
            return item.ContactId == data.option.value;
        });

        if (contact) {
            // this.selectedContact.Contact = contact.Name;
            // this.selectedContact.ContactId = contact.ContactId;
            // this.selectedContact.CompanyId = contact.CompanyId;
            // this.selectedContact.Company = contact.Company;
            // this.selectedContact.Phone = contact.Phone;
            // this.selectedContact.Email = contact.Email;
            // this.selectedContact.FirstName = contact.FirstName;
            // this.selectedContact.LastName = contact.LastName;
            contact.ContactTypeId = this.selectedContact.ContactTypeId;
            contact.ContactType = this.selectedContact.ContactType;
            contact.DebtorId = this.selectedContact.DebtorId;

            contact.IsDefault = this.selectedContact.IsDefault;
            contact.CreditorContactId = this.selectedContact.CreditorContactId;
            this.copyContact(contact, this.selectedContact);
            this.selectedContact.SelectedContact = data.option.value;
        }
    }

    editContact(contact) {
        this.selectedContact = {};

        this.copyContact(contact, this.selectedContact);
        //this.selectedContact.ContactTypeList = this.filterContactType(this.selectedContact.ContactType);
        var self = this;
        var contactType = _.find(this.contactTypeList, function (type) {
            return type.LookupId == contact.ContactTypeId
        });

        if (contactType) {
            this.selectedContact.ContactType = contactType.LookupValue;
            this.selectedContact.ContactTypeId = contactType.LookupId;
        }
        this.selectedContact.Contact = _.find(this.systemContactList, function (item) {
            return item.ContactId == self.selectedContact.ContactId;
        }).Contact;

        $('#modal_add_contact').modal('show');
    }

    copyContact(sourceContact, destinationContact) {
        var tempContact = JSON.parse(JSON.stringify(sourceContact));
        destinationContact.CreditorContactId = tempContact.CreditorContactId;
        destinationContact.CreditorId = tempContact.CreditorId;
        destinationContact.DebtorId = tempContact.DebtorId;
        destinationContact.ContactTypeId = tempContact.ContactTypeId;
        destinationContact.ContactType = tempContact.ContactType;
        destinationContact.ContactTypeList = this.contactTypeList;
        destinationContact.ContactList = tempContact.ContactList;
        destinationContact.CaseListingId = tempContact.CaseListingId;
        destinationContact.ContactId = tempContact.ContactId;
        destinationContact.Contact = tempContact.Contact;
        destinationContact.SelectedContact = tempContact.SelectedContact;
        destinationContact.CompanyId = tempContact.CompanyId;
        destinationContact.Company = tempContact.Company;
        destinationContact.CompanyList = tempContact.CompanyList;
        destinationContact.SelectedCompany = tempContact.SelectedCompany;
        destinationContact.Phone = tempContact.Phone;
        destinationContact.PhoneExt = tempContact.PhoneExt;
        destinationContact.Email = tempContact.Email;
        destinationContact.IsActive = tempContact.IsActive;
        destinationContact.IsDefault = tempContact.IsDefault ? tempContact.IsDefault : false;
        destinationContact.Title = tempContact.Title ? tempContact.Title : "";
        destinationContact.FirstName = tempContact.FirstName;
        destinationContact.LastName = tempContact.LastName;
        destinationContact.Position = tempContact.Position;
        destinationContact.Address = tempContact.Address;
        destinationContact.Address2 = tempContact.Address2;
        destinationContact.City = tempContact.City;
        destinationContact.State = tempContact.State ? tempContact.State : "";
        destinationContact.ZipCode = tempContact.ZipCode;
        destinationContact.Country = tempContact.Country;
    }

    saveContact(item) {
        if (item.IsActive) {
            if (item.Contact)
                item.Contact = item.Contact.trim();

            if (!item.ContactTypeId) {
                swal('Error', 'Please select Contact Type.', 'error');
                return;
            }

            if (!item.ContactId && (!item.FirstName || !item.LastName)) {
                swal('Error', 'Please enter/select Contact Name.', 'error');
                return;
            }

            if (!item.CompanyId && !item.Company) {
                swal('Error', 'Please enter/select Company Name.', 'error');
                return;
            }

            if (item.Email) {
                let EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (!EMAIL_REGEXP.test(item.Email)) {
                    swal('Error', 'Please enter proper email address.', 'error');
                    return;
                }
            }
        }

        item.CreditorId = this.creditorForm.controls["CreditorId"].value;
        var request = {
            CreditorId: this.creditorForm.controls["CreditorId"].value,
            CreditorContactList: [item]
        };

        this.spinner.show();
        this.creditorService.saveContacts(request)
            .subscribe(response => {
                this.spinner.hide();
                $('#modal_add_contact').modal('hide');

                if (response.Status == 'Success') {
                    swal('Success', response.Message, 'success');
                    this.selectedContact = null;
                    // if(!this.filteredCreditorContact || this.filteredCreditorContact.length == 0){
                    this.parameterDebtorId = this.debtorId;
                    this.getCreditorDetail();
                    // }
                    this.getCreditorContact();
                    this.getContactList();
                    this.getCompanyList();
                    if (item.IsDefault) {
                        var tempIsCreditorChanged = this.isCreditorChange;
                        this.creditorForm.get("Email").setValue(item.Email);
                        this.creditorForm.get("PhoneNumber").setValue(item.Phone);
                        this.creditorForm.get("KeyPerson").setValue(item.FirstName + " " + item.LastName);
                        if (tempIsCreditorChanged)
                            this.isCreditorChange = true;
                        else
                            this.isCreditorChange = false;
                    }
                }
                else {
                    swal('Error', response.Message, 'error');
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    removeContact(contact, index) {
        if (this.filteredCreditorContact[index].CreditorContactId) {
            var self = this;
            swal({
                title: 'Warning',
                text: "Are you sure you want to delete contact?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(function (isConfirmed) {
                if (isConfirmed.value) {
                    contact.IsActive = false;
                    var request = {
                        CreditorId: self.creditorForm.get('CreditorId').value,
                        CreditorContactList: [contact]
                    };

                    self.spinner.show();
                    self.creditorService.saveContacts(request)
                        .subscribe(response => {
                            self.spinner.hide();
                            if (response.Token)
                                self.storageService.setData('EncryptedToken', response.Token);

                            if (response.Status == 'Success') {
                                swal('Success', "Contact Removed successfully", 'success');
                                self.selectedContact = {};
                                self.getCreditorContact();
                                self.getContactList();
                                self.getCompanyList();
                            }
                            else {
                                swal('Error', response.Message, 'error');
                            }
                        },
                            error => {
                                self.spinner.hide();
                                self.storageService.clear();
                                self.router.navigate(['/login']);
                            });
                }
            });
        }
        else {
            this.filteredCreditorContact.splice(index, 1);
        }
    }

    //#endregion

    //#region Notes

    addNotePopup() {

        $("#eventNotification").removeClass("show");
        $('#modal_notes').modal('show');
             
        Object.keys(this.notesForm.controls).forEach(key => {
            if ($('#notes' + key))
                $('#notes' + key).removeClass('validationError');
        });
        this.isNotesSubmitted = false;
        this.notesForm.setValue({
            CreditorNoteId: "",
            CreditorId: "",
            ContactId: "",
            ClaimStatusId: "",
            ClaimStatus: "",
            Note: "",
            NoteDate: new Date(),
            EventNote: "",
            EventDate: null,
            TimeAP: "",
            TimeHH: "",
            TimeMM: "",
        });
        this.selectedCreditorNotification = [];
    }

    applyFilter(filterValue: string) {
        this.notesDataSource.filter = filterValue.trim().toLowerCase();
    }

    getNotes() {
        this.spinner.show();
        this.creditorService.getCreditorNotes(this.creditorForm.controls["CreditorId"].value)
            .subscribe(response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.creditorNotes = response.data.map(x => Object.assign({}, x));
                    var self = this;

                    var filteredNotesList = []

                    if (this.debtorId) {
                        filteredNotesList = _.filter(this.creditorNotes, function (notes) {
                            return notes.DebtorId == self.debtorId || !notes.DebtorId;
                        });
                    }
                    else
                        filteredNotesList = this.creditorNotes;

                    this.filteredCreditorNotes = filteredNotesList;
                    this.notesDataSource = new MatTableDataSource(this.filteredCreditorNotes);
                    this.notesDataSource.sort = this.notesSort;
                    this.notesDataSource.paginator = this.notesPaginator;
                    this.searchModel = "";
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    saveNote() {
        Object.keys(this.notesForm.controls).forEach(key => {
            if ($('#notes' + key))
                $('#notes' + key).removeClass('validationError');
        });


        this.isNotesSubmitted = true;

        if (this.notesForm.valid) {
            this.spinner.show();
            var request = this.getNotesRequestObject();
            this.creditorService.addCreditorNote(request)
                .subscribe(response => {
                    this.spinner.hide();

                    if (response.Status == 'Success') {
                        this.isNotesSubmitted = false;
                        $('#modal_notes').modal('hide');
                        this.getNotes();
                        this.setNotesFormValue(null);
                        this.resizePage();
                        swal('Success', response.Message, 'success');
                    }
                    else {
                        swal('Error', response.Message, 'error');
                    }
                },
                    error => {
                        this.spinner.hide();
                        this.storageService.clear();
                        this.router.navigate(['/login']);
                    });
        }
        else {
            Object.keys(this.notesForm.controls).forEach(key => {
                if (!this.notesForm.controls[key].valid) {
                    if ($('#notes' + key))
                        $('#notes' + key).addClass('validationError');
                }
            });
        }
    }

    setNotesFormValue(data) {
        this.isNotesSubmitted = false;
        if (data) {

        }
        else {
            this.notesForm.setValue({
                CreditorNoteId: "",
                CreditorId: "",
                ContactId: "",
                ClaimStatusId: "",
                ClaimStatus: "",
                Note: "",
                NoteDate: null,
                EventNote: "",
                EventDate: null,
                TimeHH: "",
                TimeMM: "",
                TimeAP: ""
            });

            this.selectedCreditorNotification = [];
        }
    }

    getNotesRequestObject() {
        var time = null;
        var timeHours = this.notesForm.controls["TimeHH"].value;
        var timeMin = this.notesForm.controls["TimeMM"].value;
        var timeAP = this.notesForm.controls["TimeAP"].value;
        var eventDate = this.convertDate(this.notesForm.controls["EventDate"].value);

        if (Number(timeHours) && (Number(timeMin) || Number(timeMin) == 0) && timeAP != '' && eventDate != null) {
            time = timeHours + ':' + timeMin + ':00 ' + timeAP;
        }

        if (!eventDate) {
            this.selectedCreditorNotification = _.filter(this.selectedCreditorNotification, function (notification) {
                if (notification.CalendarNotificationId)
                    return notification;
            });

            this.selectedCreditorNotification.forEach(element => {
                if (element.CalendarNotificationId) {
                    element.IsActive = false;
                }
            });
        }

        return {
            CreditorNoteId: this.notesForm.controls["CreditorNoteId"].value,
            CreditorId: this.creditorForm.controls["CreditorId"].value,
            DebtorId: this.creditorForm.controls["DebtorId"].value,
            ContactId: this.notesForm.controls["ContactId"].value,
            ClaimStatusId: this.notesForm.controls["ClaimStatusId"].value,
            Note: this.notesForm.controls["Note"].value,
            NoteDate: this.convertDate(this.notesForm.controls["NoteDate"].value),
            EventDate: eventDate,
            EventNote: this.notesForm.controls["EventNote"].value,
            EventTime: time,
            NotificationList: this.selectedCreditorNotification
        }
    }

    getNote(item) {
        var time = null;
        var timeHH = "";
        var timeMM = "";
        var timeAP = "";
        if (item.EventTime != null) {
            time = new Date('01-01-1970 ' + item.EventTime);
            timeHH = formatDate(time, 'hh', 'en-US');
            timeMM = formatDate(time, 'mm', 'en-US');
            timeAP = formatDate(time, 'a', 'en-US');
        }
        else {
            timeHH = "";
            timeMM = "";
            timeAP = "";
        }

        this.notesForm.setValue({
            CreditorNoteId: item.CreditorNoteId,
            CreditorId: item.CreditorId,
            ContactId: item.ContactId ? item.ContactId : "",
            ClaimStatusId: item.ClaimStatusId,
            ClaimStatus: item.ClaimStatus,
            Note: item.Note,
            NoteDate: item.NoteDate,
            EventNote: item.EventNote,
            EventDate: item.EventDate,
            TimeHH: timeHH,
            TimeMM: timeMM,
            TimeAP: timeAP
        });
        $("#eventNotification").removeClass("show");
        this.selectedCreditorNotification = item.NotificationList ? item.NotificationList : [];
        $('#modal_notes').modal('show');
    }

    displayNote(noteId) {
        var selectedNote = _.find(this.creditorNotes, function (n) {
            return n.CreditorNoteId == noteId
        });

        if (selectedNote) {
            this.getNote(selectedNote);
        }
    }

    deleteNote(item) {
        var self = this;
        swal({
            title: 'Warning',
            text: "Are you sure you want to delete this Note?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0CC27E',
            cancelButtonColor: '#FF586B',
            confirmButtonText: 'Delete',
            cancelButtonText: "Cancel"
        }).then(function (isConfirm) {
            if (isConfirm.value == true) {
                self.spinner.show();
                self.creditorService.deleteCreditorNote(item)
                    .subscribe(response => {
                        if (response.Token)
                            self.storageService.setData('EncryptedToken', response.Token);
                        if (response.Status == 'Success') {
                            self.getNotes();
                            swal('Success', response.Message, 'success');
                        }
                        else {
                            swal('Error', response.Message, 'error');
                        }
                    },
                        error => {
                            self.spinner.hide();
                            self.storageService.clear();
                            self.router.navigate(['/login']);
                        });
            }
        }).catch(swal.noop);
    }

    addNotification() {
        if (!this.selectedCreditorNotification) {
            this.selectedCreditorNotification = [];
        }

        this.selectedCreditorNotification.push({
            Type: "Creditor",
            DaysBefore: 1,
            NotificationType: "Days",
            IsActive: true,
            CalendarDateId: this.notesForm.get('CreditorNoteId').value,
            CalendarNotificationId: ''
        });
    }

    deleteNotification(item, index) {
        if (item.CalendarNotificationId) {
            item.IsActive = false;
        }
        else {
            this.selectedCreditorNotification.splice(index, 1);
        }
    }
    //#endregion

    //#region Document

    creditorDocumentChange(event) {
        if (event.target.files[0]) {
            this.creditorDocument = event.target.files[0];
            $('#creditorFile').removeClass('validationError');
            this.uploadDocument = event.target.files[0].name;
        }
        else {
            $('#creditorFile').addClass('validationError');
            this.creditorDocument = null;
            $("#uploadDocument").val(null);
            this.uploadDocument = "Upload File";
        }
    }

    uploadCapitalStructure() {
        $('#creditorFile').removeClass('validationError');
        $('#creditorDocumentName').removeClass('validationError');
        var hasError = false;
        if (!this.creditorDocument) {
            hasError = true;
            $('#creditorFile').addClass('validationError');
        }

        if (!this.document.Name) {
            hasError = true;
            $('#creditorDocumentName').addClass('validationError');
        }

        if (hasError)
            return;

        var request = {
            "creditorId": this.creditorForm.controls["CreditorId"].value,
            "debtorId": this.creditorForm.controls["DebtorId"].value,
            "name": this.document.Name,
            "description": this.document.Description
        }
        this.spinner.show();
        this.creditorService.uploadDocument(this.creditorDocument, request)
            .subscribe(response => {
                this.spinner.hide();

                if (response.Status == 'Success') {
                    swal('Success', response.Message, 'success');
                    this.getDocuments();
                    $("#uploadDocument").val(null);
                    this.uploadDocument = "Upload File";
                    this.creditorDocument = null;
                    this.document.Name = "";
                    this.document.Description = "";
                }
                else {
                    swal('Error', response.Message, 'error');
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    getDocuments() {
        this.spinner.show();
        this.creditorService.getDocuments(this.creditorForm.controls["CreditorId"].value)
            .subscribe(response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.documentList = response.data;

                    var self = this;
                    var filteredDocList = _.filter(this.documentList, function (document) {
                        return document.DebtorId == self.debtorId;
                    });
                    if (filteredDocList && filteredDocList.length > 0)
                        this.filteredDocumentList = JSON.parse(JSON.stringify(filteredDocList));
                    else
                        this.filteredDocumentList = [];

                    this.documentDataSource = new MatTableDataSource(this.filteredDocumentList);
                    this.documentDataSource.sort = this.documentSort;

                    this.resizePage();
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    //#endregion

    //#region Claims

    getClaims() {
        this.spinner.show();
        this.creditorService.getClaims(this.creditorForm.controls["CreditorId"].value)
            .subscribe(response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.claimsList = response.data;
                    this.claimsList.forEach(element => {
                        element.Selected = false;
                    });

                    this.claimsDataSource = new MatTableDataSource(this.claimsList);
                    this.claimsDataSource.sort = this.claimsSort;
                    this.resizePage();
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    editClaim(claim) {
        // if (claim.IsBidCreated) {
        //     swal('Error', 'Bid for this claim is already created so you can\'t edit claim', 'error');
        //     return;
        // }
        this.editClaimId = claim.ClaimId;
        this.selectedClaim = claim;
    }

    saveClaims(data) {
        Object.keys(this.claimForm.controls).forEach(key => {
            if ($('#claims' + key))
                $('#claims' + key).removeClass('validationError');
        });
        this.isClaimSubmitted = true;

        var request = null;
        if (this.splitedClaim) {
            if (!this.claimForm.invalid) {
                data = {
                    'DebtorId': this.claimForm.get('Debtor').value,
                    'ClaimNo': this.claimForm.get('ClaimNo').value,
                    'ClaimType': this.claimForm.get('ClaimType').value,
                    'Amount': this.claimForm.get('Amount').value,
                    'ParentClaimId': this.claimForm.get('ParentClaimId').value,
                    'IsActive': true
                }

                if (data.Amount > this.splitedClaim.Amount) {
                    swal("Error", "The Amount cannot be larger than the existing Claim Amount. Please adjust the amount entered.", "error");
                    return;
                }
                var tempSplitClaimAmount = this.splitedClaim.Amount;

                this.splitedClaim.Amount = this.splitedClaim.Amount - data.Amount;

                var self = this;
                //text: 'Click Yes to confirm the split of claim # '+self.splitedClaim.ClaimNo+' for the amount of $'+ parseFloat(data.Amount) +' as '+ data.ClaimType,
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                })
                swal({
                    title: 'Please Confirm',
                    text: 'Designate ' + formatter.format(parseFloat(data.Amount)) + ' from Claim #' + self.splitedClaim.ClaimNo + ' as ' + data.ClaimType,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(function (isConfirmed) {
                    if (isConfirmed.value) {
                        request = self.getClaimRequestObject('update', data);
                        request.ClaimsList.push(self.splitedClaim);
                        self.saveClaim(request);
                        self.isSplitClaim = false;
                    }
                    else {
                        self.splitedClaim.Amount = tempSplitClaimAmount;
                    }
                });
            }
            else {
                Object.keys(this.claimForm.controls).forEach(key => {
                    if (!this.claimForm.controls[key].valid) {
                        if ($('#claims' + key))
                            $('#claims' + key).addClass('validationError');
                    }
                });
            }
        }
        else if (!this.splitedClaim && data) {
            if (!data.ClaimNo) {
                swal("Error", "Please enter Claim Number.", "error");
                return;
            }
            if (!data.ClaimDebtorId) {
                swal("Error", "Please select Debtor.", "error");
                return;
            }
            if (!data.Amount) {
                swal("Error", "Please enter Amount.", "error");
                return;
            }
            if (!data.ClaimType) {
                swal("Error", "Please select Claim Type.", "error");
                return;
            }
            request = this.getClaimRequestObject('update', data);
            this.saveClaim(request);
        }
        else {
            if (!this.claimForm.invalid) {
                request = this.getClaimRequestObject('add', null);
                this.saveClaim(request);
            }
            else {
                Object.keys(this.claimForm.controls).forEach(key => {
                    if (!this.claimForm.controls[key].valid) {
                        if ($('#claims' + key))
                            $('#claims' + key).addClass('validationError');
                    }
                });
            }
        }
    }

    saveClaim(request) {
        this.spinner.show();
        this.creditorService.saveClaim(request)
            .subscribe(response => {
                this.spinner.hide();

                if (response.Status == 'Success') {
                    this.isClaimSubmitted = false;
                    this.editClaimId = '';
                    $('#modal_claim').modal('hide');
                    this.getClaims();
                    this.getClaimReport();
                    this.setClaimFormValues(null);
                    swal('Success', response.Message, 'success');
                }
                else {
                    swal('Error', response.Message, 'error');
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    splitClaim(claim) {
        // if (claim.IsBidCreated) {
        //     swal('Error', 'Bid for this claim is already created so you can\'t edit claim', 'error');
        //     return;
        // }


        this.splitedClaim = claim;
        let claimCopy = Object.assign({}, claim)
        claimCopy.Amount = "";
        claimCopy.ClaimType = "";
        this.setClaimFormValues(claimCopy);
        this.isSplitClaim = true;
        $('#modal_claim').modal('show');
    }

    cancelClaim() {
        this.editClaimId = '';
        $('#modal_claim').modal('hide');
        this.setClaimFormValues(null);
    }

    setClaimFormValues(data) {
        this.selectedClaim = {};
        Object.keys(this.claimForm.controls).forEach(key => {
            if ($('#claims' + key))
                $('#claims' + key).removeClass('validationError');
        });
        this.isClaimSubmitted = false;

        if (data) {
            this.claimForm.setValue({
                ClaimNo: data.ClaimNo,
                Debtor: data.ClaimDebtorId,
                Amount: data.Amount,
                ClaimType: data.ClaimType,
                ParentClaimId: data.ClaimId
            });
        }
        else {
            this.claimForm.setValue({
                ClaimNo: "",
                Debtor: "",
                Amount: "",
                ClaimType: "",
                ParentClaimId: ""
            });
            this.splitedClaim = null;
        }
    }

    getClaimRequestObject(type, data) {
        if (type == 'add') {
            return {
                'CreditorId': this.creditorForm.controls["CreditorId"].value,
                'ClaimsList': [
                    {
                        'ClaimDebtorId': this.claimForm.get('Debtor').value,
                        'DebtorId': this.creditorForm.get('DebtorId').value,
                        'ClaimNo': this.claimForm.get('ClaimNo').value,
                        'ClaimType': this.claimForm.get('ClaimType').value,
                        'Amount': this.claimForm.get('Amount').value,
                        'ParentClaimId': this.claimForm.get('ParentClaimId').value,
                        'IsActive': true
                    }
                ]
            }
        }
        else {
            return {
                'CreditorId': this.creditorForm.controls["CreditorId"].value,
                'ClaimsList': [
                    {
                        'ClaimId': data.ClaimId,
                        'DebtorId': this.creditorForm.get('DebtorId').value,
                        'ClaimDebtorId': this.claimForm.get('Debtor').value ? this.claimForm.get('Debtor').value : data.ClaimDebtorId,
                        'ClaimNo': data.ClaimNo,
                        'ClaimType': data.ClaimType,
                        'Amount': data.Amount,
                        'ParentClaimId': data.ParentClaimId,
                        'IsActive': true
                    }
                ]
            }
        }
    }

    addClaim() {
        this.splitedClaim = {};
        this.isSplitClaim = false;
        this.setClaimFormValues(null);
    }

    saveClaimStatus(item) {
        var request = {
            ClaimId: item.ClaimId,
            ClaimStatus: item.Status
        }
        this.creditorService.saveClaimStatus(request)
            .subscribe(response => {
                if (response.Status != 'Success')
                    swal('Success', response.Message, 'error');
                else
                    this.getClaims();
            },
                error => {
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    //#endregion

    //#region Bids

    addBid() {
        this.selectedClaimList = [];
        this.selectedCreditorNotification = [];
        $("#bidEventNotification").removeClass("show");
        var result = _.filter(this.claimsList, function (data) {
            return data.Selected
        });

        if (result && result.length > 0) {
            this.setBidFormValues(null);
            this.selectedClaimList = result.map(x => Object.assign({}, x));
            this.selectedClaimList.forEach(element => {
                element.ClaimAmount = "";
                element.BidAmount = "";
                element.BidPercent = "";
            });

            var claimType = this.selectedClaimList[0].ClaimType;
            var isSameClaimType = true;

            var decimalPointFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            })

            var noDecimalPointFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })
            var claimAmount;

            this.selectedClaimList.forEach(element => {
                if (element.ClaimType != claimType)
                    isSameClaimType = false;

                if (element.Amount.toString().indexOf('.') > -1) {
                    element.ClaimAmount = decimalPointFormatter.format(parseFloat(element.Amount));
                }
                else {
                    element.ClaimAmount = decimalPointFormatter.format(parseFloat(element.Amount));
                }
            });

            if (isSameClaimType)
                this.bidForm.controls["ClaimType"].setValue(claimType);
            else
                this.bidForm.controls["ClaimType"].setValue('Blended');

            this.bidForm.controls["BidDate"].setValue(new Date());

            this.bidForm.controls["BidAmount"].setValue(0);


            $('#modal_bid').modal('show');
        }
    }

    editBid(item) {
        this.selectedCreditorNotification = [];
        $("#eventNotification").removeClass("show");
        if (item) {
            this.setBidFormValues(item);
            $('#modal_bid').modal('show');
        }
    }

    cancelBidPopup() {
        this.selectedClaimList = [];
        this.selectedCreditorNotification = [];
        this.claimsList.forEach(element => {
            element.Selected = false;
        });
        this.isBidSubmitted = false;

        $("#bidEventNotification").removeClass("show");
    }

    calculateTotalBidAmount() {
        var total = 0;

        this.selectedClaimList.forEach(element => {
            if (element.BidAmount)
                total += parseFloat(parseFloat(element.BidAmount.toString().replace('$', '').replace('.', '').replace(/,/g, '')).toFixed(0));
        });

        this.bidForm.controls["BidAmount"].setValue(total);
    }

    calculateBid(item, type) {
        if (!item.ClaimAmount)
            return;

        if (!item.BidPercent && !item.BidAmount)
            return;

        if (type == 'Amount') {
            if (!item.BidPercent || parseFloat(item.BidPercent) == 0) {
                item.BidPercent = 0;
                item.BidAmount = 0;
            }
            else {
                var BidAmount = parseFloat(item.ClaimAmount.toString().replace('$', '').replace(/,/g, '')).toFixed(0);
                var percentage = parseFloat(item.BidPercent.toString().replace('%', '').replace(/,/g, '')).toFixed(2);
                var amount = parseFloat(((parseFloat(BidAmount) * parseFloat(percentage)) / 100).toString()).toFixed(0);
                //item.BidAmount = '$' + amount;
                if (amount.indexOf('.') > -1) {
                    var formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    })
                    item.BidAmount = formatter.format(parseFloat(amount));
                }
                else {
                    var formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    })
                    item.BidAmount = formatter.format(parseFloat(amount));
                }
            }
        }
        else {
            if (!item.BidAmount || parseFloat(item.BidAmount) == 0) {
                item.BidPercent = 0;
                item.BidAmount = 0;
            }
            else {
                var BidAmount = parseFloat(item.ClaimAmount.toString().replace('%', '').replace(/,/g, '')).toFixed(0);
                var amount = parseFloat(item.BidAmount.toString().replace('$', '').replace(/,/g, '')).toFixed(0);
                var percentage = parseFloat(((parseFloat(amount) * 100) / parseFloat(BidAmount)).toString()).toFixed(2);
                item.BidPercent = percentage + '%';
            }
        }

        this.calculateTotalBidAmount();
    }

    saveBids() {

        Object.keys(this.bidForm.controls).forEach(key => {
            if ($('#bid' + key))
                $('#bid' + key).removeClass('validationError');
        });

        this.selectedClaimList.forEach((item, index) => {
            if ($('#bidClaimAmount' + (index + 1)))
                $('#bidClaimAmount' + (index + 1)).removeClass('validationError');

            if ($('#bidBidPercent' + (index + 1)))
                $('#bidBidPercent' + (index + 1)).removeClass('validationError');

            if ($('#bidBidAmount' + (index + 1)))
                $('#bidBidAmount' + (index + 1)).removeClass('validationError');
        });

        this.isBidSubmitted = true;
        var hasError = false;
        this.selectedClaimList.forEach(element => {
            if (!element.ClaimAmount || !element.BidAmount || !element.BidPercent) {
                hasError = true;
            }
        });

        if (!this.bidForm.invalid && !hasError) {
            var request = this.getBidRequestedObject();
            if (request.EventDate && request.Note == "") {
                swal('Error', 'Note is Compulsory For Event Date Added', 'error');
                return;
            }
            if (request.ContactId && request.Note == "") {
                swal('Error', 'Note is Compulsory For Contact you selected', 'error');
                return;
            }
            if (request.EventNote && request.Note == "") {
                swal('Error', 'Notes  is Compulsory you entered Events note', 'error');
                return;
            }
            if (this.isBidFileChanged) {
                this.spinner.show();
                this.creditorService.saveBidWithFile(this.bidFile, request)
                    .subscribe(response => {
                        this.spinner.hide();

                        if (response.Status == 'Success') {
                            this.isBidSubmitted = false;
                            $('#modal_bid').modal('hide');
                            swal('Success', response.Message, 'success');
                            this.getBids();
                            this.setBidFormValues(null);
                            this.getNotes();
                        }
                        else {
                            swal('Error', response.Message, 'error');
                        }
                    },
                        error => {
                            this.spinner.hide();
                            this.storageService.clear();
                            this.router.navigate(['/login']);
                        });
            }
            else {
                this.spinner.show();
                this.creditorService.saveBid(request)
                    .subscribe(response => {
                        this.spinner.hide();

                        if (response.Status == 'Success') {
                            this.isBidSubmitted = false;
                            $('#modal_bid').modal('hide');
                            swal('Success', response.Message, 'success');
                            this.getBids();
                            this.setBidFormValues(null);
                            this.getNotes();
                        }
                        else {
                            swal('Error', response.Message, 'error');
                        }
                    },
                        error => {
                            this.spinner.hide();
                            this.storageService.clear();
                            this.router.navigate(['/login']);
                        });
            }
        }
        else {
            Object.keys(this.bidForm.controls).forEach(key => {
                if (!this.bidForm.controls[key].valid) {
                    if ($('#bid' + key))
                        $('#bid' + key).addClass('validationError');
                }
            });

            this.selectedClaimList.forEach((element, index) => {
                if (!element.ClaimAmount) {
                    if ($('#bidClaimAmount' + (index + 1)))
                        $('#bidClaimAmount' + (index + 1)).addClass('validationError');
                }

                if (!element.BidAmount) {
                    if ($('#bidBidPercent' + (index + 1)))
                        $('#bidBidPercent' + (index + 1)).addClass('validationError');
                }

                if (!element.BidPercent) {
                    if ($('#bidBidAmount' + (index + 1)))
                        $('#bidBidAmount' + (index + 1)).addClass('validationError');
                }
            });
        }
    }

    getBidRequestedObject() {
        var list = JSON.parse(JSON.stringify(this.selectedClaimList));
        var notificationList=JSON.parse(JSON.stringify(this.selectedCreditorNotification));
        var time = null;
        var timeHours = this.bidForm.controls["TimeHH"].value;
        var timeMin = this.bidForm.controls["TimeMM"].value;
        var timeAP = this.bidForm.controls["TimeAP"].value;
        var eventDate = this.convertDate(this.bidForm.controls["EventDate"].value);
        if (!eventDate)
            eventDate = "";

        if (!time)
            time = "";

        if (Number(timeHours) && (Number(timeMin) || Number(timeMin) == 0) && timeAP != '' && eventDate != null) {
            time = timeHours + ':' + timeMin + ':00 ' + timeAP;
        }

        list.forEach(claim => {
            claim.ClaimAmount = claim.ClaimAmount.toString().replace('$', '').replace(/,/g, '');
            claim.BidPercent = claim.BidPercent.toString().replace('%', '').replace(/,/g, '');
            claim.BidAmount = claim.BidAmount.toString().replace('$', '').replace(/,/g, '');
        });
        return {
            'BidId': this.bidForm.controls["BidId"].value,
            'BidDate': this.convertDate(this.bidForm.controls["BidDate"].value),
            'BidAmount': this.bidForm.controls["BidAmount"].value,
            'ClaimType': this.bidForm.controls["ClaimType"].value,
            'ContactId': this.bidForm.controls["ContactId"].value,
            'BidStatus': this.bidForm.controls["BidStatus"].value,
            'CreditorId': this.creditorForm.controls["CreditorId"].value,
            'DebtorId': this.creditorForm.controls["DebtorId"].value,
            'ClaimList': list,
            'FileId': this.bidForm.controls["FileId"].value,
            'IsActive': this.bidForm.controls["IsActive"].value,
            'Note': this.bidForm.controls["Note"].value,
            'EventNote': this.bidForm.controls["EventNote"].value,
            'EventDate': eventDate,
            'EventTime': time,           
            'NotificationList': notificationList           
        }
    }

    bidDocumentChange(event) {
        this.isBidFileChanged = true;
        if (event.target.files[0]) {
            this.bidFile = event.target.files[0];
            this.uploadedBid = event.target.files[0].name;
        }
        else {
            this.bidFile = null;
            $("#bidDocument").val(null);
            this.uploadedBid = "Upload File";
        }
    }

    setBidFormValues(data) {
        this.isBidSubmitted = false;
        this.bidFile = null;
        $("#bidDocument").val(null);
        this.uploadedBid = "Upload File";
        this.isBidFileChanged = false;

        this.claimsList.forEach(element => {
            element.Selected = false;
        });

        Object.keys(this.bidForm.controls).forEach(key => {
            if ($('#bid' + key))
                $('#bid' + key).removeClass('validationError');
        });

        if (this.selectedClaimList) {
            this.selectedClaimList.forEach((item, index) => {
                if ($('#bidClaimAmount' + (index + 1)))
                    $('#bidClaimAmount' + (index + 1)).removeClass('validationError');

                if ($('#bidBidPercent' + (index + 1)))
                    $('#bidBidPercent' + (index + 1)).removeClass('validationError');

                if ($('#bidBidAmount' + (index + 1)))
                    $('#bidBidAmount' + (index + 1)).removeClass('validationError');
            });
        }

        this.selectedClaimList = [];

        if (data) {
            this.bidForm.setValue({
                BidId: data.BidId,
                BidDate: data.BidDate,
                BidAmount: data.BidAmount,
                ClaimType: data.ClaimType,
                BidStatus: data.BidStatus,
                FileId: data.FileId,
                IsActive: data.IsActive,
                ContactId: "",
                Note: "",
                EventNote: "",
                EventDate: null,
                TimeHH: "",
                TimeMM: "",
                TimeAP: ""
            });

            this.selectedClaimList = JSON.parse(JSON.stringify(data.ClaimList));
            var decimalPointFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            })

            var noDecimalPointFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })

            this.selectedClaimList.forEach(claim => {
                this.calculateBid(claim, 'Amount');

                if (claim.ClaimAmount.toString().indexOf('.') > -1)
                    claim.ClaimAmount = decimalPointFormatter.format(parseFloat(claim.ClaimAmount));
                else
                    claim.ClaimAmount = noDecimalPointFormatter.format(parseFloat(claim.ClaimAmount));

                if (claim.BidPercent.toString().indexOf('.') > -1)
                    claim.BidPercent = decimalPointFormatter.format(parseFloat(claim.BidPercent));
                else
                    claim.BidPercent = noDecimalPointFormatter.format(parseFloat(claim.BidPercent));

                claim.BidPercent = claim.BidPercent.toString().replace('$', '').replace(/,/g, '') + '%';
            });
            if (data.FileURL) {
                this.uploadedBid = data.FileName;
            }
        }
        else {
            this.bidForm.setValue({
                BidId: "",
                BidDate: null,
                BidAmount: 0,
                ClaimType: "Blended",
                BidStatus: "",
                FileId: "",
                IsActive: true,
                Note: "",
                ContactId: "",
                EventNote: "",
                EventDate: null,
                TimeHH: "",
                TimeMM: "",
                TimeAP: ""
            });
        }
    }

    getBids() {
        this.getClaimReport();
        this.getClaims();
        this.spinner.show();
        this.creditorService.getBids(this.creditorForm.controls["CreditorId"].value)
            .subscribe(response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.bidsList = response.data;
                    this.bidsDataSource = new MatTableDataSource(this.bidsList);
                    this.bidsDataSource.sort = this.bidsSort;
                    this.resizePage();
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    saveBidDates(item) {
        var request = {
            BidId: item.BidId,
            TradeDate: this.convertDate(item.TradeDate),
            SettleDate: this.convertDate(item.SettleDate),
            TradeDateId: item.TradeDateId,
            BidStatus: item.BidStatus
        }

        this.creditorService.saveBidDates(request)
            .subscribe(response => {
                if (response.Status != 'Success') {
                    swal("Success", response.Message, "error")
                }
            },
                error => {
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    //#endregion


    //#region POC Documents

    addPOCDocument() {
        this.POCDocumentFile = null;
        $('#DocumentFile').removeClass('validationError');
        $("#uploadPOCDocumentFile").val(null);
        this.labelPOCDocumentFile = "Upload File";
        $('#modal_POCDocument_add').modal('show');
    }


    uploadPOCDocumentFileChange(event) {
        if (event.target.files[0]) {
            this.POCDocumentFile = event.target.files[0];
            $('#DocumentFile').removeClass('validationError');
            this.labelPOCDocumentFile = event.target.files[0].name;
        }
        else {
            this.POCDocumentFile = null;
            $('#DocumentFile').addClass('validationError');
            this.POCDocumentFile = null;
            $("#uploadPOCDocumentFile").val(null);
            this.labelPOCDocumentFile = "Upload File";
        }
    }

    getPOCDocumentList() {
        this.spinner.show();
        this.creditorService.getPOCDocuments(this.creditorForm.controls["CreditorId"].value)
            .subscribe(response => {
                this.spinner.hide();
                if (response.Status == 'Success') {

                    this.POCDocumentList = response.data;
                    var self = this;
                    var filteredDocList = _.filter(this.POCDocumentList, function (document) {
                        return document.DebtorId == self.debtorId;
                    });
                    if (filteredDocList && filteredDocList.length > 0) {
                        this.filteredDocumentList = JSON.parse(JSON.stringify(filteredDocList));
                        this.currentPOC = this.filteredDocumentList[0].FileURL;
                    }
                    else {
                        this.filteredDocumentList = [];
                        this.currentPOC = null;
                    }


                    this.pocDocumentDataSource = new MatTableDataSource(this.filteredDocumentList);
                    this.pocDocumentDataSource.sort = this.pocDocumentSort;

                    this.resizePage();
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });

    }

    uploadPOCDocument() {
        $('#DocumentFile').removeClass('validationError');

        if (!this.POCDocumentFile) {
            $('#DocumentFile').addClass('validationError');
            return
        }

        var request = {
            "creditorId": this.creditorForm.controls["CreditorId"].value,
            "debtorId": this.creditorForm.controls["DebtorId"].value,
            "POCDocumentId": "",
            "FileId": "",
            "UserId": ""
        }
        this.spinner.show();
        this.creditorService.uploadPOCDocument(this.POCDocumentFile, request)
            .subscribe(response => {
                this.spinner.hide();

                if (response.Status == 'Success') {
                    swal('Success', response.Message, 'success');
                    this.getPOCDocumentList();
                    $('#modal_POCDocument_add').modal('hide');
                    $("#uploadDocument").val(null);
                    this.labelPOCDocumentFile = "Upload File";
                    this.POCDocumentFile = null;
                }
                else {
                    swal('Error', response.Message, 'error');
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    deletePOCDocument(item, index) {
        var self = this;

        var request = {
            "creditorId": item.CreditorId,
            "debtorId": item.DebtorId,
            "POCDocumentId": item.POCDocumentId,
            "FileId": item.FileId,
            "UserId": item.UserId
        }
        swal({
            title: 'Warning',
            text: "Are you sure you want to delete POC Document File?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(function (isConfirmed) {
            if (isConfirmed.value) {

                self.spinner.show();
                self.creditorService.uploadPOCDocument(self.POCDocumentFile, request)
                    .subscribe(response => {
                        self.spinner.hide();
                        if (response.Token)
                            self.storageService.setData('EncryptedToken', response.Token);

                        if (response.Status == 'Success') {
                            swal('Success', response.Message, 'success');
                            self.getPOCDocumentList();
                        }
                        else {
                            swal('Error', response.Message, 'error');
                        }
                    },
                        error => {
                            self.spinner.hide();
                            self.storageService.clear();
                            self.router.navigate(['/login']);
                        });
            }
        });

    }



    cancelUploadPOCDocument() {
        $('#modal_POCDocument_add').modal('hide');
    }

    //#endregion

    copyPOCObject() {
        this.copyPOC = JSON.parse(JSON.stringify(this.poc));
    }

    savePOC() {
        var tempPOC = JSON.parse(JSON.stringify(this.copyPOC));

        this.poc.pocSecured = tempPOC.pocSecured;
        this.poc.pocAmount = tempPOC.pocAmount;
        this.poc.pocUnsecured = tempPOC.pocUnsecured;
        this.poc.poc = this.poc.pocSecured + this.poc.pocAmount + this.poc.pocUnsecured;
        this.isCreditorChange = true;
    }

    getClaimReport() {
        this.spinner.show();
        this.creditorService.getClaimsReport(this.creditorForm.controls["CreditorId"].value)
            .subscribe(response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.masterBidReports = response.data.ClaimsReports;
                    this.assignDebtor();
                }
            },
                error => {
                    this.spinner.hide();
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                });
    }

    resizePage() {
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }

    contactsToggle(collapsed) {
        this.contactsCollapsed = collapsed;
    }
}
