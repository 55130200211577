import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '../../config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

// Decorator to tell Angular that this class can be injected as a service to another class
@Injectable()
export class LoginService {
  httpUrl: config = new config();
  constructor(private http: HttpClient) {

  }

  login(object: any): Observable<any> {
    return this.http.post(this.httpUrl.url + "user/login", object)
      .map(this.extractData)
      .catch(this.handleError);
  }

  forgotPassword(object: any): Observable<any> {
    return this.http.post(this.httpUrl.url + "user/forgotPassword", object)
      .map(this.extractData)
      .catch(this.handleError);
  }

  resetPassword(object: any): Observable<any> {
    return this.http.post(this.httpUrl.url + "user/resetPassword", object)
      .map(this.extractData)
      .catch(this.handleError);
  }

  extractData(res: Response) {
    return res || {};
  }
  private handleError(error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return errMsg;
  }
}
