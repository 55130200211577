import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  constructor() { }
  public setData(name: any, data: any) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  public getData(name: any) {
    if (name == 'ActionList') {
      var data = localStorage.getItem(name);
      if (data.charAt(0) == '"')
        return window.atob((data.slice(1, -1)));
      else
        return window.atob(data);
    }
    else{
      if (name == 'UserFullName' || name == 'EncryptedToken') {
        var item = localStorage.getItem(name);
        if (item != null)
            item = item.replace(/"/g, '');
        return item;
      }
      else
        return localStorage.getItem(name);
    }
  }

  public removeData(name: any) {
    return localStorage.removeItem(name);
  }
  
  public clear(){
    localStorage.clear();
  }
}
