import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from "@angular/router";
import { NgModule } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatNativeDateModule, MatFormFieldModule, MatInputModule, DateAdapter, MAT_DATE_FORMATS, MatTableModule, MatSortModule, MatPaginatorModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule } from 'angular-calendar';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { AppComponent } from './app.component';
import { SideNavigationComponent } from "./component/common/navigation/side-navigation.component";
import { TopNavbarComponent } from "./component/common/navigation/top-navbar.component";
import { FlotDirective } from './component/common/charts/chart';
import { CdkDetailRowDirective } from './component/common/cdk-detail-row.directive';

import { ROUTES } from "./app.route";
import { LoginComponent } from './component/login/login.component';
import { ConfirmComponent } from './component/login/confirm.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { DebtorComponent } from "./component/debtor/debtor.component";
import { DebtorSearchComponent } from "./component/debtor/debtor-search.component";
import { CreditorComponent } from "./component/creditor/creditor.component";
import { CreditorSearchComponent } from "./component/creditor/creditor-search.component";
import { ContactComponent } from './component/contact/contact.component';
import { UserComponent } from './component/user/user.component';
import { ClaimsAgentComponent } from './component/claimsAgent/claimsagent.component';
import { IndustryComponent } from './component/industry/industry.component';
import { CapitalComponent } from './component/capital/capital.component';
import { ReasonComponent } from './component/reason/reason.component';
import { PlanComponent } from './component/plan/plan.component';
import { AuditTrailSearchComponent } from "./component/auditTrail/auditTrail-search.component";
import { ClaimStatusComponent } from './component/claimStatus/claimstatus.component';
import { SourcerReportsComponent } from './component/reports/SourcerReport/sourcer.reports.component';
import { DateTypeComponent } from './component/DateType/datetype.component';
import { RestrictedComponent } from './component/restricted/restricted.component';
import { CalendarComponent } from "./component/calendar/calendar.component";
import { ContactTypeComponent } from './component/contactType/contactType.component';
import { ClaimReportComponent } from './component/reports/ClaimReport/claimreport.component';
import {DailyTradeReportsComponent} from './component/reports/dailyTrade/dailyTrade.reports.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";

import { IboxtoolsModule } from './component/common/iboxtools/iboxtools.module';

import { StorageService } from './component/common/storage.service';
import { LoginService } from "./component/login/login.service";
import { CommonService } from "./component/common/common.service";
import { DashboardService } from './component/dashboard/dashboard.service';
import { DebtorService } from './component/debtor/debtor.service';
import { CreditorService } from './component/creditor/creditor.service';
import { ContactService } from './component/contact/contact.service';
import { CompanyService } from './component/contact/company.service';
import { UserService } from './component/user/user.service';
import { AuditTrailService } from './component/auditTrail/auditTrail.service';
import { CalendarService } from './component/calendar/calendar.service';
import { PageHelper } from './component/common/page.helper';
import { ReportService } from './component/reports/reports.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ChartsModule } from 'ng2-charts';
import { PhonePipe } from './component/common/phonePipe';
import { AppDateAdapter, APP_DATE_FORMATS } from './component/common/DateFormat';
import { DecimalToInt } from './component/common/decimaltoint.pipe';

import { CustomCurrencyPipe } from './component/common/customCurrency.pipe';
import { CurrenctCurrecnyDirective } from './component/common/customCurrency.directive';
import { CustomPercentagePipe } from './component/common/customPercent.pipe';
import { CustomPercentageDirective } from './component/common/customPercent.directive';
import { ValuationReportComponent } from './component/reports/ValuationReport/valuationReports.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './component/common/HTTPInterceptor';
import { ImportErrorComponent } from './component/importError/importError.component';
import { ImportComponent } from './component/importError/import.component';
import { AuthGuard } from './component/common/auth.guard';
import { ResetPasswordComponent } from './component/login/resetPassword.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    decimal: ".",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ","
};

@NgModule({
    declarations: [
        AppComponent,
        SideNavigationComponent,
        TopNavbarComponent,
        LoginComponent,
        ConfirmComponent,
        DashboardComponent,
        DebtorComponent,
        DebtorSearchComponent,
        CreditorComponent,
        CreditorSearchComponent,
        ContactComponent,
        UserComponent,
        ClaimsAgentComponent,
        IndustryComponent,
        CapitalComponent,
        ReasonComponent,
        PlanComponent,
        AuditTrailSearchComponent,
        ClaimStatusComponent,
        FlotDirective,
        CdkDetailRowDirective,
        SourcerReportsComponent,
        DailyTradeReportsComponent,
        DateTypeComponent,
        RestrictedComponent,
        CalendarComponent,
        ContactTypeComponent,
        PhonePipe,
        CurrenctCurrecnyDirective,
        CustomPercentageDirective,
        DecimalToInt,
        ValuationReportComponent,
        ClaimReportComponent,
        ImportErrorComponent,
        ImportComponent,
        ResetPasswordComponent
    ],
    imports: [
        NgbModule.forRoot(),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ChartsModule,
        RouterModule.forRoot(ROUTES, { useHash: true }),
        BootstrapModalModule.forRoot({ container: document.body }),
        AngularFontAwesomeModule,
        [MatNativeDateModule, MatFormFieldModule, MatInputModule],
        MatDatepickerModule,
        MatAutocompleteModule,
        IboxtoolsModule,
        NgxMaskModule.forRoot(),
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot(),
        CurrencyMaskModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        NgxSpinnerModule,
        TimepickerModule.forRoot()
    ],
    providers: [
        StorageService,
        LoginService,
        CommonService,
        DashboardService,
        DebtorService,
        CreditorService,
        ContactService,
        UserService,
        AuditTrailService,
        CalendarService,
        CurrencyPipe,
        PercentPipe,
        CompanyService,
        ReportService,
        CustomCurrencyPipe,
        CustomPercentagePipe,
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        PageHelper,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Interceptor,
          multi: true
        },
        AuthGuard
    ],
    entryComponents: [
        ConfirmComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
