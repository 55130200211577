import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CommonService } from '../common/common.service';
import * as _ from 'underscore';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
declare var swal: any;

@Component({
    selector: 'restricted',
    templateUrl: 'restricted.template.html',
    styleUrls: ['../../../assets/css/mat-table.css']
})

export class RestrictedComponent implements OnInit {
    @ViewChild('Paginator') paginator: MatPaginator;
    @ViewChild('Sort') sort: MatSort;
    dataColumns: string[] = ['Name', 'Type', 'Action'];
    dataSource: MatTableDataSource<any>;

    selectedCompany: any = "";
    restrictedList: any = [];
    companyList: any = [];
    RecordType: any = "New";
    searchModel = "";
    saveButton: boolean = false;

    constructor(private router: Router, private commonService: CommonService, private pageHelper: PageHelper, private spinner: NgxSpinnerService) {

    }

    ngOnInit() {
        $('#menuManage').addClass('active');
        $('#menuManage1').addClass('active');
        $('#menuManage1_8').addClass('active');

        this.getRestrictedList();
    }

    getRestrictedList() {
        this.spinner.show();
        this.searchModel = "";
        this.selectedCompany = "";
        this.saveButton = false;
        this.commonService.getRestrictedList().subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.companyList = [];
                    this.restrictedList = [];
                    let resultantList = response.data;

                    this.restrictedList = _.filter(resultantList, function (data) {
                        return data.IsRestricted == true;
                    });

                    this.companyList = _.filter(resultantList, function (data) {
                        return data.IsRestricted == false;
                    });

                    this.dataSource = new MatTableDataSource(this.restrictedList);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    setTimeout(() => {
                        this.pageHelper.page_content_onresize();
                    }, 500);
                }
            },
            error => {
                this.spinner.hide();
                this.router.navigate(['/login']);
            });
    }

    save(value: boolean, company: any) {
        if (this.selectedCompany && value) { //Add Restricted
            var self = this;
            var selected = _.find(this.companyList, function (data) {
                return data.Key == self.selectedCompany;
            });

            selected.IsRestricted = value;
            this.spinner.show();
            this.commonService.saveRestricted(selected).subscribe(
                response => {
                    this.spinner.hide();
                    if (response.Status == 'Success') {
                        var message = response.Message.replace("##name##", "Reason");
                        swal('Success', message, 'success');

                        this.getRestrictedList();
                        this.RecordType = "New";
                    }
                    else {
                        var message = response.Message.replace("##name##", "Reason");
                        swal('Error', message, 'error');
                    }
                },
                () => {
                    this.spinner.hide();
                    this.router.navigate(['/login']);
                }
            );
        }
        else if (company && value == false) {
            var self = this;
            swal({
                title: 'Warning',
                text: "Are you sure you want to delete restricted " + company.Type + "?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(function (isConfirmed) {
                if (isConfirmed.value) {
                    company.IsRestricted = value;

                    self.spinner.show();
                    self.commonService.saveRestricted(company).subscribe(
                        response => {
                            if (response.Status == 'Success') {
                                var message = response.Message;
                                swal('Success', message, 'success');

                                self.getRestrictedList();
                                self.RecordType = "New";
                            }
                            else {
                                var message = response.Message;
                                swal('Error', message, 'error');
                            }
                        },
                        () => {
                            self.spinner.hide();
                            self.router.navigate(['/login']);
                        });
                }
            });
        }
    }

    selectCompany(event) {        
        var values = this.selectedCompany;

        if (values != "")
            this.saveButton = true;
        else
            this.saveButton = false;
    }
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    updateRecordType() {
        this.RecordType = "Update";
        this.saveButton = false;
    }

    cancelUpdate() {
        this.selectedCompany = "";
        this.RecordType = "New";
        this.saveButton = false;
    }
}
