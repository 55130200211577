import { Component } from '@angular/core';

declare var window: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    isQA: any = false;
    isDev: any = false;

    ngOnInit() {
        if (window.location.host.indexOf('perrysysdev') > -1) {
            this.isDev = true;
        }
        if (window.location.host.indexOf('qa.') > -1) {
            this.isQA = true;
        }
    }
}