import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '../../config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { StorageService } from '../common/storage.service';

// Decorator to tell Angular that this class can be injected as a service to another class
@Injectable()
export class DebtorService {
  httpUrl: config = new config();
  constructor(private http: HttpClient, private storageService: StorageService) {

  }

  search(showAll: boolean = false): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/search?showAll=" + showAll)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getDetails(listingKey: any): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getDetail?listingKey=" + listingKey)
      .map(this.extractData)
      .catch(this.handleError);
  }

  saveDebtor(request): Observable<any> {
    return this.http.post(this.httpUrl.url + "debtor/save", request)
      .map(this.extractData)
      .catch(this.handleError);
  }

  uploadCapitalStructure(file: any, note: any, caseListingId: any): Observable<any> {
    let formData = new FormData();
    formData.append('File', file);
    formData.append('capitalStructureNote', note);
    formData.append('caseListingId', caseListingId);


    return Observable.create(observer => {
      var xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', this.httpUrl.url + "debtor/uploadCapitalStructure", false);
      xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
      xhr.send(formData);
    });
  }

  saveCapitalStructure(request): Observable<any> {
    return this.http.post(this.httpUrl.url + "debtor/saveCapitalStructure", request)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getDebtorCapitalStructure(caseListingId: any): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getCapitalStructureList?caseListingId="+ caseListingId)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getSubDebtors(caseListingId: any): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getSubDebtors?caseListingId=" + caseListingId)
      .map(this.extractData)
      .catch(this.handleError);
  }

  saveSubDebtor(request): Observable<any> {
    return this.http.post(this.httpUrl.url + "debtor/saveSubDebtor", request)
      .map(this.extractData)
      .catch(this.handleError);
  }

  saveCaseStats(request): Observable<any> {
    return this.http.post(this.httpUrl.url + "debtor/saveCaseStats", request)
      .map(this.extractData)
      .catch(this.handleError);
  }

  savePoolEstimate(request): Observable<any> {
    return this.http.post(this.httpUrl.url + "debtor/savePoolEstimate", request)
      .map(this.extractData)
      .catch(this.handleError);
  }

  saveContacts(request): Observable<any> {
    return this.http.post(this.httpUrl.url + "debtor/saveContacts", request)
      .map(this.extractData)
      .catch(this.handleError);
  }

  
  saveDebtorCreditor(request):Observable<any>{
    return this.http.post(this.httpUrl.url + "debtor/saveDebtorCreditor", request)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getDebtorContacts(caseListingId: any): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getContacts?caseListingId="+ caseListingId)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getCaseUpdateList(showAll, CaseListingId): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getCaseUpdateList?showAll=" + showAll + "&CaseListingId=" + CaseListingId)
      .map(this.extractData)
      .catch(this.handleError);
  }

  saveCaseUpdate(request: any, file: any): Observable<any> {
    let formData = new FormData();
    formData.append('UpdateDate', request.UpdateDate);
    formData.append('ExternamLink', request.ExternamLink);
    formData.append('Note', request.Note);
    formData.append('CaseListingId', request.CaseListingId);
    formData.append('File', file);

    return Observable.create(observer => {
      var xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', this.httpUrl.url + 'debtor/saveCaseUpdate', false);
      xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
      xhr.send(formData);
    });
  }

  importCreditorList(file: any,debtorId: any): Observable<any> {
    let formData = new FormData();
    formData.append('File', file);  
    formData.append('debtorId', debtorId);


    return Observable.create(observer => {
      var xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', this.httpUrl.url + "debtor/saveCreditor", false);
      xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
      xhr.send(formData);
    });
  }

  getMatrixAndScheduleFiles(listingKey): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getMatrixAndScheduleFiles?listingKey=" + listingKey)
      .map(this.extractData)
      .catch(this.handleError);
  }


  saveMatrixAndScheduleFile(request: any, file: any): Observable<any> {
    let formData = new FormData();

    formData.append('File', file);
    formData.append('FileType', request.FileType);
    formData.append('subDebtorId', request.subDebtorId);  
    formData.append('ListingKey', request.ListingKey);   

    return Observable.create(observer => {
      var xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', this.httpUrl.url + 'debtor/saveMatrixAndScheduleFile', false);
      xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
      xhr.send(formData);
    });
  }

  getCreditorList(showAll, debtorId): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getCreditorList?showAll=" + showAll + "&debtorId=" + debtorId)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getCaseStats(listingKey: any): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getCaseStats?listingKey=" + listingKey)
      .map(this.extractData)
      .catch(this.handleError);
  }

  uploadCaseUpdate(caseUpdateId,file: any): Observable<any> {
    let formData = new FormData();

    formData.append('File', file);
    formData.append('CaseUpdateId', caseUpdateId);

    return Observable.create(observer => {
      var xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', this.httpUrl.url + 'debtor/uploadCaseUpdate', false);
      xhr.setRequestHeader('AuthorizationToken', this.storageService.getData('EncryptedToken'));
      xhr.send(formData);
    });
  }

  getImportedFiles(userFilesOnly): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getImportedFiles?userFilesOnly="+ userFilesOnly)
      .map(this.extractData)
      .catch(this.handleError);
  }

  getErrorList(fileId): Observable<any> {
    return this.http.get(this.httpUrl.url + "debtor/getErrorTrackingList?fileId=" +  fileId)
        .map(this.extractData)
        .catch(this.handleError);
  }

  extractData(res: Response) {
    return res || {};
  }

  private handleError(error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg, error.status);
    return errMsg;
  }
}
