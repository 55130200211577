import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '../../config';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

// Decorator to tell Angular that this class can be injected as a service to another class
@Injectable()
export class CalendarService {
    httpUrl: config = new config();
    constructor(private http: HttpClient) {

    }

    getCaseListingDates(caseListingId): Observable<any> {
        return this.http.get(this.httpUrl.url + "calendar/getCaseListingDates?caseListingId="+ caseListingId)
        .map(this.extractData)
        .catch(this.handleError);
    }

    saveNotification(request): Observable<any> {
        return this.http.post(this.httpUrl.url + "calendar/saveNotification", request)
        .map(this.extractData)
        .catch(this.handleError);
    }

    saveCaseListingDate(request): Observable<any> {
        return this.http.post(this.httpUrl.url + "calendar/saveCaseListingDate", request)
        .map(this.extractData)
        .catch(this.handleError);
    }

    getCalendarEvents(startDate, endDate): Observable<any> {
        return this.http.get(this.httpUrl.url + "calendar/getCalendarEvents?startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString())
        .map(this.extractData)
        .catch(this.handleError);
    }

    updateCalendarEvent(request): Observable<any> {
        console.log('request', request);
        return this.http.post(this.httpUrl.url + "calendar/updateCalendarEvent", request)
            .map(this.extractData)
            .catch(this.handleError);
    }
    
    extractData(res: Response) {
        return res || {};
    }

    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
         let errMsg: string;
         if (error instanceof Response) {
         const body = error.json() || '';
         const err = JSON.stringify(body);
         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
         } else {
         errMsg = error.message ? error.message : error.toString();
         }
         console.error(errMsg, error.status);
         return errMsg;
    }
}
