import { OnDestroy, OnInit, OnChanges, ElementRef, Input, SimpleChanges, NgModule, Directive } from '@angular/core';

import '../../../../assets/js/charts/flot/jquery.flot.js';
import '../../../../assets/js/charts/flot/jquery.flot.tooltip.min.js';
import '../../../../assets/js/charts/flot/jquery.flot.spline.js';
import '../../../../assets/js/charts/flot/jquery.flot.time.js';

declare var jQuery: any;

@Directive({
  selector: 'div[chart]',
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class FlotDirective implements OnDestroy, OnChanges, OnInit {

  @Input() private options: any;
  @Input() private data: any;

  public chart: any;
  private element: ElementRef;
  private initFlag: boolean = false;

  public constructor(element: ElementRef) {
    this.element = element;
  }

  public ngOnInit(): any {
    this.initFlag = true;
    if (this.options || this.data) {
      this.build();
    }
  }

  private build(): any {
    this.ngOnDestroy();

    this.chart = jQuery.plot(this.element.nativeElement, this.data, this.options);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.initFlag) {
      if (changes.hasOwnProperty('data')) {
        this.updateChartData(changes['data'].currentValue);
      } else {
        this.build();
      }
    }
  }

  private updateChartData(newDataValues: any[]): void {
    this.chart.setData(newDataValues);
    this.chart.setupGrid();
    this.chart.draw();
  }

  public ngOnDestroy(): any {
    if (this.chart) {
      this.chart.shutdown();
      this.chart = void 0;
    }
  }

  public onResize() {
    this.chart.resize();
    this.chart.setupGrid();
    this.chart.draw();
  }
}
