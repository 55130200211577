import { Component, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CreditorService } from './creditor.service';
import { DebtorService } from '../debtor/debtor.service';
import { CommonService } from '../common/common.service';
import * as _ from 'underscore';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import { PageHelper } from '../common/page.helper';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';

import {animate, state, style, transition, trigger} from '@angular/animations';
import { CdkDetailRowDirective } from '../common/cdk-detail-row.directive';

declare var $: any;

@Component({
    selector: 'creditor-search',
    templateUrl: 'creditor-search.template.html',
    styleUrls: ['../../../assets/css/mat-table.css'],
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class CreditorSearchComponent {
    creditorList: any = [];
    creditorDebtorsList: any = [];
    creditorClaimsList: any = [];
    clonedList = [];
    filter: any = {};

    isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');

    searchModel = "";

    debtorList = [];

    @ViewChild('creditorPaginator') creditorPaginator: MatPaginator;
    @ViewChild('creditorSort') creditorSort: MatSort;
    creditorDataColumns: string[] = ['Company', 'Debtor', 'KeyPerson', 'ClaimStatus', 'ClaimAmount','Amount', 'UnsecuredAmount', 'DebtorCount','DoNotCall', 'IsRestricted'];
    creditorDataSource: MatTableDataSource<any>;

    constructor(private router: Router, private creditorService: CreditorService
        , private debtorService: DebtorService, private commonService: CommonService, private pageHelper : PageHelper
        , private spinner: NgxSpinnerService) { }

    ngOnInit() {
        $('#menuCreditors').addClass('active');
        this.clearFilter();
        this.getDebtorList(false);
        this.getCreditorLits(false);
        this.getClaimsList('ClaimStatus');
    }

    getCreditorLits(showAll: any = false) {
        this.spinner.show();
        this.searchModel = "";
        this.creditorService.search(showAll).subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {               
                    this.clonedList = response.data.map(x => Object.assign({}, x));

                    this.creditorList = response.data;
                    this.creditorDataSource = new MatTableDataSource(this.creditorList);
                    this.creditorDataSource.sort = this.creditorSort;
                    this.creditorDataSource.paginator = this.creditorPaginator;
                    setTimeout(() => {
                        this.pageHelper.page_content_onresize();
                    }, 500);
                }
            },
            error => {
                this.spinner.hide();
                this.router.navigate(['/login']);
            });
    }

    getDebtorList(showAll: any = false) {
        this.debtorService.search(showAll).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.creditorDebtorsList = response.data;
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    getClaimsList(type) {
        this.commonService.getLookup(type, false).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.creditorClaimsList = response.data;
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    applyFilter() {
        var tempList = this.clonedList.map(x => Object.assign({}, x));
        var self = this;

        if (this.filter.Restricted) {
            if(this.filter.Restricted == "1")
                tempList = _.filter(tempList, function (data) {
                    if(data.IsRestricted)
                        return data;
                });

            else if(this.filter.Restricted == "0")
                tempList = _.filter(tempList, function (data) {
                    if(!data.IsRestricted)
                        return data;
                });
        }

        if (this.filter.ClaimStatus) {
            tempList = _.filter(tempList, function (data) {
                //return data.ClaimStatus == self.filter.ClaimStatus
                var list = _.filter(data.SelectedDebtors, function (d) {
                    return d.ClaimStatusId == self.filter.ClaimStatus                    
                });    
                if(list && list.length > 0)
                    return data;
            });
        }

        if (this.filter.DebtorId) {
            tempList = _.filter(tempList, function (data) {
                //return data.DebtorId == self.filter.DebtorId
                var list = _.filter(data.SelectedDebtors, function (d) {
                    return d.DebtorId == self.filter.DebtorId                    
                });    
                if(list && list.length > 0)
                    return data;
            });
        }

        if(this.filter.ClaimType){
            if (this.filter.MinAmount) {
                if(this.filter.ClaimType == "All")
                    tempList = _.filter(tempList, function (data) {
                        return data.Amount >= self.filter.MinAmount || data.ClaimAmount >= self.filter.MinAmount || data.UnsecuredAmount >= self.filter.MinAmount 
                    });

                if(this.filter.ClaimType == "503b9")
                    tempList = _.filter(tempList, function (data) {
                        return data.Amount >= self.filter.MinAmount 
                    });

                if(this.filter.ClaimType == "ClaimAmount")
                    tempList = _.filter(tempList, function (data) {
                        return data.ClaimAmount >= self.filter.MinAmount
                    });

                if(this.filter.ClaimType == "Unsecured")
                    tempList = _.filter(tempList, function (data) {
                        return data.UnsecuredAmount >= self.filter.MinAmount 
                    });
            }
        }

        this.creditorList = tempList;
        this.creditorDataSource = new MatTableDataSource(this.creditorList);
        this.creditorDataSource.sort = this.creditorSort;
        this.creditorDataSource.paginator = this.creditorPaginator;
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }

    clearFilter() {
        this.filter = {
            "MinAmount": '',
            "DebtorId": '',
            "ClaimType": 'All',
            "ClaimStatus": '',
            "Restricted" : ''
        }
        this.searchModel = "";
        this.applyFilter();
    }

    openDebtorsModal(item){
        if (item) {
            this.debtorList = item.SelectedDebtors;
            $('#modal_creditor_debtors').modal('show');
        }
    }

    closeDebtorModal(){
        this.debtorList = [];
    }

    creditorFilter(filterValue: string) {
        this.creditorDataSource.filter = filterValue.trim().toLowerCase();
    }

    ExportTOExcel(){
        var data = this.creditorDataSource.filteredData;
    
        var exportData = [];

        data.forEach(creditor => {
            creditor.SelectedDebtors.forEach(debtor => {
                exportData.push({
                    "Creditor": creditor.Company,
                    "Debtor": debtor.Debtor,
                    "Key Person": debtor.KeyPerson,
                    "Claim Status": debtor.ClaimStatus,
                    "Claims": '$' + debtor.AmountTotal,
                    "503b9": '$' + debtor.Amount,
                    "Unsecured": '$' + debtor.AmountUnsecured,
                    "Debtors": creditor.DebtorCount,
                    "DNC": creditor.DoNotCall ? "TRUE" : "FALSE",
                    "Restricted": creditor.IsRestricted ? "Restricted" : ""
                })
            })
        });

        if (!exportData || exportData.length == 0)
            return;

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
        // XLSX.writeFile(wb, 'Creditors.xlsx');
        const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
        const header = Object.keys(exportData[0]);
        let csv = exportData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var a = document.createElement('a');
        var blob = new Blob([csvArray], {type: 'text/csv' }),
        url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = "Creditors.csv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    @Input() singleChildRowDetail: boolean;

    private openedRow: CdkDetailRowDirective
    onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
        if (this.singleChildRowDetail && this.openedRow && this.openedRow.expended) {
            this.openedRow.toggle();
        }
        this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }
}