import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from './user.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PageHelper } from '../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';

declare var swal: any;
declare var $: any;

@Component({
    selector: 'user',
    templateUrl: 'user.template.html',
    styleUrls: ['../../../assets/css/mat-table.css']
})

export class UserComponent implements OnInit {
    @ViewChild('userPaginator') userPaginator: MatPaginator;
    @ViewChild('userSort') userSort: MatSort;
    userColumns: string[] = ['Name', 'UserName', 'Email', 'Phone', 'Company', 'Password'];
    userDataSource: MatTableDataSource<any>;

    showHistory: boolean = false;
    RecordType: any = "New";
    appList: any = [];
    userList: any = [];
    form: FormGroup;
    formChangePassword: FormGroup;
    isSubmitted: boolean = false;
    isEditing: boolean = false;
    searchModel = "";
    saveButton: boolean = false;

    constructor(private router: Router, private userService: UserService, private pageHelper: PageHelper, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        $('#menuManage').addClass('active');
        $('#menuManageUsers').addClass('active');

        this.form = new FormGroup({
            UserKey: new FormControl(""),
            Name: new FormControl("", [Validators.required]),
            UserName: new FormControl("", [Validators.required]),
            Email: new FormControl("", [Validators.required, Validators.email]),
            Phone: new FormControl(""),
            Password: new FormControl(""),
            IsActive: new FormControl(true)
        });
        this.setValueChanges(this.form);

        this.form.valueChanges.subscribe(() => {
            this.saveButton = true;
        })

        this.formChangePassword = new FormGroup({
            UserKey: new FormControl(""),
            PasswordChange: new FormControl(""),
            PasswordChangeConfirm: new FormControl("")
        });
        this.setValueChanges(this.formChangePassword);

        this.getUserList(false);
    }

    getUserList(showAll: boolean = false) {
        this.searchModel = "";
        this.setFormValues(null);
        this.setFormChangePasswordValues(null);

        this.spinner.show();
        this.userService.search(showAll).subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.userList = response.data;
                    this.userDataSource = new MatTableDataSource(this.userList);
                    this.userDataSource.sort = this.userSort;
                    this.userDataSource.paginator = this.userPaginator;
                    setTimeout(() => {
                        this.pageHelper.page_content_onresize();
                    }, 500);
                }
            },
            error => {
                this.spinner.hide();
                this.router.navigate(['/login']);
            });
    }


    getUserDetails(UserKey: any) {
        if (UserKey) {
            this.spinner.show();
            this.userService.getDetails(UserKey)
                .subscribe(response => {
                    this.spinner.hide();
                    if (response.Status == 'Success') {
                        this.setFormValues(response.data);
                    }
                },
                    error => {
                        this.spinner.hide();
                        this.router.navigate(['/login']);
                    });
        }
    }

    getChangePasswordDetails(UserKey: any) {
        this.setFormChangePasswordValues(UserKey);
    }

    save() {
        Object.keys(this.form.controls).forEach(key => {
            if ($('#' + key))
                $('#' + key).removeClass('validationError');
        });

        var passwordError = false;
        if (!this.isEditing) {
            if (!this.form.get('Password').value) {
                $('#Password').addClass('validationError');
                passwordError = true;
            }
        }
        this.isSubmitted = true;
        if (this.form.valid && !passwordError) {
            var request = {
                "UserKey": this.form.get('UserKey').value,
                "Name": this.form.get('Name').value,
                "UserName": this.form.get('UserName').value,
                "Email": this.form.get('Email').value,
                "Phone": this.form.get('Phone').value,
                "Password": this.form.get('Password').value,
                "IsActive": this.form.get('IsActive').value
            }
            this.spinner.show();
            this.userService.save(request).subscribe(
                response => {
                    this.spinner.hide();
                    if (response.Status == 'Success') {
                        swal('Success', response.Message, 'success');

                        this.getUserList(false);
                    }
                    else {
                        swal('Error', response.Message, 'error');
                    }
                },
                () => {
                    this.spinner.hide();
                    this.router.navigate(['/login']);
                }
            );
        }
        else {
            Object.keys(this.form.controls).forEach(key => {
                if (!this.form.controls[key].valid) {
                    if ($('#' + key))
                        $('#' + key).addClass('validationError');
                }
            });
        }
    }

    setFormValues(data: any) {
        this.isSubmitted = false;
        Object.keys(this.form.controls).forEach(key => {
            if ($('#' + key))
                $('#' + key).removeClass('validationError');
        });

        if (data) {
            this.isEditing = true;
            this.form.setValue({
                UserKey: data.UserKey,
                Name: data.Name,
                Email: data.Email,
                UserName: data.UserName,
                Phone: data.Phone,
                Password: "",
                // PasswordConfirm: "",
                IsActive: data.IsActive
            });
            this.RecordType = "Update";
        }
        else {
            this.isEditing = false;
            this.form.setValue({
                UserKey: "",
                Name: "",
                Email: "",
                UserName: "",
                Phone: "",
                Password: "",
                // PasswordConfirm: "",
                IsActive: true
            });
            this.RecordType = "New";
            this.saveButton = false;
            Object.keys(this.form.controls).forEach(key => {
                if ($('#' + key))
                    $('#' + key).removeClass('validationError');
            });
        }
    }

    setFormChangePasswordValues(UserKey: any) {
        Object.keys(this.form.controls).forEach(key => {
            if ($('#' + key))
                $('#' + key).removeClass('validationError');
        });

        if (UserKey) {
            this.isEditing = true;
            this.formChangePassword.setValue({
                UserKey: UserKey,
                PasswordChange: "",
                PasswordChangeConfirm: ""
            });
        }
        else {
            this.isEditing = false;
            this.formChangePassword.setValue({
                UserKey: "",
                PasswordChange: "",
                PasswordChangeConfirm: ""
            });
            Object.keys(this.form.controls).forEach(key => {
                if ($('#' + key))
                    $('#' + key).removeClass('validationError');
            });
        }
    }

    changePassword() {
        Object.keys(this.formChangePassword.controls).forEach(key => {
            if ($('#' + key))
                $('#' + key).removeClass('validationError');
        });
        if (this.formChangePassword.valid) {
            if (this.formChangePassword.get('UserKey').value != '' && (this.formChangePassword.get('PasswordChange').value == '' || this.formChangePassword.get('PasswordChange').value != this.formChangePassword.get('PasswordChangeConfirm').value)) {
                $('#PasswordChange').addClass('validationError');
                $('#PasswordChangeConfirm').addClass('validationError');
                return;
            }

            var request = {
                "UserKey": this.formChangePassword.get('UserKey').value,
                "Password": this.formChangePassword.get('PasswordChange').value
            }
            this.spinner.show();
            this.userService.passwordUpdate(request).subscribe(
                response => {
                    this.spinner.hide();
                    if (response.Status == 'Success') {
                        swal('Success', response.Message, 'success');

                        $("#modal_change_password").modal('hide');
                        this.getUserList(false);
                    }
                    else {
                        swal('Error', response.Message, 'error');
                    }
                },
                () => {
                    this.spinner.hide();
                    this.router.navigate(['/login']);
                }
            );
        }
        else {
            Object.keys(this.formChangePassword.controls).forEach(key => {
                if (!this.formChangePassword.controls[key].valid) {
                    if ($('#' + key))
                        $('#' + key).addClass('validationError');
                }
            });
        }
    }

    setValueChanges(form: any) {
        form.valueChanges.subscribe(() => {
            if (this.isSubmitted) {
                Object.keys(this.form.controls).forEach(key => {
                    if (key != 'Password') {
                        if ($('#' + key))
                            $('#' + key).removeClass('validationError');
                    }
                });

                if (form.invalid) {
                    Object.keys(form.controls).forEach(key => {
                        if (key != 'Password') {
                            if (!form.controls[key].valid) {
                                if ($('#' + key))
                                    $('#' + key).addClass('validationError');
                            }
                        }
                    });
                }
            }
        });
    }

    updateRecordType() {
        this.RecordType = "Update";
    }

    applyFilter(filterValue: string) {
        this.userDataSource.filter = filterValue.trim().toLowerCase();
    }
}