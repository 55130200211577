declare var $: any;
export class PageHelper {
    page_content_onresize() {
        $(".page-content,.content-frame-body,.content-frame-right,.content-frame-left").css("width", "").css("height", "");

        var content_minus = 0;
        content_minus = ($(".page-container-boxed").length > 0) ? 40 : content_minus;
        content_minus += ($(".page-navigation-top-fixed").length > 0) ? 50 : 0;
        var content = $(".page-content");
        var sidebar = $(".page-sidebar");

        if (content.height() < $(document).height() - content_minus) {
            content.height($(document).height() - content_minus);
        }

        if (sidebar.height() > content.height()) {
            content.height(sidebar.height());
        }

        if ($(window).width() > 1024) {
            if ($(".page-sidebar").hasClass("scroll")) {
                var doc_height: any;
                if ($("body").hasClass("page-container-boxed")) {
                    doc_height = $(document).height() - 40;
                } else {
                    doc_height = $(window).height();
                }
                $(".page-sidebar").height(doc_height);
            }

            if ($(".content-frame-body").height() < $(document).height() - 162) {
                $(".content-frame-body,.content-frame-right,.content-frame-left").height($(document).height() - 162);
            } else {
                $(".content-frame-right,.content-frame-left").height($(".content-frame-body").height());
            }

            $(".content-frame-left").show();
            $(".content-frame-right").show();
        } else {
            $(".content-frame-body").height($(".content-frame").height() - 80);

            if ($(".page-sidebar").hasClass("scroll"))
                $(".page-sidebar").css("height", "");
        }

        if ($(window).width() < 1200) {
            if ($("body").hasClass("page-container-boxed")) {
                $("body").removeClass("page-container-boxed").data("boxed", "1");
            }
        } else {
            if ($("body").data("boxed") === "1") {
                $("body").addClass("page-container-boxed").data("boxed", "");
            }
        }
    }
}
