import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimaltoint' })
export class DecimalToInt implements PipeTransform {
  transform(value: string): string {
    var toReplace = /,/gi;
    var cleanString = value.replace(toReplace, '');
    let rawNumber: string = cleanString.substr(1, cleanString.length-1); //remove $ symbol from string
    // console.log(rawNumber);
    // console.log(Math.floor(Number(rawNumber)));
    // console.log(Math.ceil(Number(rawNumber)));
    if (Math.floor(Number(rawNumber)) == Math.ceil(Number(rawNumber))) {
      //return Math.floor(Number(rawNumber)).toString();
      return value.split(".")[0];
    }
    else
      return value;
  }
}