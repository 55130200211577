import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal.module';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { CalendarService } from './calendar.service';
import { CommonService } from '../common/common.service';
import { DebtorService } from '../debtor/debtor.service';
import { CreditorService } from '../creditor/creditor.service';
import { UserService } from '../user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';

declare var swal: any;
declare var $: any;

interface MyEvent extends CalendarEvent { primaryId: string; type: string; datetype: string; debtor: string; caseNumber: string; creditor: string; note: string; notestart: string; startTimeHH: string; startTimeMM: string; startTimeAP: string; eventnote: string; claimstatusid: string; hastime: boolean; }

@Component({
    selector: 'calendar',
    templateUrl: 'calendar.template.html'
})

export class CalendarComponent {
    @ViewChild('modalContent') modalContent: TemplateRef<any>;

    view: string = 'month';
    viewDate: Date = new Date();
    refresh: Subject<any> = new Subject();
    events: MyEvent[];
    clonedList = [];
    optionsDates = [];
    claimStatusList: any = [];
    activeDayIsOpen: boolean = true;
    filter: any = {};
    debtorList: any = [];
    creditorList: any = [];
    userList: any = [];
    dateTypeList: any = [];

    modalData: {
        action: string;
        event: MyEvent;
    };

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-fw fa-pencil"></i>',
            onClick: ({ event }: { event: MyEvent }): void => {
                this.handleEvent('Edited', event);
            }
        },
        //{
        //    label: '<i class="fa fa-fw fa-times"></i>',
        //    onClick: ({ event }: { event: MyEvent }): void => {
        //        this.events = this.events.filter(iEvent => iEvent !== event);
        //        this.handleEvent('Deleted', event);
        //    }
        //}
    ];

    constructor(private modal: NgbModal, private router: Router, private spinner: NgxSpinnerService, private calendarService: CalendarService, private commonService: CommonService, private debtorService: DebtorService, private creditorService: CreditorService, private userService: UserService) { }

    ngOnInit() {
        $('#menuCalendar').addClass('active');
        this.getEvents();
            
        this.commonService.getLookup("DateType")
            .subscribe(response => {
                if (response.Status == 'Success') {
                    this.optionsDates = response.data;
                }
            },
            error => {
                this.router.navigate(['/login']);
            });


        this.commonService.getLookup("ClaimStatus")
            .subscribe(response => {
                if (response.Status == 'Success') {
                    this.claimStatusList = response.data;
                }
            },
            error => {
                this.router.navigate(['/login']);
            });

        //Filters
        this.clearFilter();
        this.getDebtorList(false);
        this.getCreditorLits(false);
        this.getUserList(false);
        this.getDateType(false);
    }

    getEvents(GetActive: any = false) {
        this.spinner.show();

        var start_date = new Date(this.viewDate);
        var end_date = new Date(this.viewDate);
        start_date.setDate(start_date.getDate() - 30);
        end_date.setDate(end_date.getDate() + 30);

        this.calendarService.getCalendarEvents(start_date, end_date).subscribe(
            response => {
                this.spinner.hide();
                if (response.Status == 'Success') {
                    this.events = response.data;
                    this.events = _.map(this.events, o => _.extend({ actions: this.actions }, o));
                    this.events.forEach((part, index) => {
                        part.start = new Date(part.start);
                        part.title = this.setTitle(part);

                        if (part.hastime == true && part.start.getTime() != null) {
                            var amOrPm = (part.start.getHours() < 12) ? "AM" : "PM";
                            var hour = (part.start.getHours() <= 12) ? part.start.getHours() : part.start.getHours() - 12;
                            if (hour == 0)
                                hour = 12;
                            part.startTimeHH = ('0' + hour).slice(-2);
                            part.startTimeMM = ('0' + part.start.getMinutes()).slice(-2);
                            part.startTimeAP = amOrPm;
                        }
                        else {
                            part.startTimeHH = '';
                            part.startTimeMM = '';
                            part.startTimeAP = '';
                        }

                        if (part.type == 'Distribution Date') {
                            part.cssClass = 'event_distributionDate';
                        }
                        else if (part.type == 'Creditor Note') {
                            part.cssClass = 'event_creditorNote';
                        }
                        else {
                            part.cssClass = 'event_debtor';
                        }
                    });

                    this.clonedList = this.events.map(x => Object.assign({}, x));
                    this.applyFilter();
                }
            },
            error => {
                this.spinner.hide();
                this.router.navigate(['/login']);
            });
    }

    dayClicked({ date, events }: { date: Date; events: MyEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
        }
    }

    prevNextClicked() {
        this.activeDayIsOpen = false;
        this.getEvents();
    }

    //eventTimesChanged({
    //    event,
    //    newStart,
    //    newEnd
    //}: CalendarEventTimesChangedEvent): void {
    //    event.start = newStart;
    //    event.end = newEnd;
    //    this.handleEvent('Dropped or resized', event);
    //    this.refresh.next();
    //}

    handleEvent(action: string, event: MyEvent): void {
        this.modalData = { event, action };
        this.modal.open(this.modalContent, { size: 'lg' });
    }

    //addEvent(): void {
    //    this.events.push({
    //        title: 'New event',
    //        start: startOfDay(new Date()),
    //        end: endOfDay(new Date()),
    //        //color: colors.red,
    //        draggable: true,
    //        resizable: {
    //            beforeStart: true,
    //            afterEnd: true
    //        }
    //    });
    //    this.refresh.next();
    //}

    saveCalendar() {
        var request = this.getCalendarRequestObject();

        if (!request.EventDate) {
            swal('Error', 'Please enter/select an event date.', 'error');
            return;
        }
        if (this.modalData.event.type == 'Creditor Note') {
            if (!request.NoteDate) {
                swal('Error', 'Please enter/select a note date.', 'error');
                return;
            }
            if (request.Note == '') {
                swal('Error', 'Please enter a note.', 'error');
                return;
            }
            if (request.ClaimStatusId == '') {
                swal('Error', 'Please select a claim status.', 'error');
                return;
            }
        }

        //if (!request.DateType) {
        //    swal('Error', 'Please enter/select Date Type.', 'error');
        //    return;
        //}

        this.calendarService.updateCalendarEvent(request)
            .subscribe(response => {
                if (response.Status == 'Success') {
                    swal('Success', response.Message, 'success');
                    //this.modalData.event.title = this.setTitle(this.modalData.event);
                    this.getEvents();
                    $("#btnModalClose").trigger("click");
                    this.refresh.next();
                }
                else {
                    swal('Error', response.Message, 'error');
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    getCalendarRequestObject() {
        var time = null;

        var timeHH = this.modalData.event.startTimeHH;
        var timeMM = this.modalData.event.startTimeMM;
        var timeAP = this.modalData.event.startTimeAP;

        if (Number(timeHH) && (Number(timeMM) || Number(timeMM) == 0) && timeAP != '') {
            time = timeHH + ':' + timeMM + ':00 ' + timeAP;
        }

        var data = {
            PrimaryId: this.modalData.event.primaryId,
            Type: this.modalData.event.type,
            DateType: this.modalData.event.datetype,
            EventDate: this.convertDate(this.modalData.event.start),
            EventTime: time,
            EventNote: this.modalData.event.eventnote,
            Note: this.modalData.event.note,
            NoteDate: this.convertDate(this.modalData.event.notestart),
            ClaimStatusId: this.modalData.event.claimstatusid,
        }

        return data;
    }

    setTitle(event) {
        var title = ''

        if (event.hastime == true && event.start.getTime() != null) {
            title += event.start.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) + ' ';
        }
        title += '<span class="event_type">' + event.type + '</span>';
        if (event.debtor != '')
            title += ' - ' + event.debtor;
        if (event.caseNumber != '')
            title += ' (' + event.caseNumber + ')';
        if (event.creditor != '')
            title += ', ' + event.creditor;

        if (event.eventnote != '' && event.eventnote.length <= 50)
            title += ' - "' + event.eventnote + '"';
        else if (event.eventnote != '')
            title += ' - "' + event.eventnote.substring(0, 50) + '..."';

        return title;
    }
    
    getDebtorList(showAll: any = false) {
        this.debtorService.search(showAll).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.debtorList = _.sortBy(response.data, [function (o) { return o.PrimaryDebtor; }]);
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    getCreditorLits(showAll: any = false) {
        this.creditorService.search(showAll).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.creditorList = _.sortBy(response.data, [function (o) { return o.Company; }]);
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    getUserList(showAll: boolean = false) {
        this.userService.search(showAll).subscribe(
            response => {
                if (response.Status == 'Success') {
                    this.userList = _.sortBy(response.data, [function (o) { return o.Name; }]);
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    getDateType(GetActive: any = false) {
        this.commonService.getLookup("DateType", GetActive).subscribe(
            response => {
                if (response.Status == 'Success') {
                    //this.empList.push({this.name,this.empoloyeeID});
                    response.data.push({ LookupValue: 'Creditor Note' });
                    response.data.push({ LookupValue: 'Distribution Date' });
                    this.dateTypeList = _.sortBy(response.data, [function (o) { return o.LookupValue; }]);
                }
            },
            error => {
                this.router.navigate(['/login']);
            });
    }

    applyFilter() {
        var tempList = this.clonedList.map(x => Object.assign({}, x));
        var self = this;

        if (this.filter.Debtor) {
            tempList = _.filter(tempList, function (data) {
                return data.debtor == self.filter.Debtor;
            });
        }

        if (this.filter.Creditor) {
            tempList = _.filter(tempList, function (data) {
                return data.creditor == self.filter.Creditor;
            });
        }

        if (this.filter.User) {
            tempList = _.filter(tempList, function (data) {
                return data.user == self.filter.User;
            });
        }

        if (this.filter.DateType) {
            tempList = _.filter(tempList, function (data) {
                return data.type == self.filter.DateType;
            });
        }

        this.events = tempList;
        this.refresh.next();
    }

    clearFilter() {
        this.filter = {
            "Debtor": '',
            "Creditor": '',
            "User": '',
            "DateType": '',
        }
        this.applyFilter();
    }

    convertDate(str) {
        if (!str)
            return null;

        var date = new Date(str),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [month, day, date.getFullYear()].join("/");
    }
}
