import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from '../reports/reports.service';
import { DashboardService } from './dashboard.service';
import { PageHelper } from '../common/page.helper';

declare var swal: any;
declare var $: any;

@Component({
    selector: 'dashboard',
    templateUrl: 'dashboard.template.html'
})

export class DashboardComponent implements OnInit {

    public flotNewConsumerAppChartData = [{
        label: "\"Sold Away\" Claims",
        data: [
            [1, 170000],
            [2, 201500],
            [3, 200500],
            [4, 195000],
            [5, 185000],
            [6, 205000],
            [7, 210500]
        ]
    }];

    public flotLineOptions = {
        series: {
            lines: {
                show: true,
                lineWidth: 2,
                fill: false,
                fillColor: {
                    colors: [
                        { opacity: 0.0 },
                        { opacity: 0.0 }
                    ]
                }
            }
        },
        xaxis: {
            tickDecimals: 0
        },
        colors: ["#002A54"],
        grid: {
            color: "#999999",
            hoverable: true,
            clickable: true,
            tickColor: "#D4D4D4",
            borderWidth: 0
        },
        legend: {
            show: false
        },
        tooltip: true,
        tooltipOpts: {
            content: "Count: %y"
        }
    };

    public flotNewBusinessAppChartData = [
        {
            label: "Funded Claims",
            data: [
                [1, 20000],
                [2, 25500],
                [3, 26000],
                [4, 24500],
                [5, 29500],
                [6, 24320],
                [7, 21000]
            ]
        }
    ];

    public flotPieDataNewData: number[] = [421293, 163295];
    public flotPieDataNewLabel: string[] = ["Expected Recoveries", "Expected Distribution"];
    public flotPieDataNewColors: Array<any> = [{ backgroundColor: ["#002A54", "#00aa63"], hoverBackgroundColor: ["#359aff", "#8DD9CA"] }];

    private pieOptions: any = {
        legend: { position: 'right', labels: { fontSize: 10 } }
    }

    sourcerList: any = [];
    valuationReportList: any = [];
    dailyTradeAndSettleReportList: any = [];
    claimReportList : any = [];

    constructor(private router: Router, private dashboardService: DashboardService,
        private reportService: ReportService, private pageHelper : PageHelper) {

    }

    ngOnInit() {
        $('#menuDashboard').addClass('active');

        this.getSourcerList();
        this.getValuationList();
        this.getDailyTradeReport();
        this.getClaimReportSummary();
    }

    getDailyTradeReport() {
        this.reportService.getDailyTradeReport("", "", "", 'dashboard').subscribe(
            response => {

                if (response.Status == 'Success') {
                    this.dailyTradeAndSettleReportList = response.data;
                }
            },
            error => {
                this.router.navigate(['/login']);

            });
    }

    getSourcerList() {

        this.reportService.getSourcerReport("", "", "", 'dashboard')
            .subscribe(response => {
                if (response.Status == 'Success') {
                    this.sourcerList = response.data;
                }
            },
                error => {
                    this.router.navigate(['/login']);
                })

    }
    getValuationList() {
        this.reportService.getValuationReport('', null, null, 'dashboard')
            .subscribe(response => {
                if (response.Status == 'Success') {
                    this.valuationReportList = response.data;
                }
            },
                error => {
                    this.router.navigate(['/login']);
                })
    }


    getClaimReportSummary() {
        this.reportService.getDashboardClaimReportSummary().subscribe(
        response => {
            if (response.Status == 'Success') {
                this.claimReportList = response.data;
                this.resizePage();
            }
        },
        error => {
            this.router.navigate(['/login']);
        });
    }
    resizePage(){
        setTimeout(() => {
            this.pageHelper.page_content_onresize();
        }, 500);
    }
}
