import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { StorageService } from '../common/storage.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

declare var swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  
  year: any;

  constructor(private router: Router, private loginService: LoginService, private storageService: StorageService) {
      this.year = new Date().getFullYear();

      this.form = new FormGroup({
          UserName: new FormControl('', [Validators.required]),
          Password: new FormControl('', [Validators.required])
      });
  }

  ngOnInit() {
    if (this.storageService.getData('EncryptedToken')) {
      this.storageService.removeData('EncryptedToken');
      this.storageService.removeData('UserFullName');
    }
  }

  onLoginClicked() {
    if(this.form.valid){
      var request = {
        "UserName": this.form.get('UserName').value,
        "Password": this.form.get('Password').value
      }

      this.loginService.login(request).subscribe(
          response => {
              if (response.Status == 'Success') {
                if(response.User){
                  this.router.navigate(['/resetPassword'], { queryParams: { Key: response.User } });
                }
                else{
                  this.storageService.setData('UserFullName', response.UserFullName);
                  this.router.navigate(['/dashboard']);
                }
              }
              else{
                  swal('Error', response.Message, 'error');
              }
          },
          () => {
              this.storageService.clear();
          }
      );
    }
  }
}
