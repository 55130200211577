import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReportService } from '../reports.service';
import * as _ from 'underscore';
import { PageHelper } from '../../common/page.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditorService } from '../../creditor/creditor.service';


declare var $: any;

@Component({
    selector: 'valuationreports',
    templateUrl: 'valuationReports.template.html',
    styleUrls: ['../../../../assets/css/mat-table.css']
})

export class ValuationReportComponent implements OnInit {

  @ViewChild('valuationreportPaginator') valuationreportPaginator: MatPaginator;
  @ViewChild('valuationreportSort') valuationreportSort: MatSort;
  valuationreportColumns: string[] = ['Debtor', 'InsertionDate', 'Amount', 'UnsecuredAmount', 'SecuredAmount'];
  valuationreportDataSource: MatTableDataSource<any>;
  clonedList = [];
  
  valuationReportList: any = [];

  filteringDebtorList: any = [];

  filter = {
    debtorId: '',
    fromdate: null,
    todate: null
  }
  Debtor: any = "";
  DebtorId: any = "";

  searchModel = "";
  debtorList: any = [];
  
  constructor(private router: Router, private reportService: ReportService,
    private pageHelper: PageHelper, private spinner: NgxSpinnerService, private creditorService: CreditorService) {
        
    }

    ngOnInit() {
      $('#menuReport').addClass('active');
      $('#menuValuationReport').addClass('active');

      this.getDebtors(); //to fill filter drop down list
      this.clearFilter();
    }

    getValuationReport() {
      this.searchModel = "";
      this.spinner.show();

      this.reportService.getValuationReport(this.filter.debtorId, this.convertDate(this.filter.fromdate), this.convertDate(this.filter.todate), '').subscribe(
        response => {
          this.spinner.hide();

          if (response.Status == 'Success'){
            this.valuationReportList = response.data;
            this.valuationreportDataSource = new MatTableDataSource(this.valuationReportList);
            this.valuationreportDataSource.sort = this.valuationreportSort;
            this.valuationreportDataSource.paginator = this.valuationreportPaginator;
            setTimeout(() => {
              this.pageHelper.page_content_onresize();
            }, 500);
          }
        },
        error => {
          this.router.navigate(['/login']);
          this.spinner.hide();
        });
    }


    getDebtors() {
      this.creditorService.getDebtors(true)
        .subscribe(response => {
          if (response.Status == 'Success') {
            this.debtorList = response.data;
          }
        },
        error => {
          this.router.navigate(['/login']);
        });
    }

    clearFilter() {
      this.filter = {
        debtorId: '',
        fromdate: null,
        todate: null
      }

      this.applyFilter();
    }



    applyFilter() {
      //var tempList = this.clonedList.map(x => Object.assign({}, x));
      //var self = this;
      this.getValuationReport();
      //this.debtorList = tempList;
      //this.debtorDataSource = new MatTableDataSource(this.debtorList);
      //this.debtorDataSource.sort = this.debtorSort;
      //this.debtorDataSource.paginator = this.debtorPaginator;

      //setTimeout(() => {
      //  this.pageHelper.page_content_onresize();
      //}, 500);
    }


    restrictInput(event: any) {
      event.preventDefault();
    }

    restrictDelete(event: any) {
      var key = event.keyCode || event.charCode;

      if (key == 8 || key == 46)
        return false;
    }

    selectDebtor(data)
    {
      if (data.option.value) {
        var result = _.find(this.debtorList, function (valuation) {
          return valuation.DebtorId == data.option.value;
        });
        if (result) {
          this.Debtor = result.Debtor;
          this.DebtorId = data.option.value;
          this.filter.debtorId = data.option.value;
          this.applyFilter();
        }
      }
      else {
        this.Debtor = "";
      }
    }

    ExportTOExcel() { 
        var data = this.valuationreportDataSource.filteredData;
      var exportData = [];
      data.forEach(item => {
        exportData.push({
            "Debtor": item.Debtor,
            "Date": this.convertDate(item.InsertionDate),
            "503b9": item.Amount + '%',
            "Unsecured": item.UnsecuredAmount + '%',
            "Secured": item.SecuredAmount + '%'
        })
      });

      if (!exportData || exportData.length == 0)
          return;

      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(exportData[0]);
      let csv = exportData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      var a = document.createElement('a');
      var blob = new Blob([csvArray], { type: 'text/csv' }),
          url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = "valuationReport.csv";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();    
    }



    //debtorFilter(filterValue: string) {
    //  this.debtorDataSource.filter = filterValue.trim().toLowerCase();
    //}

    convertDate(str) {
      if (!str)
        return null;

      var date = new Date(str),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [month, day, date.getFullYear()].join("/");
    }

    filterDebtor(val) {
      var self = this;
      var list = this.debtorList;
      this.DebtorId = "";
      if (val) {
        const filterValue = val.toLowerCase();
        list = list.filter(valuation => valuation.Debtor.toLowerCase().startsWith(filterValue))
        return list;
      }
      return list;
    }

}
